import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  FormErrorMessage,
  Box,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { toggleSuccessModal } from 'src/store/slices/auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useRegisterMutation } from 'src/hooks/data/auth';
import { useDispatch } from 'src/store';

type inputProps = {
  name:
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'location'
    | 'companyName'
    | 'companyEmail';
  type: string;
  label: string;
};

const inputs: inputProps[] = [
  { name: 'firstName', type: 'text', label: 'First Name' },
  { name: 'lastName', type: 'text', label: 'Last Name' },
  { name: 'email', type: 'email', label: 'Email' },
  { name: 'companyName', type: 'text', label: 'Company Name' },
  { name: 'companyEmail', type: 'email', label: 'Company Email' },
  { name: 'phone', type: 'text', label: 'Phone number' },
  { name: 'location', type: 'text', label: 'Location' },
];

const Register = () => {
  const dispatch = useDispatch();
  const [mutateRegister, { isLoading }] = useRegisterMutation();
  return (
    <>
      <Box textAlign="center" mb="1rem">
        <Heading size="xl">Register</Heading>
      </Box>
      <Box>
        <Formik
          initialValues={{
            companyName: '',
            companyEmail: '',
            phone: '',
            location: '',
            firstName: '',
            lastName: '',
            email: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            companyName: Yup.string()
              .min(4, 'Company name must be at least 4 characters')
              .required('Company name is required'),
            companyEmail: Yup.string()
              .email('Company email must be valid.')
              .required('Company email is required'),
            phone: Yup.string().required('Phone number is required'),
            location: Yup.string().required('Location is required'),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
          })}
          onSubmit={async (values, { resetForm, setStatus }) => {
            try {
              const { submit, ...rest } = values;
              await mutateRegister(rest).unwrap();
              dispatch(toggleSuccessModal(true));
              resetForm();
            } catch (error: any) {
              setStatus({
                type: 'error',
                message: error?.data?.message,
              });
            } finally {
              setTimeout(() => {
                setStatus();
              }, 7000);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            status,
          }) => (
            <Form onSubmit={handleSubmit}>
              {inputs.map((inp) => (
                <FormControl
                  id={inp.name}
                  mb="1rem"
                  key={inp.name}
                  isInvalid={Boolean(touched[inp.name] && errors[inp.name])}
                >
                  <FormLabel>{inp.label}</FormLabel>
                  <Input
                    type={inp.type}
                    name={inp.name}
                    value={values[inp.name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{errors[inp.name]}</FormErrorMessage>
                </FormControl>
              ))}
              {!isLoading && status?.type && (
                <Alert status="error" mb="1rem">
                  <AlertIcon />
                  <AlertDescription>{status?.message}</AlertDescription>
                </Alert>
              )}
              <Button
                isLoading={isLoading}
                colorScheme="teal"
                width="full"
                type="submit"
                spinner={<BeatLoader size={8} color="white" />}
              >
                Register
              </Button>
              <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                my="0.5rem"
              >
                <Box as="hr" width="30%" />
                <Text fontSize="sm" mx="1rem" color="grey">
                  or
                </Text>
                <Box as="hr" width="30%" />
              </Box>
              <Button
                width="full"
                as={RouterLink}
                to="/auth/login"
                color="white"
                bg="facebook.500"
                _hover={{ color: 'white', bg: 'facebook.700' }}
                textDecoration="none"
              >
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Register;
