import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Box,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import {
  useGetPlatformSettingsQuery,
  useUpdateSMTPSettingsMutation,
} from 'src/hooks/data/admin/settings';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toastParams } from 'src/utils/toastParams';

export const SmtpForm = () => {
  const toast = useToast();
  const { data } = useGetPlatformSettingsQuery();
  const [mutateUpdate, { isLoading }] = useUpdateSMTPSettingsMutation();

  return (
    <Formik
      initialValues={{
        smtpHost: data?.smtpHost || '',
        smtpUser: data?.smtpUser || '',
        smtpPass: '',
        smtpFromEmail: data?.smtpFromEmail || '',
      }}
      validationSchema={Yup.object().shape({
        smtpFromEmail: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Sender email is required'),
        smtpPass: Yup.string().required('Smtp password is required'),
        smtpUser: Yup.string().required('Smtp user is required'),
        smtpHost: Yup.string().required('Smtp host is required'),
      })}
      onSubmit={async (values) => {
        if (!data?.id) {
          toast({
            title: "No existing records. Can't update settings",
            status: 'error',
            ...toastParams,
          });
          return;
        }

        mutateUpdate({ id: data?.id, data: values })
          .unwrap()
          .then(() => {
            toast({
              title: 'Save changes successful',
              status: 'success',
              ...toastParams,
            });
          })
          .catch((err) => {
            toast({
              title: err?.data?.message,
              status: 'error',
              ...toastParams,
            });
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        status,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl
            id="smtpHost"
            mb="1rem"
            isInvalid={Boolean(touched.smtpHost && errors.smtpHost)}
            display={{ base: 'block', sm: 'flex' }}
            gap={4}
          >
            <FormLabel width={150}>SMTP Host</FormLabel>
            <Box flex={1}>
              <Input
                type="text"
                name="smtpHost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpHost}
              />
              <FormErrorMessage>{errors.smtpHost}</FormErrorMessage>
            </Box>
          </FormControl>
          <FormControl
            id="smtpUser"
            mb="1rem"
            isInvalid={Boolean(touched.smtpUser && errors.smtpUser)}
            display={{ base: 'block', sm: 'flex' }}
            gap={4}
          >
            <FormLabel width={150}>SMTP Username</FormLabel>
            <Box flex={1}>
              <Input
                type="text"
                name="smtpUser"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpUser}
              />
              <FormErrorMessage>{errors.smtpUser}</FormErrorMessage>
            </Box>
          </FormControl>
          <FormControl
            id="smtpPass"
            mb="1rem"
            isInvalid={Boolean(touched.smtpPass && errors.smtpPass)}
            display={{ base: 'block', sm: 'flex' }}
            gap={4}
          >
            <FormLabel width={150}>
              SMTP Password{' '}
              <Tooltip hasArrow label="Password will be hidden on upload">
                <span style={{ display: 'inline-flex' }}>
                  <AiOutlineInfoCircle color="green" />
                </span>
              </Tooltip>
            </FormLabel>
            <Box flex={1}>
              <Input
                type="text"
                name="smtpPass"
                placeholder="******"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpPass}
              />
              <FormErrorMessage>{errors.smtpPass}</FormErrorMessage>
            </Box>
          </FormControl>
          <FormControl
            id="smtpFromEmail"
            mb="1rem"
            isInvalid={Boolean(touched.smtpFromEmail && errors.smtpFromEmail)}
            display={{ base: 'block', sm: 'flex' }}
            gap={4}
          >
            <FormLabel width={150}>Sender Email</FormLabel>
            <Box flex={1}>
              <Input
                type="text"
                name="smtpFromEmail"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.smtpFromEmail}
              />
              <FormErrorMessage>{errors.smtpFromEmail}</FormErrorMessage>
            </Box>
          </FormControl>

          <Button
            isLoading={isLoading}
            mt={10}
            colorScheme="teal"
            type="submit"
            spinner={<BeatLoader size={8} color="white" />}
          >
            Save changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};
