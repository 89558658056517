import { Button, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const PDFViewer = ({ fileUrl }: { fileUrl: string }) => {
  const [numPage, setNumPage] = useState(1);
  const [scale, setScale] = useState(1);
  return (
    <center>
      <Box>
        <Box
          position="fixed"
          bottom="150px"
          right={{ base: '50px', lg: '150px' }}
          zIndex="banner"
          display="flex"
          flexDirection="column"
          p={3}
          gap={4}
        >
          <Button
            type="button"
            colorScheme="blackAlpha"
            onClick={() => setScale((prev) => (prev < 4 ? prev / 0.5 : prev))}
          >
            +
          </Button>
          <Button
            type="button"
            colorScheme="blackAlpha"
            onClick={() =>
              setScale((prev) => (prev > 0.25 ? prev * 0.5 : prev))
            }
          >
            -
          </Button>
        </Box>
        <Document
          file={fileUrl}
          onLoadSuccess={({ numPages }) => setNumPage(numPages)}
        >
          {Array.from(new Array(numPage), (_el, index) => (
            <Box key={`page-${index + 1}`} mb="4">
              <Page
                scale={scale}
                canvasBackground="transparent"
                pageNumber={index + 1}
              />
            </Box>
          ))}
        </Document>
      </Box>
    </center>
  );
};
export default PDFViewer;
