/* eslint-disable no-empty */
import { api } from 'src/store/services/baseApi';

type PostSubscriptionType = {
  companyUUID: string;
  planCode: string;
  email: string;
  amount: number;
  paymentProvider?: string;
  customerCode?: string;
  isRenewSub?: boolean;
};

type CurrentSubType = {
  code: string;
  token: string;
  paymentProvider?: string;
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchPlans: build.query({
      query: () => ({ url: 'plan', method: 'GET' }),
    }),
    getPlanUsage: build.query<any, void>({
      query: () => ({ url: 'plan/info', method: 'GET' }),
    }),
    getCompanySubscriptions: build.query<any, void>({
      query: () => ({ url: 'subscriptions', method: 'GET' }),
    }),
    getCurrentCompanySubscription: build.query<any, void>({
      query: () => ({ url: 'subscription/current', method: 'GET' }),
    }),
    postRenewSubscription: build.mutation<any, PostSubscriptionType>({
      query: ({ isRenewSub, ...args }) => ({
        url: isRenewSub ? 'subscription/renew' : 'subscription',
        method: 'POST',
        data: { paymentProvider: 'paystack', ...args },
      }),
      invalidatesTags: ['fetchMe'],
    }),
    cancelSubscription: build.mutation<any, CurrentSubType>({
      query: (args) => ({
        url: 'subscription/cancel',
        method: 'POST',
        data: { paymentProvider: 'paystack', ...args },
      }),
      invalidatesTags: ['fetchMe'],
    }),
    manageCard: build.mutation<any, Omit<CurrentSubType, 'token'>>({
      query: (args) => ({
        url: 'subscription/manage/email',
        method: 'POST',
        data: { paymentProvider: 'paystack', ...args },
      }),
    }),
  }),
});

export const {
  useFetchPlansQuery,
  useGetCompanySubscriptionsQuery,
  usePostRenewSubscriptionMutation,
  useGetCurrentCompanySubscriptionQuery,
  useGetPlanUsageQuery,
  useCancelSubscriptionMutation,
  useManageCardMutation,
} = injectedRtkApi;
