import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Text,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BiFilter } from 'react-icons/bi';
import { Pagination } from 'src/components/pagination';
import PageTitle from 'src/components/PageTitle';
import Card from 'src/components/Card';
import { MultipleUserDropdown } from 'src/components/dropdown/MultipleUserDropdown';
import { UserStrip } from 'src/interfaces/task';
import { useFetchTasksQuery } from 'src/hooks/data/task';
import { useAuth } from 'src/hooks/useAuth';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import { DepartmentDropdown } from 'src/components/dropdown/departmentDropdown';
import checkListIcon from 'src/assets/images/checklist-outline.svg';
import { AiOutlineTeam } from 'react-icons/ai';
import { Department } from 'src/interfaces/company';
import EmptyResult from 'src/components/EmptyResult';
import ListData from './components/ListData';
import Filter from './components/Filter';
import { CreateIssue } from '../../board/components/CreateIssue';

const PAGE_SIZE = 50;
const initialTasks = {
  count: 0,
  rows: [],
  totalPages: 0,
  currentPage: 0,
};

const IssueList = () => {
  const { isAdmin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState<UserStrip[]>([]);
  const [selectedDepts, setSelectedDepts] = useState<Department[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { data: users = [] } = useFetchUsersQuery('activated');

  const [query, setQuery] = useState({});
  const { tasks, isFetching } = useFetchTasksQuery(
    {
      isAdmin,
      page: currentPage - 1,
      size: PAGE_SIZE,
      userIds: selectedUsers.map((user) => user.id),
      departmentIds: selectedDepts.map((dept) => dept.id),
      ...query,
    },
    {
      selectFromResult: (results) => ({
        tasks: results.data ?? initialTasks,
        ...results,
      }),
    }
  );

  const firstTimeUser = useMemo(() => {
    return (
      !selectedUsers.length &&
      !selectedDepts.length &&
      !Object.keys(query).length &&
      !tasks.rows.length
    );
  }, [selectedDepts, selectedUsers, query, tasks.rows]);

  useEffect(() => {
    if (searchParams.get('userId')) {
      const userId = searchParams.get('userId');
      const findUser = users.find((user) => user.id === Number(userId));
      if (findUser) setSelectedUsers((prev) => [...prev, findUser]);
    }
  }, []);

  const startCount = PAGE_SIZE * currentPage - (PAGE_SIZE - 1);
  const endCount =
    tasks.rows.length < PAGE_SIZE
      ? tasks.count
      : tasks.rows.length * currentPage;

  return (
    <>
      <PageTitle text="Issues" />
      <Flex gap={4} flexWrap="wrap">
        <Menu>
          <MenuButton as={Button} leftIcon={<BiFilter />} variant="outline">
            Filter
          </MenuButton>
          <Filter
            useFetchTasks={(val) => setQuery(val ?? {})}
            isLoading={isFetching}
          />
        </Menu>
        <MultipleUserDropdown
          data={selectedUsers}
          onApply={(val) => {
            setSelectedUsers(val);
            if (!val.length) {
              setSearchParams((params) => {
                params.delete('userId');
                return params;
              });
            }
          }}
        >
          <MenuButton
            as={Button}
            bg="blue.900"
            color="white"
            _hover={{ bg: 'blue.900' }}
            rightIcon={
              <AvatarGroup color="blue.900" size="sm" max={2}>
                {selectedUsers.map((item) => (
                  <Avatar
                    key={item.id}
                    name={`${item.firstName} ${item.lastName}`}
                  />
                ))}
              </AvatarGroup>
            }
          >
            Assignee
          </MenuButton>
        </MultipleUserDropdown>
        <DepartmentDropdown
          selectedData={selectedDepts}
          onApply={(val) => {
            setSelectedDepts(val);
          }}
        >
          <MenuButton
            as={Button}
            leftIcon={<AiOutlineTeam />}
            bg="blue.900"
            color="white"
            _hover={{ bg: 'blue.900' }}
          >
            Departments{' '}
            {selectedDepts.length ? (
              <Badge>{selectedDepts.length}</Badge>
            ) : null}
          </MenuButton>
        </DepartmentDropdown>
      </Flex>
      <Card
        mt="8"
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        overflow="hidden"
        flexFlow="column nowrap"
        style={{ maxHeight: 'calc(100vh - 220px)' }}
      >
        <Box overflowY="auto" flexGrow={1}>
          <ListData tasks={tasks.rows} loading={isFetching} />
          {!isFetching && !tasks.rows.length && (
            <EmptyResult
              title={
                firstTimeUser
                  ? "Let's create your first task"
                  : 'No results found'
              }
              subtitle="All issues would be shown here once you have one or clear your filter(s)"
              imageSrc={firstTimeUser ? checkListIcon : undefined}
            >
              {firstTimeUser && (
                <Button
                  bg="blue.900"
                  color="white"
                  _hover={{ bg: 'blue.900' }}
                  my={3}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Create issue
                </Button>
              )}
            </EmptyResult>
          )}
          {tasks.rows.length ? (
            <Box
              position="sticky"
              width="full"
              left={0}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              gridGap="2"
              mt="4"
            >
              <Text fontSize="sm" fontWeight="bold">
                {!isFetching && `${startCount} - ${endCount} of ${tasks.count}`}
              </Text>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={PAGE_SIZE}
                totalCount={tasks.count}
              />
            </Box>
          ) : undefined}
        </Box>
      </Card>

      {/* CREATE ISSUE MODAL */}
      <CreateIssue isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </>
  );
};

export default IssueList;
