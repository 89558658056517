import {
  Box,
  Container,
  IconButton,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import Logo from 'src/components/Logo';
import { showMobileNav } from 'src/store/slices/auth';
import { useSelector, useDispatch } from 'src/store';
import NavItems from './partials/NavItems';
import Notification from './partials/Notification';
import Profile from './partials/Profile';

const Topbar = () => {
  const [isMediaMobile] = useMediaQuery('(max-width: 500px)');
  const [isMediaIpad] = useMediaQuery('(max-width: 720px)');
  const { isMobileNav } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const mobileNavRef = useRef<any>();

  useOutsideClick({
    ref: mobileNavRef,
    handler: () => dispatch(showMobileNav(false)),
  });

  return (
    <Box
      as="header"
      ref={mobileNavRef}
      position="sticky"
      top="0"
      zIndex="banner"
    >
      <Box
        as="nav"
        position="relative"
        bg="white"
        boxShadow="sm"
        height="70px"
        zIndex="banner"
      >
        <Container maxW="container.wide" display="flex" alignItems="center">
          <Logo width="50px" height="70px" />
          <Box flexGrow={1} display="flex" justifyContent="center" px="40px">
            {!isMediaIpad && <NavItems />}
          </Box>
          {isMediaIpad && (
            <IconButton
              aria-label="menu"
              variant="link"
              color="primary"
              pb={isMediaMobile ? 0 : '15px'}
              onClick={() => dispatch(showMobileNav(!isMobileNav))}
            >
              <BsThreeDots fontSize="30px" />
            </IconButton>
          )}
          <Notification mr={1} mt={1} />
          <Profile />
        </Container>
      </Box>
      {isMediaIpad && (
        <Box
          bg="white"
          py="4"
          boxShadow="md"
          position="absolute"
          top="70px"
          left="0"
          width="full"
          transition="all 1s ease"
          transform={isMobileNav ? 'translateY(0px)' : 'translateY(-999px)'}
        >
          <Container
            maxW="container.wide"
            display={isMediaMobile ? 'block' : 'flex'}
            justifyContent="center"
          >
            <NavItems isMobile={isMediaMobile} />
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Topbar;
