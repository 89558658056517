import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Divider,
  FormErrorMessage,
  Alert,
  AlertDescription,
  AlertIcon,
  Text,
  CloseButton,
  Textarea,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import {
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
} from 'src/hooks/data/department';
import { Department } from 'src/interfaces/company';

export const DeptFormMutations = ({
  editDept,
  onClose,
}: {
  editDept?: Department;
  onClose?: () => void;
}) => {
  const [DeptFormMutationsMutate, { isLoading }] = useAddDepartmentMutation();
  const [editDeptMutate, { isLoading: isEditing }] =
    useUpdateDepartmentMutation();

  return (
    <Box pt={6} pb={4} px={3}>
      <Formik
        initialValues={{
          name: editDept?.name || '',
          description: editDept?.description || '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Name is required'),
        })}
        onSubmit={async (values, { setStatus, resetForm }) => {
          const { submit, ...rest } = values;
          const mutation = editDept?.id
            ? editDeptMutate({
                ...rest,
                id: editDept.id,
              })
            : DeptFormMutationsMutate(rest);

          mutation
            .unwrap()
            .then(() => {
              setStatus({
                type: 'success',
                message: editDept?.id
                  ? 'Saved successfully'
                  : 'Created successfully',
              });
              if (editDept?.id) {
                onClose?.();
              } else {
                resetForm();
              }
            })
            .catch((error) => {
              setStatus({
                type: 'error',
                message: error?.data?.message,
              });
            })
            .finally(() => {
              setTimeout(() => {
                setStatus();
              }, 7000);
            });
        }}
      >
        {({
          status,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setStatus,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormControl
              id="name"
              mb="1rem"
              isInvalid={Boolean(touched.name && errors.name)}
              isRequired
            >
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl id="description" mb="1rem">
              <FormLabel>Description</FormLabel>
              <Textarea
                name="description"
                onChange={handleChange}
                value={values.description}
              />
            </FormControl>

            {status && status?.type && (
              <Alert status={status?.type} variant="subtle" mb="1rem">
                <AlertIcon />
                <AlertDescription>
                  <Text>{status?.message}</Text>
                </AlertDescription>
                <CloseButton
                  alignSelf="flex-start"
                  position="absolute"
                  right={-1}
                  top={-1}
                  onClick={() => setStatus({})}
                />
              </Alert>
            )}
            <Divider mb={1} />
            <Box mt={3} display="flex">
              <Button
                mr={3}
                isLoading={isLoading || isEditing}
                colorScheme="teal"
                type="submit"
                spinner={<BeatLoader size={8} color="white" />}
              >
                {editDept?.id ? 'Save' : 'Create'}
              </Button>
              {editDept?.id && (
                <Button
                  isLoading={isLoading || isEditing}
                  // colorScheme="teal"
                  spinner={<BeatLoader size={8} color="white" />}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
