import { Priority } from 'src/interfaces/task';

export const defaultError = {
  title: false,
  priority: false,
  assignedTo: false,
};

export const defaultValues = {
  title: '',
  description: '',
  priority: Priority.LOW,
  assignedTo: null,
  assigned: null,
  estimate: '',
  department: null,
};

export const titleValidator = (value: string) => {
  if (!value) {
    return 'Title is required';
  }
  if (value.length < 4) {
    return 'Title must be at least 4 characters';
  }

  return '';
};

export const validation = (forms: Record<string, unknown>) => {
  const errors = Object.entries(forms).reduce((acc, [key, val]) => {
    if (key in defaultError) {
      acc = { ...acc, [key]: !val };
    }
    if (key === 'title' && !!titleValidator(val as string)) {
      acc = {
        ...acc,
        title: true,
      };
    }
    return acc;
  }, {});

  const isInValid = Object.values(errors).includes(true);
  return { isInValid, errors };
};
