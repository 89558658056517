import { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from 'src/hooks/useAuth';

const OnlyAdmin = ({ component: Component }: { component: ReactElement }) => {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    return <Navigate to="/app/overview" />;
  }

  return Component;
};

export default OnlyAdmin;
