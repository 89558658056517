import { Text } from '@chakra-ui/react';

const PageTitle = ({ text }: { text: string }) => {
  return (
    <Text fontSize="2xl" fontWeight="500" pb="4">
      {text}
    </Text>
  );
};

export default PageTitle;
