/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuDivider,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { useGetDepartmentsQuery } from 'src/hooks/data/department';
import { useAuth } from 'src/hooks/useAuth';
import { Department } from 'src/interfaces/company';
import { DeptFormMutations } from './DeptFormMutations';
import { DeptItem } from './DeptItem';

type Props<T> = {
  selectedData: T[];
  onApply: (data: T[]) => void;
  children: ReactNode;
  isSingle?: boolean;
};

export const DepartmentDropdown = ({
  selectedData,
  onApply,
  children,
  isSingle,
}: Props<Department>) => {
  const { isAdmin } = useAuth();
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<Department[]>([]);
  const [showForm, setShowForm] = useState(false);

  const { isLoading, data = [] } = useGetDepartmentsQuery(undefined);

  useEffect(() => {
    if (selectedData) {
      setSelected(selectedData);
    }
  }, [selectedData]);

  const handleSelectDept = (dept: Department) => {
    if (!selected.find((i) => i.id === dept.id)) {
      setSelected((prev) => [...prev, dept]);
    } else {
      setSelected((prev) => prev.filter((i) => i.id !== dept.id));
    }
  };

  const filteredDept = data.filter((dept) =>
    [dept.name, dept.description].some((i) => i.includes(query.toLowerCase()))
  );

  return (
    <Menu>
      {({ onClose }) => (
        <>
          {children}
          <MenuList py={0} width="300px" zIndex="dropdown">
            <Flex px={2} pt={2} align="center">
              <Box flex={1}>
                {showForm && (
                  <IconButton
                    aria-label="add department"
                    variant="link"
                    p={2}
                    _hover={{ bg: 'gray.100' }}
                    onClick={() => {
                      setShowForm(false);
                    }}
                    icon={<BsChevronLeft />}
                  />
                )}
              </Box>
              <Text fontWeight="semibold">Departments</Text>
              <Box flex={1} justifyContent="flex-end" display="flex">
                {isAdmin && (
                  <IconButton
                    aria-label="add department"
                    variant="link"
                    p={2}
                    _hover={{ bg: 'gray.100' }}
                    onClick={() => (showForm ? onClose() : setShowForm(true))}
                    icon={showForm ? <AiOutlineClose /> : <AiOutlinePlus />}
                  />
                )}
              </Box>
            </Flex>

            {showForm ? (
              <DeptFormMutations />
            ) : (
              <>
                <Box p={2}>
                  <Input
                    type="text"
                    name="search"
                    placeholder="Search by name, description..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </Box>
                <MenuDivider />
                <Box maxH="200px" overflow="scroll" mb={4}>
                  {isLoading ? (
                    <Text fontSize="sm" p="2">
                      Loading...
                    </Text>
                  ) : filteredDept.length > 0 ? (
                    filteredDept.map((dept, index) => (
                      <DeptItem
                        key={index}
                        dept={dept}
                        onPress={handleSelectDept}
                        disabled={isSingle && selected.length > 0}
                        selected={selected}
                      />
                    ))
                  ) : (
                    <Text fontSize="sm" p="2" textAlign="center">
                      No results
                    </Text>
                  )}
                </Box>
                <MenuDivider />
                {filteredDept.length ? (
                  <Flex
                    bg="gray.50"
                    p={2}
                    alignItems="center"
                    justifyContent="space-between"
                    gap="2"
                  >
                    <Button
                      variant="outline"
                      onClick={() => {
                        setSelected([]);
                        onApply([]);
                        onClose();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      colorScheme="teal"
                      onClick={() => {
                        onApply(selected);
                        onClose();
                      }}
                    >
                      Apply
                    </Button>
                  </Flex>
                ) : null}
              </>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
