import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'src/store';
import { setSession, authFactory, getSession } from 'src/utils/axios';
import { User } from 'src/interfaces';
import { api } from '../services/baseApi';

export type SignedRoleT = 'admin' | 'super_admin' | 'user' | null;

type Actions = {
  user: User.UserObject | null;
  isAuthenticated: boolean;
  isSuccessModal: boolean;
  isMobileNav: boolean;
  isMultiRoleRedirect: boolean;
  currentSignedRole: SignedRoleT;
};

const initialState: Actions = {
  user: null,
  isAuthenticated: authFactory(getSession()?.token!),
  isSuccessModal: false,
  isMobileNav: false,
  isMultiRoleRedirect: false,
  currentSignedRole:
    (localStorage.getItem('signed_profile') as SignedRoleT) ?? null,
};

// Utility function to set signed profile
const utilitySignedProfile = (state: any, payload: SignedRoleT) => {
  const method = payload ? 'setItem' : 'removeItem';
  localStorage[method]('signed_profile', payload as string);

  state.isMultiRoleRedirect = false;
  state.isAuthenticated = !!payload;
  state.currentSignedRole = payload;
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(
      state,
      action: PayloadAction<{
        user: User.UserObject;
        token: string;
        refreshToken: string;
      } | null>
    ) {
      const { payload } = action;
      if (payload && payload.user.roles.length > 1) {
        state.isMultiRoleRedirect = true;
      } else {
        utilitySignedProfile(
          state,
          action.payload?.user.roles[0] as SignedRoleT
        );
      }
      state.user = payload && payload.user;
      setSession(payload);
    },
    setLoginUser(state, action: PayloadAction<User.UserObject>) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    toggleSuccessModal(state, action: PayloadAction<boolean>) {
      state.isSuccessModal = action.payload;
    },
    showMobileNav(state, action: PayloadAction<boolean>) {
      state.isMobileNav = action.payload;
    },
    setSignedProfile(state, action: PayloadAction<SignedRoleT>) {
      utilitySignedProfile(state, action.payload);
    },
  },
});

export const {
  setCredentials,
  toggleSuccessModal,
  setLoginUser,
  showMobileNav,
  setSignedProfile,
} = slice.actions;

export const reducer = slice.reducer;

export const resetStateAndCredentials = () => (dispatch: Dispatch) => {
  dispatch(setCredentials(null));
  dispatch(setSignedProfile(null));
  dispatch(api.util.resetApiState());
};
