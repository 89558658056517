import {
  Heading,
  Flex,
  Card,
  Skeleton,
  Button,
  Box,
  Text,
  useToast,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import { NAIRA_SYMBOL } from 'src/constant';
import {
  useFetchPlansQuery,
  usePostRenewSubscriptionMutation,
} from 'src/hooks/data/subscription';
import { useAuth } from 'src/hooks/useAuth';
import { toastParams } from 'src/utils/toastParams';

export const Plans = ({ customerCode }: { customerCode?: string }) => {
  const { user } = useAuth();
  const toast = useToast();

  const { plan, isLoading } = useFetchPlansQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      plan: data?.[0], // TODO: We currently have only one plan. This should be updated when there are multiple plans.
      ...rest,
    }),
  });
  const [mutateSubscription, { isLoading: isSubmitting }] =
    usePostRenewSubscriptionMutation();

  const isCurrentSubActive = user?.subscription?.status === 'active';

  const handleSubscription = async (
    amount: number,
    planCode: string,
    isRenewSub = false
  ) => {
    try {
      const response = await mutateSubscription({
        email: user!.email,
        companyUUID: user!.company!.companyUUID!,
        amount,
        planCode,
        isRenewSub,
        ...(isRenewSub && { customerCode }),
      }).unwrap();

      if (response?.authorization_url) {
        window.location.href = response?.authorization_url;
      } else {
        toast({
          title: 'Subscription renewed succesfully',
          status: 'success',
          ...toastParams,
        });
      }
    } catch (error: any) {
      toast({
        title: error?.data?.message,
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <Box>
      <Heading size="lg" my="6">
        Select Plan
      </Heading>

      <Flex justifyContent="space-between" flexWrap="wrap" gap={4}>
        <Card as={Skeleton} isLoaded={!isLoading} flexGrow={1} minW={250} p={4}>
          <Box
            mx="auto"
            width={100}
            height="60px"
            borderRadius={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="gray.400"
          >
            <Heading size="md">BASIC</Heading>
          </Box>
          <Heading textAlign="center" my="4">
            FREE{' '}
            <Text as="small" fontSize="initial" fontWeight="400">
              /always
            </Text>
          </Heading>
        </Card>
        <Card as={Skeleton} isLoaded={!isLoading} flexGrow={1} minW={250} p={4}>
          <Box
            mx="auto"
            width={100}
            height="60px"
            borderRadius={10}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="green.600"
          >
            <Heading size="md">PRO</Heading>
          </Box>
          <Heading textAlign="center" my="4">
            {NAIRA_SYMBOL}
            {(plan?.amount || 0)?.toLocaleString('en-US')}{' '}
            <Text as="small" fontSize="initial" fontWeight="400">
              /{plan?.interval}
            </Text>
          </Heading>
          {(user?.subscription?.planCode !== plan?.planCode ||
            (!isCurrentSubActive &&
              user?.subscription?.planCode === plan?.planCode)) && (
            <Button
              my={2}
              width="fit-content"
              mx="auto"
              bg="blue.900"
              color="white"
              _hover={{ bg: 'blue.800' }}
              disabled={isLoading || isSubmitting}
              isLoading={isSubmitting}
              spinner={<BeatLoader size={8} color="white" />}
              onClick={
                plan?.planCode
                  ? () =>
                      handleSubscription(
                        plan?.amount,
                        plan?.planCode,
                        !isCurrentSubActive
                      )
                  : undefined
              }
            >
              {!isCurrentSubActive ? 'Renew plan' : 'Upgrade to plan'}
            </Button>
          )}
        </Card>
      </Flex>
    </Box>
  );
};
