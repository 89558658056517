import {
  Box,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useAuth } from 'src/hooks/useAuth';

const Organization = () => {
  const { user } = useAuth();
  return (
    <Box width={{ base: '100%', md: '80%' }}>
      <Formik
        initialValues={{
          companyName: '' || user?.company?.companyName,
          companyPhone: '' || user?.company?.phone,
          companyAddress: '' || user?.company?.location,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
        })}
        onSubmit={() => {}}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gridGap="8"
              gridTemplateColumns="repeat(2, 1fr)"
              py="8"
            >
              <FormControl
                id="companyName"
                isInvalid={Boolean(touched.companyName && errors.companyName)}
              >
                <FormLabel htmlFor="companyName">Company name</FormLabel>
                <Input
                  type="text"
                  name="companyName"
                  size="lg"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyName}
                />
                <FormErrorMessage>{errors.companyName}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="companyPhone"
                isInvalid={Boolean(touched.companyPhone && errors.companyPhone)}
              >
                <FormLabel htmlFor="companyPhone">
                  Company phone number
                </FormLabel>
                <Input
                  type="text"
                  name="companyPhone"
                  size="lg"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyPhone}
                />
                <FormErrorMessage>{errors.companyPhone}</FormErrorMessage>
              </FormControl>
            </Box>
            <FormControl mb="8" _disabled={{ pointerEvent: 'none' }}>
              <FormLabel>Company email</FormLabel>
              <Input
                type="text"
                name="companyEmail"
                size="lg"
                value={user?.company?.companyEmail || ''}
                disabled
              />
            </FormControl>
            <FormControl
              id="companyAdrress"
              isInvalid={Boolean(
                touched.companyAddress && errors.companyAddress
              )}
              mb="8"
            >
              <FormLabel htmlFor="companyAddress">Company address</FormLabel>
              <Textarea
                name="companyAddress"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyAddress}
              />
              <FormErrorMessage>{errors.companyAddress}</FormErrorMessage>
            </FormControl>

            <Button
              colorScheme="teal"
              px="8"
              width={{ base: 'full', md: 'fit-content' }}
              type="submit"
              isLoading={isSubmitting}
              spinner={<BeatLoader size={8} color="white" />}
            >
              Save changes
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Organization;
