import {
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useGetPlanUsageQuery } from 'src/hooks/data/subscription';

export const CompanySubscriptionUsage = () => {
  const { isLoading, data } = useGetPlanUsageQuery();
  if (isLoading) return null;
  return (
    <TableContainer mt={8}>
      <Heading size="lg">Usage on current plan</Heading>
      <Table variant="simple">
        <TableCaption>
          At 90% usage, an email will be issued to you.
        </TableCaption>
        <Thead>
          <Tr>
            <Th width="60%" />
            <Th textAlign="center">Current</Th>
            <Th textAlign="center">Maximum</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Users</Td>
            <Td textAlign="center">{data?.usage?.users}</Td>
            <Td textAlign="center">{data?.plan?.users}</Td>
          </Tr>
          <Tr>
            <Td>Tasks</Td>
            <Td textAlign="center">{data?.usage?.tasks}</Td>
            <Td textAlign="center">{data?.plan?.tasks}</Td>
          </Tr>
          <Tr>
            <Td>Total File Size</Td>
            <Td textAlign="center">{data?.usage?.totalFileSize}</Td>
            <Td textAlign="center">{data?.plan?.totalFileSize}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
