import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiShare2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ConfirmModal } from 'src/components/ConfirmModal';
import PriorityBadge from 'src/components/priority/PriorityBadge';
import { copyTextToClipboard } from 'src/utils/copyFunc';
import { toastParams } from 'src/utils/toastParams';
import { useAuth } from 'src/hooks/useAuth';
import { useDeleteTaskMutation } from 'src/hooks/data/task';

type Props = { priority: string; taskId: string };

export const Actions = ({ priority, taskId }: Props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [deleteTask, { isLoading }] = useDeleteTaskMutation();
  const { isAdmin } = useAuth();
  const [copied, setCopied] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => setOpenModal(!openModal);

  const handleCopyURL = async () => {
    if (copied) {
      return;
    }

    const url = document.URL;
    const response = await copyTextToClipboard(url);
    if (response) {
      toast({
        description: 'Copied successfully.',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleDeleteTask = async () => {
    try {
      await deleteTask(taskId).unwrap();
      toggleModal();
      toast({
        title: 'Deleted successfully',
        status: 'success',
        ...toastParams,
      });
      navigate('/app/issues');
    } catch (error: any) {
      toast({
        title: error?.data?.message || 'Something went wrong',
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <>
      <Box
        width="100%"
        display="inline-flex"
        borderBottom="1px solid"
        borderColor="gray.300"
        alignItems="center"
        pb={2}
        gap={0.5}
      >
        <PriorityBadge priority={priority} />
        <Button variant="ghost" fontSize="sm" onClick={handleCopyURL}>
          <FiShare2 size={15} style={{ marginRight: '5px' }} /> Copy link
        </Button>
        {isAdmin && (
          <Button
            variant="ghost"
            fontSize="sm"
            onClick={toggleModal}
            colorScheme="red"
          >
            <RiDeleteBinLine size={15} style={{ marginRight: '5px' }} /> Delete
          </Button>
        )}
      </Box>

      {/* Modal */}
      <ConfirmModal
        title="delete"
        actionText="Delete"
        isOpen={openModal}
        onClose={toggleModal}
        handleContinue={handleDeleteTask}
        isSubmitting={isLoading}
      >
        <Text>Deleting this ticket is permanent. There is no undo.</Text>
      </ConfirmModal>
    </>
  );
};
