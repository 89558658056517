import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  ChakraProvider,
  ColorModeScript,
  createLocalStorageManager,
} from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import store from './store';
import 'focus-visible/dist/focus-visible';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

// This is to override app from any chakra-ui system-color mode on the browser
const manager = createLocalStorageManager('any-tmcolor-key');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <ChakraProvider theme={theme} colorModeManager={manager}>
        <Global styles={GlobalStyles} />
        <BrowserRouter>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
