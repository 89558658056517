import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { TiEdit } from 'react-icons/ti';
import Editor from 'src/components/editor/CKeditor';
import { useFetchUsersQuery } from 'src/hooks/data/auth';

type Props = {
  description: string;
  handleUpdateTask: (val: Record<string, string>) => void;
};

const Description = ({ description, handleUpdateTask }: Props) => {
  const { data = [] } = useFetchUsersQuery('activated');
  const [focusDescription, setFocusDescription] = useState(false);
  const [contents, setContents] = useState<string>(description || '');

  const handleCloseDescription = () => {
    setFocusDescription(!focusDescription);
    setContents(description ?? '');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleUpdateTask({ description: contents });
    setFocusDescription(false);
  };

  return (
    <>
      <Text fontWeight="semibold" mb="2">
        Description:{' '}
        {contents.length && !focusDescription ? (
          <Button
            type="button"
            variant="link"
            ml={4}
            onClick={() => setFocusDescription(true)}
          >
            Edit
            <TiEdit size={20} />
          </Button>
        ) : undefined}
      </Text>
      {!focusDescription ? (
        <Box
          p="2"
          mx="-2"
          _hover={{ bg: 'gray.200' }}
          cursor="pointer"
          rounded="md"
        >
          {!contents.length ? (
            <Text fontSize="sm" onClick={() => setFocusDescription(true)}>
              Add a more detailed description...
            </Text>
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: contents }} />
          )}
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box flexGrow={1}>
            <Editor
              contents={contents}
              setContents={setContents}
              shouldFocus={focusDescription}
              users={data}
            />
            <Flex gap="2" mt="2">
              <Button type="submit" colorScheme="teal">
                Submit
              </Button>
              <Button colorScheme="gray" onClick={handleCloseDescription}>
                Cancel
              </Button>
            </Flex>
          </Box>
        </form>
      )}
    </>
  );
};

export default Description;
