import axios from 'axios';
import { SERVER_URL } from 'src/constant';
import { api } from 'src/store/services/baseApi';

type SmtpT = {
  smtpHost: string;
  smtpUser: string;
  smtpPass: string;
  smtpFromEmail: string;
};

type SettingsT = SmtpT & {
  id: number;
  faviconUrl: string;
  logoUrl: string;
  updatedAt: string;
  createdAt: string;
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlatformSettings: build.query<SettingsT, void>({
      queryFn: async () => {
        try {
          const response = await axios.get(`${SERVER_URL}settings`);
          return { data: response.data };
        } catch (err: any) {
          return {
            error: { status: err?.response?.status, data: err?.response?.data },
          };
        }
      },
      providesTags: ['getPlatformSettings'],
    }),
    updatePlatformLogo: build.mutation<any, { id: number; data: FormData }>({
      query: ({ id, data }) => ({
        url: `settings/${id}/upload`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['getPlatformSettings'],
    }),
    updateSMTPSettings: build.mutation<any, { id: number; data: SmtpT }>({
      query: ({ id, data }) => ({ url: `settings/${id}`, method: 'PUT', data }),
      invalidatesTags: ['getPlatformSettings'],
    }),
    testSMTPSettings: build.mutation<any, { email: string }>({
      query: (data) => ({ url: 'settings/smtp/test', method: 'POST', data }),
    }),
  }),
});

export const {
  useGetPlatformSettingsQuery,
  useUpdatePlatformLogoMutation,
  useUpdateSMTPSettingsMutation,
  useTestSMTPSettingsMutation,
} = injectedRtkApi;
