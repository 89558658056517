import {
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Box,
  Divider,
  useMediaQuery,
  Badge,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AiOutlineUser } from 'react-icons/ai';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { FiSettings, FiUsers } from 'react-icons/fi';
import { MdOutlineLogout } from 'react-icons/md';
import {
  resetStateAndCredentials,
  setSignedProfile,
} from 'src/store/slices/auth';
import { useDispatch } from 'src/store';
import { useAuth } from 'src/hooks/useAuth';
import { IoMdSwitch } from 'react-icons/io';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMediaMobile] = useMediaQuery('(max-width: 500px)');
  const { user, isSuperAdmin } = useAuth();

  const logout = () => {
    dispatch(resetStateAndCredentials());
  };

  const handleSwitchAccount = () => {
    setSignedProfile('super_admin');
    return navigate('/app');
  };

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton as={Button} variant="link" color="primary">
            <Box position="relative" pl={2} pr={6}>
              <Box>
                <Avatar
                  size="sm"
                  name={`${user?.firstName} ${user?.lastName}`}
                  icon={<AiOutlineUser fontSize="1.5rem" />}
                />
                {!isMediaMobile && (
                  <Text fontSize="xs" mt="1">
                    Profile
                  </Text>
                )}
              </Box>
              <Box position="absolute" top="2.5" right="1.5">
                {isOpen ? <IoChevronUp /> : <IoChevronDown />}
              </Box>
            </Box>
          </MenuButton>
          <MenuList>
            <Box p="10px">
              <Flex alignItems="start">
                <Avatar
                  size="sm"
                  name={`${user?.firstName} ${user?.lastName}`}
                  icon={<AiOutlineUser fontSize="1.5rem" />}
                  mr={1}
                />
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    {user?.firstName} {user?.lastName}
                  </Text>
                  <Text fontSize="xs">{user?.email}</Text>
                </Box>
              </Flex>
            </Box>
            <Divider />
            <Box p="10px">
              <Text textAlign="center" fontSize="xs" fontWeight="bold">
                {user?.subscription?.plan}
                <Badge
                  colorScheme={
                    user?.subscription?.status === 'active' ? 'green' : 'red'
                  }
                  marginLeft={2}
                >
                  {user?.subscription?.status}
                </Badge>
              </Text>
            </Box>
            <Divider />
            {isSuperAdmin && (
              <>
                <MenuItem fontSize="xs" py="10px" onClick={handleSwitchAccount}>
                  <Flex alignItems="center">
                    <IoMdSwitch size="20px" style={{ marginRight: '10px' }} />{' '}
                    <Box>
                      <Text fontWeight="bold">Switch to:</Text>
                      <Text>Super admin account</Text>
                    </Box>
                  </Flex>
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem
              as={RouterLink}
              to="/app/settings/members"
              fontSize="xs"
              py="10px"
            >
              <Flex alignItems="center">
                <FiUsers size="20px" style={{ marginRight: '10px' }} /> Members
              </Flex>
            </MenuItem>
            <MenuItem
              as={RouterLink}
              to="/app/settings"
              fontSize="xs"
              py="10px"
            >
              <Flex alignItems="center">
                <FiSettings size="20px" style={{ marginRight: '10px' }} />{' '}
                Settings
              </Flex>
            </MenuItem>
            <Divider />
            <MenuItem fontSize="xs" py="10px" onClick={logout}>
              <Flex alignItems="center">
                <MdOutlineLogout size="20px" style={{ marginRight: '10px' }} />{' '}
                Logout
              </Flex>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default Profile;
