import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import CustomDrawer from 'src/components/CustomDrawer';
import { useFetchUserQuery } from 'src/hooks/data/auth';
import { useAuth } from 'src/hooks/useAuth';
import { ChangeUserRole } from './components/ChangeUserRole';
import { UserAccount } from './components/UserAccount';

const MemberDetails = () => {
  const { isAdmin } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { isFetching, data } = useFetchUserQuery(params.id as string, {
    skip: !params.id,
  });

  useEffect(() => {
    if (params.id) {
      setOpenDrawer(true);
    }
  }, [params]);

  return (
    <CustomDrawer
      isOpen={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
        navigate('/app/settings/members');
      }}
      placement="right"
      size="xl"
      title="Settings"
    >
      <Tabs>
        <TabList>
          <Tab>Account</Tab>
          {isAdmin && <Tab>Change role</Tab>}
          <Tab>Recent activity</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <UserAccount data={data} isLoading={isFetching} />
          </TabPanel>
          {isAdmin && (
            <TabPanel>
              <ChangeUserRole data={data} isLoading={isFetching} />
            </TabPanel>
          )}
          <TabPanel>
            <Text>Recent Activity</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CustomDrawer>
  );
};

export default MemberDetails;
