import { VStack, StackDivider } from '@chakra-ui/react';
import React from 'react';

type StaticListProps<TItem> = {
  data: TItem[];
  renderItem: (item: TItem, index: number) => React.ReactElement;
  spacing?: number;
};

export const StackList = <TItem,>({
  data,
  renderItem,
  spacing = 4,
}: StaticListProps<TItem>) => {
  return (
    <VStack
      divider={<StackDivider borderColor="gray.100" />}
      spacing={spacing}
      align="stretch"
    >
      {data.map(renderItem)}
    </VStack>
  );
};
