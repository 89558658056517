import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MdAttachment } from 'react-icons/md';
import { ConfirmModal } from 'src/components/ConfirmModal';
import FilePreview from 'src/components/FilePreview';
import UploadInput from 'src/components/UploadInput';
import { FileType } from 'src/interfaces/task';
import { toastParams } from 'src/utils/toastParams';
import { getFileSize } from 'src/utils/getFileSize';
import axios from 'src/utils/axios';

import SwiperModal from 'src/components/swiperModal';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import {
  useDeleteAttachmentMutation,
  useUpdateTaskMutation,
} from 'src/hooks/data/task';

type PropTypes = { files: Array<FileType>; taskId: string };

const Attachments = ({ taskId, files }: PropTypes) => {
  const toast = useToast();
  const [updateTask, { isLoading: isUpdating }] = useUpdateTaskMutation();
  const [deleteAttachment, { isLoading: isDeleting }] =
    useDeleteAttachmentMutation();
  const [openModal, setOpenModal] = useState(false);
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [actionType, setActionType] = useState<'upload' | 'delete' | undefined>(
    undefined
  );
  const [isOpenSwiper, setIsOpenSwiper] = useState(false);
  const [selectedFileView, setSelectedFileView] = useState<number | undefined>(
    undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const dispatchActions = useDispatchActions();

  const onCloseModal = () => {
    setOpenModal(false);
    setSelectedFile(null);
    setUploadFiles([]);
  };

  useEffect(() => {
    if (uploadFiles.length) {
      setOpenModal(true);
      setActionType('upload');
    }
    if (!uploadFiles.length && openModal) {
      onCloseModal();
    }
  }, [uploadFiles]);

  const handleDeleteAttachment = () => {
    if (!selectedFile) return;

    dispatchActions(deleteAttachment({ taskId, fileId: selectedFile }), () => {
      onCloseModal();
      setSelectedFile(null);
      setIsOpenSwiper(false);
    });
  };

  const handleDeleteUploadFile = (index: number) => {
    setUploadFiles((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleUploadAttachment = () => {
    const formData = new FormData();
    uploadFiles.forEach((file) => {
      formData.append('files', file);
    });

    dispatchActions(updateTask({ taskId, data: formData }), () => {
      onCloseModal();
      setUploadFiles([]);
    });
  };

  const handleDownloadAttachment = async (fileUUID: string) => {
    try {
      const response = await axios.get(`task/${fileUUID}/file`);
      window.open(response.data.signedDownloadURL, '_blank');
    } catch (error: any) {
      toast({
        title: error?.data?.message || 'Something went wrong',
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <>
      <Box mt={8}>
        <Box display="flex" alignItems="start" gap="2" mb="2">
          <MdAttachment size="20px" />
          <Text fontWeight="semibold">Attachments</Text>
        </Box>
        {files.map((file, index) => (
          <FilePreview
            key={file.fileUUID}
            file={file}
            onDownload={() => handleDownloadAttachment(file.fileUUID)}
            onDelete={() => {
              setOpenModal(true);
              setActionType('delete');
              setSelectedFile(file.fileUUID);
            }}
            clickFilePreview={() => {
              setIsOpenSwiper(true);
              setSelectedFileView(index);
            }}
          />
        ))}

        <UploadInput ref={inputRef} files={files} setFiles={setUploadFiles} />
        <Button
          mt="4"
          px="4"
          bg="gray.200"
          fontSize="sm"
          rounded="base"
          onClick={() => inputRef.current?.click()}
        >
          Add an attachment
        </Button>
      </Box>

      {/* Modal */}
      {isOpenSwiper && (
        <SwiperModal
          files={files}
          initialSlide={selectedFileView}
          isOpen={isOpenSwiper}
          onClose={() => {
            setIsOpenSwiper(false);
            setSelectedFileView(undefined);
          }}
          onDownload={(fileId) => handleDownloadAttachment(fileId)}
          onDelete={(fileId) => {
            setOpenModal(true);
            setActionType('delete');
            setSelectedFile(fileId);
          }}
        />
      )}

      <ConfirmModal
        title={actionType === 'delete' ? 'delete' : 'upload'}
        actionText={actionType === 'delete' ? 'Delete' : 'Upload'}
        isOpen={openModal}
        onClose={onCloseModal}
        handleContinue={
          actionType === 'delete'
            ? handleDeleteAttachment
            : handleUploadAttachment
        }
        isSubmitting={isUpdating || isDeleting}
      >
        {actionType === 'delete' ? (
          <Text>Deleting this attachment is permanent. There is no undo.</Text>
        ) : (
          <Box>
            {uploadFiles.map((uploadFile, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                border="1px solid"
                borderColor="gray.300"
                rounded="md"
                p="2"
                mb={2}
              >
                <Box fontSize="xs">
                  <Text fontWeight="semibold">{uploadFile.name}</Text>
                  <Text>File size: {getFileSize(uploadFile.size)}</Text>
                </Box>
                <Button size="xs" onClick={() => handleDeleteUploadFile(index)}>
                  X
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </ConfirmModal>
    </>
  );
};

export default Attachments;

function useDispatchActions() {
  const toast = useToast();

  return useCallback(
    (
      actions: MutationActionCreatorResult<any>,
      callbackSuccess: () => void
    ) => {
      actions
        .unwrap()
        .then(() => {
          toast({
            title: 'Successful',
            status: 'success',
            ...toastParams,
          });
          callbackSuccess();
        })
        .catch((error) => {
          toast({
            title: error?.data?.message || 'Something went wrong!',
            status: 'error',
            ...toastParams,
          });
        });
    },
    [toast]
  );
}
