import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Link,
  Flex,
  Box,
  Skeleton,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Card from 'src/components/Card';
import CardTitle from 'src/components/CardTitle';
import StatusDropdown from 'src/components/dropdown/StatusDropdown';
import EmptyResult from 'src/components/EmptyResult';
import ProfileCard from 'src/components/ProfileCard';
import { useFetchTasksQuery } from 'src/hooks/data/task';
import { useAuth } from 'src/hooks/useAuth';
import { Task } from 'src/interfaces';
import { lifeSpanCalculator } from 'src/utils/lifeSpanCalculator';
import { CreateIssue } from '../../board/components/CreateIssue';

const SkeletonRow = () => (
  <Tr>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td width="40%">
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
  </Tr>
);

const TicketSummary = () => {
  const { isAdmin } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { tasks, isLoading } = useFetchTasksQuery(
    { isAdmin },
    {
      selectFromResult: (results) => ({
        tasks: [...(results.data?.rows || [])].slice(0, 10),
        ...results,
      }),
    }
  );
  return (
    <>
      <Card
        position="relative"
        width="100%"
        height="100%"
        display="flex"
        overflow="hidden"
        flexFlow="column nowrap"
      >
        <Flex justifyContent="space-between">
          <CardTitle text="Recent Tickets" />
          {tasks.length ? (
            <Link fontSize="xs" as={RouterLink} to="/app/issues">
              View all
            </Link>
          ) : undefined}
        </Flex>
        <Box overflow="auto" flexGrow={1}>
          <Table
            fontSize="sm"
            variant="striped"
            colorScheme="blackAlpha"
            minW="1000px"
          >
            <Thead>
              <Tr>
                <Th>Ticket ID</Th>
                <Th width="40%">Ticket title</Th>
                <Th>Lifespan</Th>
                <Th>Status</Th>
                <Th>Assignee</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading ? (
                <>
                  <SkeletonRow />
                  <SkeletonRow />
                  <SkeletonRow />
                </>
              ) : (
                tasks.map((task) => (
                  <Tr key={task.id}>
                    <Td>
                      <Link as={RouterLink} to={`/app/issues/${task.id}`}>
                        <Text
                          whiteSpace="nowrap"
                          decoration={
                            task.status === Task.Status.COMPLETED
                              ? 'line-through'
                              : ''
                          }
                        >
                          {task.id}
                        </Text>
                      </Link>
                    </Td>
                    <Td>
                      <Link as={RouterLink} to={`/app/issues/${task.id}`}>
                        <Text noOfLines={2}>{task.title}</Text>
                      </Link>
                    </Td>
                    <Td whiteSpace="nowrap">
                      {lifeSpanCalculator(task).lifeSpan}
                    </Td>
                    <Td>
                      <StatusDropdown status={task.status} taskId={task.id} />
                    </Td>
                    <Td>
                      <ProfileCard user={task.assigned} />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
          {!isLoading && !tasks.length && (
            <EmptyResult
              title="No results"
              subtitle="All recent issues would be shown here once you have one"
            >
              <Button
                bg="blue.900"
                color="white"
                _hover={{ bg: 'blue.900' }}
                my={3}
                onClick={() => setIsOpen(!isOpen)}
              >
                Create issue
              </Button>
            </EmptyResult>
          )}
        </Box>
      </Card>

      {/* CREATE ISSUE MODAL */}
      <CreateIssue isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </>
  );
};

export default TicketSummary;
