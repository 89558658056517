import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { VscCircuitBoard } from 'react-icons/vsc';
import { CiBoxList } from 'react-icons/ci';
import { useNavigate, useParams } from 'react-router';
import CustomDrawer from 'src/components/CustomDrawer';
import { useGetDepartmentQuery } from 'src/hooks/data/department';
import { DepartmentOverview } from './components/DepartmentOverview';

const DepartmentDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { isLoading, data } = useGetDepartmentQuery(
    {
      id: params.id as string,
    },
    { skip: !params.id }
  );

  useEffect(() => {
    if (params.id) {
      setOpenDrawer(true);
    }
  }, [params]);

  return (
    <CustomDrawer
      isOpen={openDrawer}
      onClose={() => {
        setOpenDrawer(false);
        navigate('/app/settings/departments');
      }}
      placement="right"
      size="xl"
      title="Department"
    >
      <Tabs>
        <TabList>
          <Tab>
            <VscCircuitBoard style={{ marginRight: '5px' }} />
            Overview
          </Tab>
          <Tab>
            <CiBoxList style={{ marginRight: '5px' }} /> List
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <DepartmentOverview data={data} isLoading={isLoading} />
          </TabPanel>
          <TabPanel>
            <Text>List tasks or activities associated to department</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </CustomDrawer>
  );
};

export default DepartmentDetails;
