import { RouteObject } from 'react-router-dom';
import IssueList from './issueList';
import IssueDetails from './issueDetails';

export const issueRoutes: RouteObject[] = [
  {
    path: '',
    element: <IssueList />,
  },
  {
    path: ':slug',
    element: <IssueDetails />,
  },
];
