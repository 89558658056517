import {
  Box,
  Text,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  Avatar,
  FormLabel,
  Alert,
  AlertDescription,
  AlertIcon,
  Card,
  Tag,
  MenuButton,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useUpdateProfileMutation } from 'src/hooks/data/auth';
import { useAuth } from 'src/hooks/useAuth';
import { BsArrowRight } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { Department } from 'src/interfaces/company';
import { AiOutlinePlus } from 'react-icons/ai';
import { DepartmentDropdown } from 'src/components/dropdown/departmentDropdown';

const Profile = () => {
  const { user, isAdmin } = useAuth();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const [selectedDepts, setSelectedDepts] = useState<Department[]>([]);

  useEffect(() => {
    if (user?.departments) {
      setSelectedDepts(user.departments);
    }
  }, [user]);

  return (
    <Box>
      <Card
        as={RouteLink}
        to={`/app/issues?userId=${user?.id}`}
        textDecoration="none"
        my={4}
        p={3}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        _hover={{
          color: '#333984',
          backgroundColor: '#e2e8f0',
        }}
      >
        <Text>View all tasks assigned to you</Text>
        <BsArrowRight />
      </Card>
      <Flex
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        justifyContent="start"
        columnGap="8"
      >
        <Avatar
          mt="20px"
          size="xl"
          name={`${user?.firstName} ${user?.lastName}`}
        />
        <Box width={{ base: '100%', md: '80%' }}>
          <Formik
            initialValues={{
              firstName: user?.firstName || '',
              lastName: user?.lastName || '',
              phone: '',
              jobTitle: user?.jobTitle || '',
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last name is required'),
            })}
            onSubmit={async (values, { setStatus }) => {
              const { submit, ...rest } = values;
              updateProfile({
                ...rest,
                id: user!.id,
                departments: selectedDepts.map((dept) => dept.id),
                isAdmin,
              })
                .unwrap()
                .then(() => {
                  setStatus({
                    type: 'success',
                    title: 'Success',
                    message: 'Updated successfully',
                  });
                })
                .catch((error) => {
                  setStatus({
                    type: 'error',
                    title: error?.data?.error,
                    message: error?.data?.message,
                  });
                })
                .finally(() => {
                  setTimeout(() => {
                    setStatus({});
                  }, 5000);
                });
            }}
          >
            {({
              status,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gridGap="8"
                  gridTemplateColumns="repeat(2, 1fr)"
                  py="8"
                >
                  <FormControl
                    id="firstName"
                    isInvalid={Boolean(touched.firstName && errors.firstName)}
                  >
                    <FormLabel htmlFor="firstName">First name</FormLabel>
                    <Input
                      type="text"
                      name="firstName"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="lastName"
                    isInvalid={Boolean(touched.lastName && errors.lastName)}
                  >
                    <FormLabel htmlFor="lastName">Last name</FormLabel>
                    <Input
                      type="text"
                      name="lastName"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    textColor="gray.500"
                    _disabled={{ pointerEvent: 'none' }}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input type="text" size="lg" value={user?.email} disabled />
                  </FormControl>
                  <FormControl
                    id="jobTitle"
                    isInvalid={Boolean(touched.jobTitle && errors.jobTitle)}
                  >
                    <FormLabel htmlFor="jobTitle">Job title</FormLabel>
                    <Input
                      type="text"
                      name="jobTitle"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.jobTitle}
                    />
                    <FormErrorMessage>{errors.jobTitle}</FormErrorMessage>
                  </FormControl>
                  <Box>
                    <Box mb={2} display="flex" gap={2}>
                      <Text fontWeight="medium">Department</Text>
                      {isAdmin && (
                        <DepartmentDropdown
                          selectedData={selectedDepts}
                          onApply={(val) => {
                            setSelectedDepts(val);
                          }}
                        >
                          <MenuButton
                            size="xs"
                            as={Button}
                            bg="blue.900"
                            color="white"
                            _hover={{ bg: 'blue.900' }}
                          >
                            <AiOutlinePlus />
                          </MenuButton>
                        </DepartmentDropdown>
                      )}
                    </Box>
                    <Flex
                      minH="3rem"
                      flexWrap="wrap"
                      border="1px solid"
                      borderColor="gray.200"
                      rounded="md"
                      p={2}
                      gap={2}
                    >
                      {selectedDepts.map((dept, index) => (
                        <Tag key={index} variant="outline" colorScheme="blue">
                          {dept.name}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                  <FormControl
                    id="phone"
                    isInvalid={Boolean(touched.phone && errors.phone)}
                  >
                    <FormLabel htmlFor="phone">Phone number</FormLabel>
                    <Input
                      type="text"
                      name="phone"
                      size="lg"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                    />
                    <FormErrorMessage>{errors.phone}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    id="role"
                    textColor="gray.500"
                    _disabled={{ pointerEvent: 'none' }}
                  >
                    <FormLabel htmlFor="role">Role</FormLabel>
                    <Input
                      type="text"
                      size="lg"
                      textTransform="capitalize"
                      value={user?.roles[0]}
                      disabled
                    />
                  </FormControl>
                </Box>
                {status && status?.type && (
                  <Alert status={status?.type} mb="1rem">
                    <AlertIcon />
                    <AlertDescription>
                      <Text fontWeight="semibold">{status?.title}</Text>
                      <Text>{status?.message}</Text>
                    </AlertDescription>
                  </Alert>
                )}
                <Button
                  colorScheme="teal"
                  px="8"
                  width={{ base: 'full', md: 'fit-content' }}
                  type="submit"
                  isLoading={isLoading}
                  spinner={<BeatLoader size={8} color="white" />}
                >
                  Save changes
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Box>
  );
};

export default Profile;
