import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FaPencilAlt } from 'react-icons/fa';
import { BeatLoader } from 'react-spinners';
import Card from 'src/components/Card';
import { toastParams } from 'src/utils/toastParams';
import {
  useGetPlatformSettingsQuery,
  useUpdatePlatformLogoMutation,
} from 'src/hooks/data/admin/settings';
import { PreviewFile } from './PreviewFile';

type ChangeBtnProps = {
  onChange(e: any): void;
  mimeType: string;
};

const ChangeButton = ({ onChange, mimeType }: ChangeBtnProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <IconButton
        aria-label="change icon"
        position="absolute"
        top="-15px"
        right="-15px"
        isRound
        size="sm"
        icon={<FaPencilAlt />}
        onClick={() => inputRef?.current?.click()}
      />
      <input
        type="file"
        accept={mimeType}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => onChange(e.target.files)}
        size={1024}
      />
    </>
  );
};

const MAX_FILE_SIZE = 1024; // 1MB

const General = () => {
  const toast = useToast();
  const [fieldState, setFieldState] = useState(
    {} as Record<'logo' | 'favicon', File | undefined>
  );

  const { data } = useGetPlatformSettingsQuery();
  const [updateLogos, { isLoading: isUploading }] =
    useUpdatePlatformLogoMutation();

  const pickImg = (key: string, files: any) => {
    const file = files[0] || {};
    const fileSize = file.size / 1024;

    if (fileSize > MAX_FILE_SIZE) {
      toast({
        title: 'File must not be larger than 1MB',
        status: 'error',
        ...toastParams,
      });
      return;
    }

    setFieldState((prev) => ({ ...prev, [key]: file }));
  };

  const handleFileUpload = () => {
    if (!fieldState.logo && !fieldState.favicon) {
      toast({
        title: 'Fields must not be empty',
        status: 'error',
        ...toastParams,
      });
      return;
    }

    if (!data?.id) {
      toast({
        title: "No existing records. Can't update settings",
        status: 'error',
        ...toastParams,
      });
      return;
    }

    const formData = new FormData();
    formData.append('logo', fieldState.logo!);
    formData.append('favicon', fieldState.favicon!);

    updateLogos({ id: data?.id, data: formData })
      .unwrap()
      .then(() => {
        toast({
          title: 'Save changes successful',
          status: 'success',
          ...toastParams,
        });
        setFieldState({
          logo: undefined,
          favicon: undefined,
        });
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <Box py={6}>
      <Card maxW="700px" mx="auto">
        <Heading as="h3" size="md" textAlign="center" mb="10">
          General Settings
        </Heading>
        <Flex mb={6}>
          <Text w={150} fontWeight="medium">
            Logo
          </Text>
          <Box>
            <Flex
              width="100px"
              height="100px"
              justify="center"
              align="center"
              border="2px"
              position="relative"
            >
              <ChangeButton
                mimeType="image/png, image/jpeg"
                onChange={(files) => pickImg('logo', files)}
              />
              <PreviewFile file={fieldState.logo} uploadUrl={data?.logoUrl} />
            </Flex>
            <Text as="small">
              <em>only png or jpg is allowed</em>
            </Text>
          </Box>
        </Flex>
        <Flex>
          <Text w={150} fontWeight="medium">
            Favicon
          </Text>
          <Flex
            width="80px"
            height="80px"
            justify="center"
            align="center"
            border="2px"
            position="relative"
          >
            <ChangeButton
              mimeType="image/vnd.microsoft.icon, image/png"
              onChange={(files) => pickImg('favicon', files)}
            />
            <PreviewFile
              file={fieldState.favicon}
              uploadUrl={data?.faviconUrl}
            />
          </Flex>
        </Flex>

        <Flex mt="60px" gap={5}>
          <Button
            colorScheme="teal"
            isLoading={isUploading}
            spinner={<BeatLoader size={8} color="white" />}
            isDisabled={!fieldState.logo && !fieldState.favicon}
            onClick={handleFileUpload}
          >
            Save changes
          </Button>
          {(fieldState.logo || fieldState.favicon) && (
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() =>
                setFieldState({
                  logo: undefined,
                  favicon: undefined,
                })
              }
            >
              Reset
            </Button>
          )}
        </Flex>
      </Card>
    </Box>
  );
};

export default General;
