import { RouteObject, Navigate } from 'react-router-dom';
import NotFound from '../app/error/NotFound';
import Dashboard from './dashboard';
import { Settings } from './settings';
import { settingsRoutes } from './settings/settings.routes';
import { Subscription } from './subscription';

export const adminRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'subscription',
    element: <Subscription />,
  },
  {
    path: 'settings',
    element: <Settings />,
    children: settingsRoutes,
  },
  { path: '', element: <Navigate to="/admin/dashboard" /> },
  { path: '*', element: <NotFound /> },
];
