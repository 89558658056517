import { useRoutes, Navigate } from 'react-router-dom';
import { GuestGuard, AuthGuard, SuperAdminGuard } from 'src/components/guards';
import AuthBase from 'src/views/auth/Base';
import { DashboardLayout, SuperDashboardLayout } from 'src/layouts';
import { authRoutes } from 'src/views/auth/auth.routes';
import { appRoutes } from 'src/views/app/app.routes';
import { adminRoutes } from 'src/views/admin/admin.routes';
import { useFetchMeQuery } from './hooks/data/auth';
import { getSession } from './utils/axios';
import { useAuth } from './hooks/useAuth';
import { useGetPlatformSettingsQuery } from './hooks/data/admin/settings';
import PageHelmet from './components/PageHelmet';

function App() {
  const routing = useRoutes([
    {
      path: 'auth',
      element: <GuestGuard component={<AuthBase />} />,
      children: authRoutes,
    },
    {
      path: 'app',
      element: <AuthGuard component={<DashboardLayout />} />,
      children: appRoutes,
    },
    {
      path: 'admin',
      element: <SuperAdminGuard component={<SuperDashboardLayout />} />,
      children: adminRoutes,
    },
    { path: '/', element: <Navigate to="/auth/login" /> },
  ]);

  const {
    isLoading: isSettingsLoading,
    data: platformSettingsData,
    isError: isErrorSettings,
  } = useGetPlatformSettingsQuery();
  const { isAuthenticated } = useAuth();
  const skipFetchUser = !!getSession()?.token || isAuthenticated;
  // Fetch user only there's a session.
  const { isLoading } = useFetchMeQuery(undefined, {
    skip: !skipFetchUser,
  });

  if (isLoading || isSettingsLoading || isErrorSettings) {
    return <h1>Loading....</h1>;
  }

  return (
    <PageHelmet
      title="Vezeti Task Management"
      description="A centralized platform for improving team efficiency, users can create, assign, prioritize, and monitor tasks, ensuring effective collaboration and productivity."
      faviconUrl={platformSettingsData?.faviconUrl}
    >
      {routing}
    </PageHelmet>
  );
}

export default App;
