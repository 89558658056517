import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Flex,
  Avatar,
  Box,
  Text,
  Button,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { UserStrip } from 'src/interfaces/task';

type CardProps = {
  user: UserStrip;
};

const ProfileCard = ({ user }: CardProps) => {
  if (!user.firstName && !user.lastName) {
    return (
      <Flex alignItems="center" whiteSpace="nowrap">
        <Avatar size="xs" mr="2" /> Unassigned
      </Flex>
    );
  }
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex alignItems="center" whiteSpace="nowrap">
          <Avatar
            name={`${user.firstName} ${user.lastName}`}
            size="xs"
            mr="2"
          />{' '}
          {user.firstName} {user.lastName}
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          color="white"
          bg="blue.800"
          borderColor="blue.800"
          width="300px"
        >
          <PopoverBody>
            <Flex align="center">
              <Avatar
                name={`${user.firstName} ${user.lastName}`}
                size="lg"
                mr="3"
              />
              <Box>
                <Text noOfLines={1} fontWeight="bold">
                  {user.firstName} {user.lastName}
                </Text>
                {user.jobTitle && (
                  <Text fontSize="xs" noOfLines={1} mb="2.5" textStyle="bold">
                    {user.jobTitle}
                  </Text>
                )}
                <Flex alignItems="center" fontSize="xs" gap="1">
                  <HiOutlineMail /> {user.email}
                </Flex>
                <Button
                  size="sm"
                  colorScheme="teal"
                  mt="2"
                  as={RouterLink}
                  to={`/app/settings/members/${user.id}`}
                  color="white"
                  bg="teal.500"
                  _hover={{ color: 'white', bg: 'teal.700' }}
                  textDecoration="none"
                >
                  View profile
                </Button>
              </Box>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ProfileCard;
