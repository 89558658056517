/* eslint-disable no-nested-ternary */
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  HStack,
  MenuButton,
  Skeleton,
  Tag,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link as RouterLink } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { DepartmentDropdown } from 'src/components/dropdown/departmentDropdown';
import {
  useSendVerifyEmailMutation,
  useUpdateUserByAdminMutation,
} from 'src/hooks/data/auth';
import { useAuth } from 'src/hooks/useAuth';
import { UserObject } from 'src/interfaces/user';
import { toastParams } from 'src/utils/toastParams';

export const UserAccount = ({
  data,
  isLoading,
}: {
  data?: UserObject;
  isLoading: boolean;
}) => {
  const { isAdmin } = useAuth();
  const toast = useToast();
  const [updateUserStatus, { isLoading: isUpdating }] =
    useUpdateUserByAdminMutation();
  const [sendVerify, { isLoading: isSending }] = useSendVerifyEmailMutation();
  const [openModal, setOpenModal] = useState(false);

  const isActivatedUser = data?.status === 'activated';

  const handleUpdate = async (val: Record<string, any>) => {
    updateUserStatus({
      id: data?.id!,
      data: val,
    })
      .unwrap()
      .then((_res) => {
        setOpenModal(false);
        toast({
          title: 'Successful',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((error) => {
        toast({
          title: error?.data?.message || 'Something went wrong!',
          status: 'error',
          ...toastParams,
        });
      });
  };

  const handleResendVerify = () => {
    sendVerify(data?.email!)
      .unwrap()
      .then(() => {
        toast({
          title: 'Verification sent successfully.',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((error) => {
        toast({
          title: error?.data?.message || 'Something went wrong!',
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <Box>
      <Box py={6}>
        <Text fontSize="xl" fontWeight="semibold" mb={2}>
          Profile
        </Text>
        <Grid
          templateColumns={{
            base: '1fr',
            sm: 'repeat(2, 1fr)',
          }}
          gap={4}
        >
          <Box>
            <Text>First name</Text>
            <Skeleton minHeight="20px" isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="medium">
                {data?.firstName}
              </Text>
            </Skeleton>
          </Box>
          <Box>
            <Text>Last name</Text>
            <Skeleton minHeight="20px" isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="medium">
                {data?.lastName}
              </Text>
            </Skeleton>
          </Box>
          <Box>
            <Text>Job title</Text>
            <Skeleton minHeight="20px" isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="medium">
                {data?.jobTitle || '-'}
              </Text>
            </Skeleton>
          </Box>
          <Box>
            <Text>Role</Text>
            <Skeleton minHeight="20px" isLoaded={!isLoading}>
              <Badge colorScheme="twitter">{data?.roles[0]}</Badge>
            </Skeleton>
          </Box>
          <Box>
            <Text>Email</Text>
            <Skeleton minHeight="20px" isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="medium">
                {data?.email}
              </Text>
            </Skeleton>
          </Box>
        </Grid>
      </Box>
      <Divider />
      <Box py={6}>
        <Box display="flex" gap={2}>
          <Text fontSize="xl" fontWeight="semibold" mb={2}>
            Departments
          </Text>
          {isAdmin && (
            <DepartmentDropdown
              selectedData={data?.departments || []}
              onApply={(val) => {
                const ids = val.map((i) => i.id);
                handleUpdate({ departments: ids });
              }}
            >
              <MenuButton
                size="xs"
                as={Button}
                bg="blue.900"
                color="white"
                _hover={{ bg: 'blue.900' }}
              >
                <AiOutlinePlus />
              </MenuButton>
            </DepartmentDropdown>
          )}
        </Box>

        <Box>
          <HStack spacing={4}>
            {isLoading ? (
              Array.from({ length: 3 }, (_, index) => (
                <Skeleton
                  minHeight="30px"
                  width={100}
                  rounded="md"
                  key={index}
                />
              ))
            ) : data?.departments?.length ? (
              data?.departments.map((dept, index) => (
                <Tag
                  as={RouterLink}
                  to={`/app/settings/departments/${dept.id}`}
                  textDecoration="none"
                  size="lg"
                  key={index}
                  variant="outline"
                  colorScheme="blue"
                >
                  {dept.name}
                </Tag>
              ))
            ) : (
              <Text fontSize="xs">
                <em>--Not in any department --</em>
              </Text>
            )}
          </HStack>
        </Box>
      </Box>
      <Divider />
      {!isLoading &&
        isAdmin &&
        (data?.status === 'pending' ? (
          <Box py={6}>
            <Text fontSize="xl" fontWeight="semibold" mb={2}>
              Resend verification
            </Text>
            <Text mb={3}>
              Can only send code after 24 hours of previous send.
            </Text>
            <Button
              size="lg"
              colorScheme="twitter"
              isLoading={isSending}
              spinner={<BeatLoader size={8} color="white" />}
              onClick={handleResendVerify}
            >
              Resend
            </Button>
          </Box>
        ) : (
          !data?.roles.includes('admin') && (
            <Box py={6}>
              <Text fontSize="xl" fontWeight="semibold" mb={2}>
                {data?.status === 'activated' ? 'Deactivate' : 'Activate'}{' '}
                Account
              </Text>
              <Text mb={3}>
                Remove user from all access to the organization
              </Text>
              <Button
                size="lg"
                colorScheme={data?.status === 'activated' ? 'red' : 'green'}
                onClick={() => setOpenModal(true)}
              >
                {data?.status === 'activated' ? 'Deactivate' : 'Activate'} this
                user
              </Button>
            </Box>
          )
        ))}

      <ConfirmModal
        title={
          data?.status === 'activated' ? 'deactivate user' : 'activate user'
        }
        actionText="Yes, continue"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        handleContinue={() =>
          handleUpdate({
            status: isActivatedUser ? 'deactivated' : 'activated',
          })
        }
        isSubmitting={isUpdating}
      />
    </Box>
  );
};
