import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  MenuButton,
  Switch,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import PriorityDropdown from 'src/components/dropdown/PriorityDropdown';
import StatusDropdown from 'src/components/dropdown/StatusDropdown';
import { Task } from 'src/interfaces';
import UserDropdown from 'src/components/dropdown/UserDropdown';

import { toastParams } from 'src/utils/toastParams';
import { lifeSpanCalculator } from 'src/utils/lifeSpanCalculator';
import { useUpdateTaskMutation } from 'src/hooks/data/task';
import { MultipleUserDropdown } from 'src/components/dropdown/MultipleUserDropdown';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import DateTimeDropdown from 'src/components/dropdown/DateTimeDropdown';
import { dateToTimeAgo } from 'src/utils/time-date-functions';

const FlexContent = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Flex wrap="wrap" fontSize="sm" wordBreak="break-word" my="6">
    <Box
      position="relative"
      flexGrow={1}
      maxWidth="175px"
      lineHeight="1"
      pt="8px"
      width="35%"
      pr="24px"
      boxSizing="border-box"
    >
      <Text>{title}</Text>
    </Box>
    <Box pt="4px" boxSizing="border-box" width="65%" flexGrow={1}>
      {children}
    </Box>
  </Flex>
);

export default function RightCol({ task }: { task: Task.Task }) {
  const [updateTask, { isLoading }] = useUpdateTaskMutation();
  const { permittedUsers } = useFetchUsersQuery('activated', {
    selectFromResult: (results) => ({
      permittedUsers: [...(results?.data ?? [])].filter((user) => {
        return (task.permitted ?? []).includes(user.email);
      }),
    }),
  });
  const toast = useToast();

  const handleUpdateTask = (payload: Record<string, unknown>) => {
    const newPayload = {
      ...payload,
      taskId: task.id,
    };

    updateTask({ taskId: task.id, data: newPayload })
      .unwrap()
      .then(() => {
        toast({
          title: 'Updated Successfully',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <Box border="1px" bg="white" borderRadius="md" px="4">
      <FlexContent title="Assignee">
        <UserDropdown
          data={task.assigned}
          onChangeUser={handleUpdateTask}
          enableClick
        />
      </FlexContent>
      <FlexContent title="Status">
        <StatusDropdown
          status={task.status}
          taskId={task.id}
          padding="1"
          border="1px"
          borderRadius="md"
        />
      </FlexContent>
      <FlexContent title="Priority">
        <PriorityDropdown
          priority={task.priority}
          onChange={handleUpdateTask}
        />
      </FlexContent>
      <FlexContent title="Due date">
        <DateTimeDropdown
          estimatedDate={task.estimate}
          isOverdue={lifeSpanCalculator(task).overdue}
          onClickApply={(est) => handleUpdateTask({ estimate: est })}
          isLoading={isLoading}
        />
      </FlexContent>
      <FlexContent title="Created by">
        <UserDropdown data={task.user} />
        <Text fontSize="xs">{dateToTimeAgo(String(task.createdAt))}</Text>
      </FlexContent>
      <Box display="flex" alignItems="start" gap="2" my={6}>
        <Switch
          id="private-task"
          onChange={(e) => {
            handleUpdateTask({ isPrivate: !task.isPrivate });
          }}
          isChecked={task.isPrivate}
        />
        <Text fontSize="sm" fontWeight="bold">
          Set to private
        </Text>
        <Tooltip
          hasArrow
          label="Only permitted user(s) will be able to view this task."
        >
          <span>
            {' '}
            <AiOutlineInfoCircle color="green" />
          </span>
        </Tooltip>
      </Box>
      {task.isPrivate && (
        <FlexContent title="Permitted Members">
          <MultipleUserDropdown
            data={permittedUsers}
            onApply={(val) => {
              const userEmails = val.map((user) => user.email);
              handleUpdateTask({ permitted: userEmails });
            }}
          >
            <MenuButton
              as={Button}
              bg="gray.200"
              color="white"
              _hover={{ bg: 'blue.900' }}
            >
              {permittedUsers.length ? (
                <AvatarGroup color="blue.900" size="sm" max={4}>
                  {permittedUsers.map((item) => (
                    <Avatar
                      key={item?.id}
                      name={`${item?.firstName} ${item?.lastName}`}
                    />
                  ))}
                </AvatarGroup>
              ) : (
                'None'
              )}
            </MenuButton>
          </MultipleUserDropdown>
        </FlexContent>
      )}
    </Box>
  );
}
