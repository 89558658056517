import { useSelector } from 'src/store';

export const useAuth = () => {
  const auth = useSelector((state) => state.auth);

  const isAdmin = auth.user?.roles?.includes('admin') || false;
  const isSuperAdmin = auth.user?.roles?.includes('super_admin') || false;

  return { ...auth, isAdmin, isSuperAdmin };
};
