import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper';
import { filesAPIUrl } from 'src/constant';
import { FileType } from 'src/interfaces/task';
import { getFileSize } from 'src/utils/getFileSize';
import PDFViewer from '../PDFViewer';

// Import styles
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper-modal.css';

const renderView = (file: FileType) => {
  if (file.mimetype === 'application/pdf') {
    return <PDFViewer fileUrl={filesAPIUrl(file.path)} />;
  }
  if (file.mimetype?.startsWith('image/')) {
    return <Image src={filesAPIUrl(file.path)} mx="auto" />;
  }
  if (file.mimetype?.startsWith('audio/')) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" h="70%">
        <audio controls src={filesAPIUrl(file.path)}>
          <track kind="captions" />
        </audio>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="center" h="70%">
      <Text fontSize="xl" fontWeight="semibold" color="white">
        File cannot be previewed.
      </Text>
    </Box>
  );
};

type Props = {
  files: Array<FileType>;
  initialSlide?: number;
  isOpen: boolean;
  onClose(): void;
  onDelete(id: string): void;
  onDownload(id: string): void;
};

export const SwiperModal: FC<Props> = ({
  files,
  initialSlide,
  isOpen,
  onClose,
  onDelete,
  onDownload,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      colorScheme="whiteAlpha"
    >
      <ModalOverlay bg="blackAlpha.800" />
      <ModalContent background="transparent">
        <ModalCloseButton
          zIndex="docked"
          textColor="white"
          transform="scale(1.5)"
          _hover={{ transform: 'scale(2)' }}
        />
        <ModalBody p={0}>
          <Swiper
            autoHeight
            navigation
            keyboard={{ enabled: true }}
            modules={[Keyboard, Navigation]}
            initialSlide={initialSlide}
            className="mySwiper"
          >
            {files.map((file, index) => (
              <SwiperSlide key={index}>
                <Box height="100vh" overflow="auto">
                  {/* UNDERLAY BACKGROUND */}
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    width="full"
                    height="calc(100vh - 130px)"
                    bg="blackAlpha.700"
                  />
                  {/* FRAME */}
                  <Box px="70px" pt="100px" position="relative" h="full">
                    {renderView(file)}
                    <Box h={150} />
                  </Box>
                  {/* BANNER LAYER */}
                  <Box
                    width="full"
                    zIndex="banner"
                    position="absolute"
                    left="0"
                    bottom="0"
                    height="130px"
                    bg="blackAlpha.700"
                    textAlign="center"
                    p="4"
                  >
                    <Text
                      color="white"
                      fontSize="xl"
                      fontWeight="semibold"
                      mb="1"
                    >
                      {file.name}
                    </Text>
                    <Text color="white" mb="1">
                      File size: {getFileSize(file.size)}
                    </Text>

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap={4}
                    >
                      <Button
                        variant="link"
                        type="button"
                        onClick={() => onDownload(file.fileUUID)}
                      >
                        Download
                      </Button>
                      <Button
                        variant="link"
                        type="button"
                        onClick={() => onDelete(file.fileUUID)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
