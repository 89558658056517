import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { useAuth } from 'src/hooks/useAuth';

const AuthGuard = ({ component: Component }: { component: ReactElement }) => {
  const { isAuthenticated, isSuperAdmin, currentSignedRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // Redirect only if matched
  if (isSuperAdmin && currentSignedRole === 'super_admin') {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return Component;
};

export default AuthGuard;
