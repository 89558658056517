import { Box, Text, Heading, Button, Input, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import Card from 'src/components/Card';
import { useTestSMTPSettingsMutation } from 'src/hooks/data/admin/settings';
import { toastParams } from 'src/utils/toastParams';
import { SmtpForm } from './SmtpForm';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Smtp = () => {
  const toast = useToast();
  const [testEmail, setTestEmail] = useState('');

  const [mutateTestSmtp, { isLoading }] = useTestSMTPSettingsMutation();

  const handleTestEmail = () => {
    if (!emailRegex.test(testEmail)) {
      toast({
        title: 'Enter a valid email address',
        status: 'error',
        ...toastParams,
      });
      return;
    }

    mutateTestSmtp({ email: testEmail })
      .unwrap()
      .then(() => {
        setTestEmail('');
        toast({
          title: 'Test email sent successfully. Check your email.',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <Box py={6}>
      <Card maxW="700px" mx="auto">
        <Heading as="h3" size="md" textAlign="center" mb="10">
          SMTP Settings
        </Heading>
        <Box>
          <SmtpForm />
        </Box>
      </Card>
      <Card maxW="700px" mx="auto" mt="40px">
        <Heading as="h4" size="md" textAlign="center" mb="10">
          Test SMTP
        </Heading>

        <Text>Test Email</Text>
        <Box display={{ base: 'block', sm: 'flex' }} gap="2">
          <Input
            type="email"
            name="email"
            onChange={(e) => setTestEmail(e.target.value)}
            value={testEmail}
            mb={{ base: 2, sm: 0 }}
          />
          <Button
            type="button"
            onClick={handleTestEmail}
            isLoading={isLoading}
            spinner={<BeatLoader size={8} color="white" />}
            isDisabled={!testEmail}
          >
            Send test
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default Smtp;
