import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  useToast,
  Heading,
  useMediaQuery,
} from '@chakra-ui/react';
import { BsFilterLeft as BsFilterLeftIcon } from 'react-icons/bs';
import { toastParams } from 'src/utils/toastParams';
import { useFetchTaskQuery, useUpdateTaskMutation } from 'src/hooks/data/task';
import { useAuth } from 'src/hooks/useAuth';
import Header from './components/Header';
import Comments from './components/Comments';
import RightCol from './components/RightCol';
import Title from './components/Title';
import Description from './components/Description';
import History from './components/History';
import { Actions } from './components/Actions';
import Attachments from './components/Attachments';

const IssueDetails = () => {
  const { isAdmin } = useAuth();
  const [isIpadPro] = useMediaQuery('(min-width: 905px)');
  const slug = useParams().slug as string;
  const [updateTask] = useUpdateTaskMutation();
  const toast = useToast();
  const { data: task, isLoading } = useFetchTaskQuery({
    isAdmin,
    taskId: slug,
  });
  const [tab, setTab] = useState(0);

  const handleUpdateTask = (value: Record<string, string>) => {
    const payload = {
      ...value,
      taskId: slug,
    };

    updateTask({ taskId: slug, data: payload })
      .unwrap()
      .then(() => {
        toast({
          title: 'Updated Successfully',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!task) {
    return (
      <Box maxW="800px" mx="auto" textAlign="center">
        <Heading mb="1">No results found</Heading>
        <Text>
          This might happen if you&apos;re not permitted to view this page or a
          wrong issue number.
        </Text>
        <Text mt={1}>Kindly confirm and try again.</Text>
      </Box>
    );
  }

  return (
    <Box pb={6} maxW="1300px" mx="auto">
      <Header slug={slug} isPrivate={task.isPrivate} />
      <Box>
        <Box
          float={isIpadPro ? 'left' : 'none'}
          width={isIpadPro ? '60%' : '100%'}
          mt="5"
          mb="10"
        >
          <Actions priority={task.priority} taskId={task.id} />
          <Title
            title={task?.title as string}
            handleUpdateTask={handleUpdateTask}
          />
          <Description
            description={task?.description as string}
            handleUpdateTask={handleUpdateTask}
          />
          <Attachments files={task.files} taskId={task.id} />
        </Box>
        <Box
          float={isIpadPro ? 'right' : 'none'}
          width={isIpadPro ? '30%' : '100%'}
          minW="350px"
        >
          {task && <RightCol task={task} />}
        </Box>
        <Box style={{ clear: 'left' }} />
        <Box width={isIpadPro ? '60%' : '100%'} mt="4">
          <Flex fontWeight="semibold" mb="4" gap="1" align="center">
            Activity
            <BsFilterLeftIcon />
          </Flex>
          <Box mb="4">
            <Text display="inline-block" mr="5">
              Show:{' '}
            </Text>
            <Tabs
              variant="soft-rounded"
              display="inline-block"
              onChange={setTab}
              index={tab}
            >
              <TabList gap="2">
                <Tab background="gray.100">Comments</Tab>
                <Tab background="gray.100">History</Tab>
              </TabList>
            </Tabs>
          </Box>
          <Box>
            {tab === 0 ? <Comments slug={slug} /> : <History slug={slug} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IssueDetails;
