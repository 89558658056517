import {
  Box,
  Text,
  Button,
  Flex,
  useMediaQuery,
  Badge,
  Link,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IoNotificationsOutline } from 'react-icons/io5';
import CustomDrawer from 'src/components/CustomDrawer';
import { StackList } from 'src/components/StackList';
import {
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from 'src/hooks/data/auth';
import { toastParams } from 'src/utils/toastParams';
import { dateToTimeAgo } from 'src/utils/time-date-functions';

const Notification = (props: any) => {
  const [isMediaMobile] = useMediaQuery('(max-width: 500px)');
  const toast = useToast();
  const { data: notifications } = useGetNotificationsQuery();
  const [updateNotification, { isLoading: isDeleting, originalArgs }] =
    useUpdateNotificationMutation();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDelete = (id: number) => {
    updateNotification(id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Deleted successfully',
          status: 'success',
          ...toastParams,
        });
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <>
      <Box {...props}>
        <Button
          variant="link"
          color="primary"
          onClick={() => setOpenDrawer(true)}
        >
          <Flex alignItems="center" direction="column">
            <Box position="relative">
              <IoNotificationsOutline
                fontSize="20px"
                style={{ flexGrow: 1, height: '25px' }}
              />
              {notifications && notifications.length > 0 && (
                <Badge
                  colorScheme="twitter"
                  position="absolute"
                  top="0.5"
                  right={-1}
                  fontSize="xx-small"
                >
                  {notifications.length > 99 ? '99+' : notifications.length}
                </Badge>
              )}
            </Box>
            {!isMediaMobile && (
              <Text fontSize="xs" mt="1">
                Notification
              </Text>
            )}
          </Flex>
        </Button>
      </Box>
      {/* Notification Drawer */}
      <CustomDrawer
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
        placement="right"
        size="sm"
        title="Notification"
      >
        {notifications && notifications.length ? (
          <StackList
            data={[...notifications].reverse()}
            spacing={1}
            renderItem={(item, index) => (
              <Flex
                key={index}
                rounded="md"
                bgColor={item.viewed ? '' : 'blue.50'}
                _hover={{
                  backgroundColor: item.viewed ? 'gray.300' : 'blue.100',
                }}
                p="2"
                pointerEvents={
                  isDeleting && item.id === originalArgs ? 'none' : 'visible'
                }
                justify="space-between"
                gap={3}
              >
                <Link
                  as={RouterLink}
                  to={item.url}
                  flexGrow={1}
                  width="90%"
                  onClick={() => {
                    updateNotification(item.id);
                    setOpenDrawer(false);
                  }}
                >
                  <Text fontSize="sm" width="100%">
                    {item.description}
                  </Text>
                  <Text as="small" color="gray.500">
                    {dateToTimeAgo(item.createdAt)}
                  </Text>
                </Link>

                {isDeleting && item.id === originalArgs ? (
                  <Spinner size="xs" />
                ) : (
                  <Button size="xs" onClick={() => handleDelete(item.id)}>
                    X
                  </Button>
                )}
              </Flex>
            )}
          />
        ) : (
          <Text textAlign="center" p="2">
            No recent activity
          </Text>
        )}
      </CustomDrawer>
    </>
  );
};

export default Notification;
