import {
  List,
  ListItem,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { MdSpaceDashboard } from 'react-icons/md';
import { IoChevronDownOutline } from 'react-icons/io5';
import { VscCircuitBoard, VscIssueReopened } from 'react-icons/vsc';
import { generateKey } from 'src/utils/generators';
import { useDispatch } from 'src/store';
import { showMobileNav } from 'src/store/slices/auth';

interface itemsProps {
  link: string;
  icon: any;
  name: string;
}

interface itemChildProps extends itemsProps {
  children?: itemsProps[];
}

const items: itemChildProps[] = [
  {
    link: '/app/overview',
    icon: VscCircuitBoard,
    name: 'Overview',
  },
  {
    link: '/app/board',
    icon: MdSpaceDashboard,
    name: 'Board',
  },
  {
    link: '/app/issues',
    icon: VscIssueReopened,
    name: 'Issues',
  },
];

const Bars = ({ item }: { item: itemChildProps }) => {
  const dispatch = useDispatch();
  const { icon: Icon, name, link, children } = item;
  const location = useLocation();

  const isExactPath = (path: string) => matchPath(path, location.pathname);

  if (children) {
    const isActive = matchPath(
      {
        path: link,
        end: false,
      },
      location.pathname
    );

    return (
      <ListItem>
        <Menu autoSelect={false}>
          <MenuButton
            as={Button}
            rightIcon={<IoChevronDownOutline />}
            colorScheme={isActive ? 'pink' : 'teal'}
          >
            {name}
          </MenuButton>
          <MenuList>
            {children.map(
              ({ icon: ChildIcon, name: childName, link: childLink }) => (
                <MenuItem
                  icon={<ChildIcon />}
                  as={RouterLink}
                  to={childLink}
                  textDecoration="none"
                  key={generateKey(childName)}
                  bg={isExactPath(childLink) ? 'pink' : ''} // chakra doesn't have exact for paths.
                >
                  {childName}
                </MenuItem>
              )
            )}
          </MenuList>
        </Menu>
      </ListItem>
    );
  }
  return (
    <ListItem width="full">
      <Button
        as={RouterLink}
        to={link}
        textDecoration="none"
        colorScheme="teal"
        _hover={{ color: '#fff' }}
        _activeLink={{ bg: 'pink.600' }}
        width="full"
        onClick={() => dispatch(showMobileNav(false))}
      >
        <Icon />
        <Text ml="2">{name}</Text>
      </Button>
    </ListItem>
  );
};

const NavItems = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <List
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      gridGap="20px"
    >
      {items.map((item) => (
        <Bars item={item} key={item.name} />
      ))}
    </List>
  );
};

export default NavItems;
