import { useState } from 'react';
import {
  Box,
  Grid,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Portal,
  HStack,
  StackDivider,
  Button,
  PopoverFooter,
} from '@chakra-ui/react';
import { DateRangePicker, Range } from 'react-date-range';
import { addDays, format, subDays } from 'date-fns';
import PageTitle from 'src/components/PageTitle';
import { RiCalendar2Fill } from 'react-icons/ri';
import { BeatLoader } from 'react-spinners';
import { useFetchTaskStatsMeQuery } from 'src/hooks/data/task';
import TaskSummaryChart from './components/TaskSummaryChart';
import TaskLifeSpanChart from './components/TaskLifeSpanChart';
import TicketSummary from './components/TicketSummary';
import Overrall from './components/Overrall';

const formatDate = (date: Date) => format(date, 'dd-MM-yyyy');

const Overview = () => {
  const [datePickerRange, setDatePickerRange] = useState<Range[]>([
    {
      startDate: subDays(new Date(), 29),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [dateQuery, setDateQuery] = useState(datePickerRange[0]);
  // NB: Find a better way to implement the query
  const { isFetching, data = {} } = useFetchTaskStatsMeQuery({ ...dateQuery });

  return (
    <Box mb="8">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb={3}
      >
        <PageTitle text="Overview" />

        <Box>
          <Box display="flex" alignItems="center">
            <RiCalendar2Fill size={16} />
            <Text fontSize="sm" as="span">
              Select Dates:
            </Text>
          </Box>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <HStack
                display="flex"
                cursor="pointer"
                rounded="md"
                boxShadow="lg"
                bg="gray.500"
                p="1"
                px="2"
                color="white"
                divider={<StackDivider border="2px solid white" />}
              >
                <Text px="2">
                  {formatDate(datePickerRange[0].startDate as Date)}
                </Text>
                <Text px="2">
                  {formatDate(datePickerRange[0].endDate as Date)}
                </Text>
              </HStack>
            </PopoverTrigger>
            <Portal>
              <PopoverContent w="full" maxW="85vw" overflow="auto">
                <PopoverBody>
                  <DateRangePicker
                    onChange={(item) => setDatePickerRange([item.selection])}
                    maxDate={addDays(new Date(), 0)}
                    direction="vertical"
                    ranges={datePickerRange}
                  />
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    size="sm"
                    bg="blue.900"
                    color="white"
                    _hover={{ bg: 'blue.900' }}
                    onClick={() => {
                      setDateQuery(datePickerRange[0]);
                    }}
                    isLoading={isFetching}
                    spinner={<BeatLoader size={8} color="white" />}
                  >
                    Search date
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      </Box>
      <Overrall loading={isFetching} dataProps={data} />
      <Grid
        templateColumns={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
        }}
        gap={4}
        my={4}
      >
        <TaskSummaryChart loading={isFetching} dataProps={data} />
        <TaskLifeSpanChart loading={isFetching} dataProps={data} />
      </Grid>
      <TicketSummary />
    </Box>
  );
};

export default Overview;
