import React, { useMemo, useState } from 'react';
import {
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Input,
  Box,
  Text,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import { UserStrip } from 'src/interfaces/task';
import truncate from 'src/utils/truncate';
import { useFetchUsersQuery } from 'src/hooks/data/auth';

interface Props {
  data: UserStrip | null;
  enableClick?: boolean;
  hasError?: boolean;
  onChangeUser?(e: Record<string, unknown>): void;
  externalUsers?: UserStrip[];
  useExternalUsers?: boolean;
}

const UserDropdown: React.FC<Props> = ({
  data: selectedUser,
  enableClick,
  hasError,
  onChangeUser,
  externalUsers,
  useExternalUsers,
}) => {
  const [search, setSearch] = useState('');
  const { data = [], isLoading } = useFetchUsersQuery('activated');

  const filteredUsers = useMemo(() => {
    const dataUsers = useExternalUsers ? externalUsers || [] : data;
    return dataUsers.filter(
      (user) =>
        user.id !== selectedUser?.id &&
        (user.firstName.toLowerCase()?.includes(search.toLowerCase()) ||
          user.lastName.toLowerCase()?.includes(search.toLowerCase()))
    );
  }, [data, search, selectedUser, externalUsers, useExternalUsers]);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box ml={-2} maxW="100%" isTruncated>
              <Flex
                alignItems="center"
                whiteSpace="nowrap"
                _hover={{ bg: 'gray.200' }}
                p="1"
                maxW="100%"
                width="fit-content"
                cursor="pointer"
                borderRadius="md"
                border="2px solid"
                borderColor={hasError ? 'red.500' : 'transparent'}
                userSelect="none"
                pointerEvents={!enableClick ? 'none' : 'unset'}
                isTruncated
              >
                <Avatar
                  name={
                    selectedUser
                      ? `${selectedUser.firstName} ${selectedUser.lastName}`
                      : ''
                  }
                  size="xs"
                  mr="2"
                />{' '}
                {selectedUser
                  ? `${selectedUser?.firstName} ${selectedUser?.lastName}`
                  : 'Unassigned'}
              </Flex>
            </Box>
          </PopoverTrigger>
          <PopoverContent width="270px">
            <PopoverBody>
              <Box px="2" fontWeight="semibold" my={1}>
                <Text fontSize="xs" mb="1">
                  Select a member
                </Text>
                <Input
                  placeholder="search for member..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  size="sm"
                  autoFocus
                />
              </Box>
              {isLoading && !filteredUsers.length ? (
                <Box
                  my="4"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Spinner speed="1s" size="lg" color="blue.500" />
                </Box>
              ) : (
                <Box maxHeight="200px" overflow="auto">
                  {filteredUsers.map((user) => (
                    <Flex
                      key={user.id}
                      p="2"
                      cursor="pointer"
                      alignItems="start"
                      whiteSpace="nowrap"
                      fontSize="sm"
                      onClick={() => {
                        onChangeUser?.({
                          assignedTo: user.id,
                          assigned: user,
                        });
                        onClose();
                      }}
                      _hover={{ bg: 'gray.200' }}
                    >
                      <Avatar
                        name={`${user.firstName} ${user.lastName}`}
                        size="sm"
                        mr="2"
                      />
                      <Box>
                        <Text fontWeight="bold">
                          {truncate(`${user.firstName} ${user.lastName}`, 27)}
                        </Text>
                        <Text fontSize="xs" mb="2.5" textStyle="bold">
                          {truncate(user.email, 30)}
                        </Text>
                      </Box>
                    </Flex>
                  ))}
                </Box>
              )}
              {!filteredUsers.length && !isLoading && (
                <Box p="2" textAlign="center">
                  <Text fontSize="xs" mb="2.5">
                    No results
                  </Text>
                </Box>
              )}
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default UserDropdown;
