import { Button, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { BiLinkExternal } from 'react-icons/bi';
import { roleConstant } from './roleConstant'; // NB: This should be coming from BE

const Permissions = () => {
  return (
    <Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={4}>
      {roleConstant.map((role, idx) => (
        <GridItem
          key={idx}
          w="100%"
          maxW="400px"
          p={4}
          shadow="md"
          borderWidth="1px"
        >
          <Heading size="sm" mb={3}>
            {role.title}
          </Heading>
          <Text>{role.description}</Text>
          <Button variant="link" mt="2" rightIcon={<BiLinkExternal />}>
            View details
          </Button>
        </GridItem>
      ))}
    </Grid>
  );
};

export default Permissions;
