/* eslint-disable no-unused-vars */
import { UserObject } from './user';
import { Company } from './company';

export interface taskStatusProps {
  id: number;
  name: string;
  value: string;
}

export interface taskProps {
  id: string;
  title: string;
  status: string;
  description: string;
  user: UserObject;
}

export interface taskPayloadProps {
  taskId?: string;
  title?: string;
  status?: string;
  priority?: string;
  assignedTo?: number;
}

export enum Priority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  URGENT = 'urgent',
}

export enum PriorityColor {
  LOW = 'gray',
  MEDIUM = 'orange',
  HIGH = 'red',
  URGENT = 'red',
}

export enum Status {
  PENDING = 'pending',
  PROGRESS = 'progress',
  REVIEW = 'review',
  COMPLETED = 'completed',
}

export enum StatusColor {
  PENDING = 'gray',
  PROGRESS = 'blue',
  REVIEW = 'yellow',
  COMPLETED = 'green',
}

export type UserStrip = Omit<UserObject, 'roles' | 'status' | 'companyId'>;

export type FileType = {
  path: string;
  size: number;
  name: string;
  mimetype: string;
  fileUUID: string;
};

export interface Task {
  id: string;
  title: string;
  description: string | null;
  customerEmail: string | null;
  estimate: string | null;
  status: string;
  priority: string;
  files: Array<FileType>;
  user: UserStrip;
  assigned: UserStrip;
  companyId: number;
  company?: Company;
  isPrivate?: boolean;
  permitted?: Array<string>;
  createdAt?: string;
  updatedAt?: string;
}

export interface PaginatedTasks {
  count: number;
  rows: Task[];
  totalPages: number;
  currentPage: number;
}

export interface Log {
  id: number;
  message: string;
  user: UserStrip;
  createdAt: string;
  updatedAt: string;
}

export interface Comment extends Omit<Log, 'message'> {
  description: string;
}

export type NotificationT = {
  createdAt: string;
  description: string;
  id: number;
  updatedAt: string;
  url: string;
  viewed: boolean;
};
