import { PropsWithChildren } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';

type PropTypes = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  handleContinue: () => void;
  isSubmitting?: boolean;
  actionText: string;
};

export function ConfirmModal({
  title,
  isOpen,
  onClose,
  children,
  actionText,
  handleContinue,
  isSubmitting,
}: PropsWithChildren<PropTypes>) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm {title}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button
            width="full"
            colorScheme="red"
            onClick={handleContinue}
            isLoading={isSubmitting}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {actionText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
