import { Box, Divider, IconButton, Text } from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';

type Props = {
  isMediaLarge: boolean;
  title: string;
  openDrawer(): void;
};

export default function Header({ title, openDrawer, isMediaLarge }: Props) {
  return (
    <>
      <Box display="flex" gap={2} alignItems="center">
        {!isMediaLarge && (
          <IconButton
            aria-label="menu"
            variant="link"
            color="primary"
            onClick={openDrawer}
          >
            <GiHamburgerMenu />
          </IconButton>
        )}

        <Text textTransform="capitalize" fontSize="2xl" fontWeight="500">
          {title.split('/')[0]}
        </Text>
      </Box>
      <Divider />
    </>
  );
}
