import { List, ListItem, ListIcon } from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';
import { FiUser, FiUsers, FiLock } from 'react-icons/fi';
import { FaUniversalAccess, FaHornbill } from 'react-icons/fa';
import { CgOrganisation } from 'react-icons/cg';
import { IoNotificationsOutline } from 'react-icons/io5';
import { HiRectangleGroup } from 'react-icons/hi2';
import { useAuth } from 'src/hooks/useAuth';

import styles from './settings.module.css';

export default function RouteList() {
  const { isAdmin } = useAuth();

  const routerLinkClass = ({ isActive }: { isActive: boolean }) =>
    `${styles.routeLink} ${isActive ? styles.active : ''}`;

  return (
    <List>
      <ListItem position="relative" h="40px">
        <RouterLink to="/app/settings/profile" className={routerLinkClass}>
          <ListIcon as={FiUser} color="green.500" />
          Profile
        </RouterLink>
      </ListItem>
      {isAdmin && (
        <ListItem position="relative" h="40px">
          <RouterLink
            to="/app/settings/organization"
            className={routerLinkClass}
          >
            <ListIcon as={CgOrganisation} color="green.500" />
            Organization
          </RouterLink>
        </ListItem>
      )}
      <ListItem position="relative" h="40px">
        <RouterLink to="/app/settings/members" className={routerLinkClass}>
          <ListIcon as={FiUsers} color="green.500" />
          Members
        </RouterLink>
      </ListItem>
      <ListItem position="relative" h="40px">
        <RouterLink to="/app/settings/departments" className={routerLinkClass}>
          <ListIcon as={HiRectangleGroup} color="green.500" />
          Departments
        </RouterLink>
      </ListItem>
      <ListItem position="relative" h="40px">
        <RouterLink
          to="/app/settings/notifications"
          className={routerLinkClass}
        >
          <ListIcon as={IoNotificationsOutline} color="green.500" />
          Notifications
        </RouterLink>
      </ListItem>
      {isAdmin && (
        <ListItem position="relative" h="40px">
          <RouterLink
            to="/app/settings/permissions"
            className={routerLinkClass}
          >
            <ListIcon as={FaUniversalAccess} color="green.500" />
            Permissions
          </RouterLink>
        </ListItem>
      )}
      <ListItem position="relative" h="40px">
        <RouterLink to="/app/settings/security" className={routerLinkClass}>
          <ListIcon as={FiLock} color="green.500" />
          Security
        </RouterLink>
      </ListItem>
      {isAdmin && (
        <ListItem position="relative" h="40px">
          <RouterLink to="/app/settings/billing" className={routerLinkClass}>
            <ListIcon as={FaHornbill} color="green.500" />
            Billing
          </RouterLink>
        </ListItem>
      )}
    </List>
  );
}
