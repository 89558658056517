import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box, Skeleton, Text } from '@chakra-ui/react';
import Card from 'src/components/Card';
import CardTitle from 'src/components/CardTitle';

ChartJS.register(...registerables);

export default function TaskLifeSpanChart({
  loading,
  dataProps,
}: {
  dataProps: Record<string, number>;
  loading: boolean;
}) {
  const options: ChartOptions<'pie'> = {
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: { position: 'bottom' },
    },
  };

  const inCompleteTask = dataProps.total - dataProps.completed;
  const dataRecords = [inCompleteTask, dataProps.completed];
  const hasRecords = dataRecords.some((rec) => rec);
  const completedPercent = Math.floor(
    (dataProps.completed / dataProps.total) * 100
  );
  const inCompletedPercent = Math.floor(
    (inCompleteTask / dataProps.total) * 100
  );

  const data = {
    labels: ['Incomplete tasks', 'Completed tasks'],
    datasets: [
      {
        data: dataRecords,
        backgroundColor: ['rgba(102, 102, 102, 0.1)', '#62B44E'],
        borderColor: ['rgba(102, 102, 102, 0.1)', '#62B44E'],
        borderWidth: 1,
        hoverBorderWidth: 5,
      },
    ],
  };
  return (
    <Card>
      <CardTitle text="Time Estimates" />
      {loading ? (
        <Skeleton height="200px" width="100%" />
      ) : (
        <Box minH={200} height="80%">
          {hasRecords ? (
            <Box
              display={{ base: 'block', sm: 'flex' }}
              height="100%"
              alignItems="center"
            >
              <Box flex={2}>
                <Pie options={options} data={data} />
              </Box>
              <Box>
                <Box textAlign="center" mb="2">
                  <Text fontWeight="semibold">Completed tasks</Text>
                  <Text fontSize={22}>{completedPercent}%</Text>
                </Box>
                <Box textAlign="center" mb="2">
                  <Text fontWeight="semibold">Incomplete tasks</Text>
                  <Text fontSize={22}>{inCompletedPercent}%</Text>
                </Box>
                {/* <Box textAlign="center" mb="2">
                  <Text fontWeight="semibold">Total lifespan</Text>
                  <Text fontSize={22}>520:25 hr</Text>
                </Box>
                <Box textAlign="center">
                  <Text fontWeight="semibold">Average lifespan/task</Text>
                  <Text fontSize={22}>20 min</Text>
                </Box> */}
              </Box>
            </Box>
          ) : (
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="sm">No records during the selected dates</Text>
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
}
