/* eslint-disable no-empty */
import { User } from 'src/interfaces';
import { NotificationT } from 'src/interfaces/task';
import { setCredentials, setLoginUser } from 'src/store/slices/auth';
import { api } from 'src/store/services/baseApi';
import { AdminType } from './task';

type Login = {
  email: string;
  password: string;
};

type UpdateUserByAdmin = {
  firstName: string;
  lastName: string;
  roles: Array<string>;
  status: 'activated' | 'deactivated';
};

type Profile = {
  firstName?: string;
  lastName?: string;
  department?: string;
  phone?: string;
  jobTitle?: string;
  departments?: number[];
} & AdminType;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchMe: build.query<User.UserObject, void>({
      query: () => ({ url: 'user/me', method: 'GET' }),
      transformResponse: (response: any) => response?.user,
      providesTags: ['fetchMe'],
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLoginUser(data));
        } catch {
          dispatch(setCredentials(null));
        }
      },
    }),
    login: build.mutation<
      { user: User.UserObject; token: string; refreshToken: string },
      Login
    >({
      query: (body) => ({ url: 'auth/login', method: 'POST', data: body }),
    }),
    register: build.mutation<boolean, User.CreateUser>({
      query: (body) => ({ url: 'auth/register', method: 'POST', data: body }),
    }),
    updateProfile: build.mutation<User.UserObject, Profile & { id: number }>({
      query: ({ id, isAdmin, ...body }) => ({
        url: isAdmin ? `user/${id}/admin` : `user/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['fetchMe'],
    }),
    getNotifications: build.query<NotificationT[], void>({
      query: () => ({ url: 'notification', method: 'GET' }),
      providesTags: ['notification'],
    }),
    updateNotification: build.mutation<unknown, number>({
      query: (id) => ({ url: `notification/${id}`, method: 'PUT' }),
      invalidatesTags: ['notification'],
    }),
    sendVerifyEmail: build.mutation<void, string>({
      query: (email) => ({
        url: `user/email/verify`,
        method: 'POST',
        data: { email },
      }),
    }),
    fetchUsers: build.query<User.UserObject[], string | void>({
      query: (status) => ({
        url: `users?${status ? `status=${status}` : ''}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: 'fetchUsers', id }))
          : ['fetchUsers'],
    }),
    fetchUser: build.query<User.UserObject, string>({
      query: (id) => ({
        url: `user/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'fetchUsers', id }],
    }),
    addUser: build.mutation<User.UserObject, User.AddUser>({
      query: (body) => ({ url: 'user', method: 'POST', data: body }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            injectedRtkApi.util.updateQueryData(
              'fetchUsers',
              undefined,
              (oldData) => {
                oldData.push(data);
              }
            )
          );
        } catch {}
      },
    }),
    updateUserByAdmin: build.mutation<
      User.UserObject,
      { id: number; data: Partial<UpdateUserByAdmin> }
    >({
      query: ({ id, data }) => ({
        url: `user/${id}/admin`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // used optimistic update because of `IsFetching`. NB: Try to improve code to use invalidateTags
          dispatch(
            injectedRtkApi.util.updateQueryData(
              'fetchUser',
              String(id),
              (oldData) => ({ ...oldData, ...data })
            )
          );
          dispatch(
            injectedRtkApi.util.updateQueryData(
              'fetchUsers',
              undefined,
              (oldData) => {
                return oldData.map((user) =>
                  user.id === id ? { ...user, ...data } : user
                );
              }
            )
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useFetchMeQuery,
  useLoginMutation,
  useUpdateProfileMutation,
  useRegisterMutation,
  useAddUserMutation,
  useFetchUsersQuery,
  useFetchUserQuery,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useUpdateUserByAdminMutation,
  useSendVerifyEmailMutation,
} = injectedRtkApi;
