import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Box,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useLoginMutation } from 'src/hooks/data/auth';
import { setCredentials } from 'src/store/slices/auth';
import { useDispatch } from 'src/store';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const dispatch = useDispatch();
  const [mutateLogin, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <Box textAlign="center" mb="1rem">
        <Heading size="xl">Login</Heading>
      </Box>
      <Box>
        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup.string().required('Password is required'),
          })}
          onSubmit={async (values, { setStatus }) => {
            const { submit, ...rest } = values;
            try {
              const response = await mutateLogin(rest).unwrap();
              dispatch(setCredentials(response));
            } catch (error: any) {
              setStatus({
                type: 'error',
                message: error?.data?.message,
              });
            } finally {
              setTimeout(() => {
                setStatus();
              }, 7000);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            status,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormControl
                id="email"
                mb="1rem"
                isInvalid={Boolean(touched.email && errors.email)}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="password"
                mb="1rem"
                isInvalid={Boolean(touched.password && errors.password)}
              >
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      variant="ghost"
                      size="sm"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              <Text mb="0.5rem">
                <RouterLink to="/auth/forgot-password">
                  Forgot Password ?
                </RouterLink>
              </Text>
              {!isLoading && status?.type && (
                <Alert status="error" mb="1rem">
                  <AlertIcon />
                  <AlertDescription>{status?.message}</AlertDescription>
                </Alert>
              )}
              <Button
                isLoading={isLoading}
                colorScheme="teal"
                width="full"
                type="submit"
                spinner={<BeatLoader size={8} color="white" />}
              >
                Login
              </Button>
              <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                my="0.5rem"
              >
                <Box as="hr" width="30%" />
                <Text fontSize="sm" mx="1rem" color="grey">
                  or
                </Text>
                <Box as="hr" width="30%" />
              </Box>
              <Button
                width="full"
                as={RouterLink}
                to="/auth/register"
                bg="facebook.500"
                color="white"
                _hover={{ color: 'white', bg: 'facebook.700' }}
                textDecoration="none"
              >
                Register
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Login;
