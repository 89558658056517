import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalBody,
  Button,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as SuccessSvg } from 'src/assets/images/success_registration.svg';
import { useDispatch, useSelector } from 'src/store';
import { toggleSuccessModal } from 'src/store/slices/auth';

const SuccessRegisterModal = () => {
  const { isSuccessModal } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(toggleSuccessModal(false));
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isSuccessModal}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pb={6}>
          <SuccessSvg width="100%" height="100%" />
          <Text textAlign="center" fontSize="lg" fontWeight="bold" mt={4}>
            Thank you for registering!
          </Text>
          <Text textAlign="center" fontSize="lg" mt={4}>
            Please check your email for a verification link to complete the
            registration.
          </Text>
        </ModalBody>

        <Box display="flex" justifyContent="center" my="6">
          <Button
            as={RouterLink}
            to="/auth/login"
            onClick={handleClose}
            color="white"
            bg="facebook.500"
            _hover={{ color: 'white', bg: 'facebook.700' }}
            textDecoration="none"
          >
            Go to Login
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default SuccessRegisterModal;
