import { Box, Button, Image, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { filesAPIUrl } from 'src/constant';
import { FileType } from 'src/interfaces/task';
import { getFileSize } from 'src/utils/getFileSize';

type Props = {
  file: File | FileType;
  onDelete?: () => void;
  onDownload?: () => void;
  clickFilePreview?: () => void;
};

const hasFileUUID = (props: File | FileType): props is FileType =>
  'fileUUID' in props;

const FilePreview = ({
  file,
  onDelete,
  onDownload,
  clickFilePreview,
}: Props) => {
  const [previewFile, setPreviewFile] = useState<any>();

  const isImageFile = useMemo(
    () =>
      hasFileUUID(file)
        ? file.mimetype?.startsWith('image/')
        : file.type.startsWith('image/'),
    [file]
  );

  useEffect(() => {
    if (!hasFileUUID(file)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        setPreviewFile(readerEvent.target?.result);
      };
    } else {
      setPreviewFile(filesAPIUrl(file.path));
    }
  }, [file]);

  return (
    <Box
      display="flex"
      gap="2"
      p="2"
      ml={5}
      _hover={{ bg: hasFileUUID(file) ? 'gray.200' : 'transparent' }}
      onClick={clickFilePreview}
    >
      <Box
        width="100px"
        height="70px"
        overflow="hidden"
        bg="gray.300"
        borderRadius="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {isImageFile && previewFile ? (
          <Image src={previewFile} objectFit="cover" height="full" />
        ) : (
          <Text textTransform="uppercase" fontSize="2xl" fontWeight="semibold">
            {file.name.split('.').pop()}
          </Text>
        )}
      </Box>
      <Box>
        <Text fontSize="sm" fontWeight="semibold">
          {file.name}
        </Text>
        <Text fontSize="xs">File size: {getFileSize(file.size)}</Text>

        <Box display="flex" alignItems="center" gap={2}>
          {hasFileUUID(file) && (
            <Button
              variant="link"
              fontSize="sm"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                onDownload?.();
              }}
            >
              Download
            </Button>
          )}
          <Button
            variant="link"
            fontSize="sm"
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              onDelete?.();
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilePreview;
