import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Box,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import { useDispatch } from 'src/store';
import { setCredentials } from 'src/store/slices/auth';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from 'src/constant';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';

const ConfirmAccount = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const userToken = String(searchParams.get('token'));

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  return (
    <>
      <Heading size="xl" mb="1rem" textAlign="center">
        Update Account
      </Heading>
      <Text textAlign="center" fontStyle="italic">
        Please, complete your account information by adding your password.
      </Text>

      <Box my="10">
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(6, 'Password must be less than 6 characters.')
              .required('Password is required'),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required('Confirm password is required'),
          })}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            const { password } = values;

            try {
              const response = await axios.post(
                `${SERVER_URL}auth/verify-user`,
                { password, token: userToken },
                {
                  headers: {
                    Authorization: `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                  },
                }
              );
              const { user, token, refreshToken } = response.data;
              dispatch(setCredentials({ user, token, refreshToken }));

              resetForm();
            } catch (error: any) {
              setErrors({
                submit: error?.response?.data?.message,
              });
            } finally {
              setSubmitting(false);
              setTimeout(() => {
                setErrors({});
              }, 10000);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormControl
                id="password"
                mb="1rem"
                isInvalid={Boolean(touched.password && errors.password)}
              >
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showPassword.password ? 'text' : 'password'}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          password: !prev.password,
                        }))
                      }
                    >
                      {showPassword.password ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="confirmPassword"
                mb="1rem"
                isInvalid={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
              >
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                  <Input
                    pr="4.5rem"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      variant="ghost"
                      size="sm"
                      onClick={() =>
                        setShowPassword((prev) => ({
                          ...prev,
                          confirmPassword: !prev.confirmPassword,
                        }))
                      }
                    >
                      {showPassword.confirmPassword ? (
                        <FaEye />
                      ) : (
                        <FaEyeSlash />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
              </FormControl>
              {errors.submit && (
                <Alert status="error" mb="1rem">
                  <AlertIcon />
                  <AlertDescription>{errors.submit}</AlertDescription>
                </Alert>
              )}
              <Button
                isLoading={isSubmitting}
                colorScheme="facebook"
                width="full"
                type="submit"
                spinner={<BeatLoader size={8} color="white" />}
              >
                Update your account
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ConfirmAccount;
