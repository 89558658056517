import { Box, Divider, List, ListItem, Text } from '@chakra-ui/react';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { IoSettingsOutline, IoWalletOutline } from 'react-icons/io5';
import { CgOrganisation } from 'react-icons/cg';
import { NavLink as RouterLink, useMatch } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { GrResources } from 'react-icons/gr';
import { FaHornbill } from 'react-icons/fa';

export const navItems = [
  {
    label: 'Dashboard',
    icon: MdOutlineDashboardCustomize,
    link: '/admin/dashboard',
  },
  {
    label: 'Company',
    icon: CgOrganisation,
    link: '/admin/company',
  },
  {
    label: 'Finance',
    icon: IoWalletOutline,
    link: '/admin/finance',
  },
  {
    label: 'Subscription',
    icon: FaHornbill,
    link: '/admin/subscription',
  },
  {
    label: 'Settings',
    icon: IoSettingsOutline,
    link: '/admin/settings',
  },
  {
    label: 'Resources',
    icon: GrResources,
    link: '/admin/resources',
  },
];

export const Sidebar = ({ isOpen }: { isOpen: boolean }) => {
  const isExactPath = (path: string) =>
    useMatch({
      path,
      end: false,
    });

  return (
    <Box
      as="aside"
      position={{ base: 'fixed', lg: 'relative' }}
      width={{ base: 'auto', lg: '250px' }}
      display={isOpen ? 'block' : 'none'}
      zIndex={9999}
      top={0}
      left={0}
    >
      <Box
        as="nav"
        h="full"
        w={250}
        bg="gray.100"
        position="fixed"
        left={0}
        top={0}
        display="flex"
        flexDir="column"
        alignItems="center"
        shadow="sm"
        pt={3}
      >
        <Logo width="50px" height="70px" />
        <Box flex={1} w="full">
          <Divider my={2} />
          <Box py={3}>
            <List mx="0">
              {navItems.map(({ icon: Icon, label, link }, index: number) => (
                <ListItem
                  key={index}
                  _hover={{ bg: 'gray.200' }}
                  bg={isExactPath(link) ? 'gray.200' : ''}
                  mb={1}
                >
                  <Box
                    as={RouterLink}
                    to={link}
                    textDecoration="none"
                    display="flex"
                    alignItems="center"
                    _hover={{ color: 'inherit' }}
                    gap={2}
                    py={4}
                    px={5}
                  >
                    <Icon size={20} />
                    <Text
                      fontSize={16}
                      fontWeight={isExactPath(link) ? 'semibold' : 'medium'}
                    >
                      {label}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
