import { FC } from 'react';
import { Box, Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Topbar from './Topbar';

const DashboardLayout: FC = () => {
  return (
    <Box position="relative">
      <Topbar />
      <Box as="main">
        <Container maxW="container.wide" py={6}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
