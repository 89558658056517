import { Draggable } from 'react-beautiful-dnd';
import { Box, Text } from '@chakra-ui/react';
import PerfectScrollbar from 'react-perfect-scrollbar';
// Constant
import { Task } from 'src/interfaces';
// Components
import TaskIcon from 'src/components/TaskIcon';
import TaskCard from './TaskCard';

type Props = {
  column: Task.taskStatusProps;
  dragRef: any;
  isDraggingOver: any;
  placeholder: any;
  allTasks: Task.Task[];
};
const TaskColumn = ({
  column,
  dragRef,
  allTasks,
  isDraggingOver,
  placeholder,
  ...rest
}: Props) => {
  const totalTasks = (status: string) => {
    return allTasks.reduce(
      (acc, task) => (task.status === status ? acc + 1 : acc + 0),
      0
    );
  };

  return (
    <Box
      minW={{ base: '250px', md: '300px' }}
      width="100%"
      ref={dragRef}
      {...rest}
      {...dragRef.droppableProps}
      rounded="md"
      height="calc(100vh - 18vh)"
      mb={2}
      bg={isDraggingOver ? 'gray.300' : 'gray.100'}
      position="relative"
      pb={2}
    >
      <Box display="flex" alignItems="center" bg="gray.100" p="2">
        <TaskIcon status={column.value} />
        <Text fontWeight="semibold" ml="4px" flexGrow={1}>
          {column.name}{' '}
        </Text>
        {totalTasks(column.value) ? (
          <Box
            as="span"
            fontSize="12px"
            px="2"
            bg="gray.600"
            color="white"
            rounded="full"
          >
            {totalTasks(column.value)}
          </Box>
        ) : undefined}
      </Box>
      <PerfectScrollbar
        style={{
          height: 'calc(100% - 40px)',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        {allTasks.map(
          (task, index) =>
            column.value === task.status && (
              <Draggable
                draggableId={task.id}
                index={index}
                key={task.id}
                isDragDisabled={task.status === Task.Status.COMPLETED}
              >
                {(provided) => (
                  <TaskCard
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    task={task}
                    isCompleted={task.status === Task.Status.COMPLETED}
                    key={task.id}
                    ref={provided.innerRef}
                  />
                )}
              </Draggable>
            )
        )}
      </PerfectScrollbar>
      {placeholder}
    </Box>
  );
};

export default TaskColumn;
