import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  IconButton,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';

type CustomDrawerProps = {
  isOpen: boolean;
  onClose(): void;
  placement?: 'left' | 'right';
  size?: string;
  title?: string;
};

export default function CustomDrawer({
  onClose,
  isOpen,
  placement = 'left',
  size = 'xs',
  title,
  children,
}: PropsWithChildren<CustomDrawerProps>) {
  return (
    <Drawer placement={placement} size={size} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay height="calc(100vh - 70px)" mt="70px" />
      <DrawerContent height="calc(100vh - 71px)" mt="71px">
        <DrawerHeader borderBottomWidth="1px">
          <IconButton aria-label="go back" onClick={onClose}>
            <MdKeyboardBackspace />
          </IconButton>{' '}
          {title}
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
