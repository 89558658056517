import {
  formatDistanceToNow,
  format,
  isSameYear,
  isSameDay,
  differenceInDays,
} from 'date-fns';

/**
 * @param date '2023-02-10T14:16:22.864Z'
 * @returns Feb 10, 2023
 */
export const dateFormatter = (date: string): string => {
  const parsedDate = new Date(date);
  return format(parsedDate, 'MMM d, yyyy');
};

/**
 * @param date '2023-02-10T14:16:22.864Z'
 * @returns 02:16 PM
 */
export const getTime = (date: string | Date) =>
  new Date(date).toLocaleTimeString('en', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
  });

/**
 * @param dateStr 10/10/2023
 * @returns Tuesday
 */
export function getDayName(dateStr: string): string {
  return new Date(dateStr).toLocaleDateString('en', { weekday: 'long' });
}

/**
 * @param val 2 days ago
 * @param date 10/10/2023
 * @returns 2 days ago, 2:45 PM
 */
export function includeTime(val: string, date: string | Date) {
  return `${val}, ${getTime(date)}`;
}

export function dateToTimeAgo(date: string): string {
  const currentDate = new Date();
  const parsedDate = new Date(date);

  const daysDifference = differenceInDays(currentDate, parsedDate);

  // For different year
  if (!isSameYear(currentDate, parsedDate) || daysDifference > 14) {
    return includeTime(format(parsedDate, 'MMM d, yyyy'), parsedDate);
  }

  // For same year
  const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

  if (!isSameDay(currentDate, parsedDate)) {
    return includeTime(timeAgo, parsedDate);
  }
  return timeAgo;
}
