import { Badge, Box } from '@chakra-ui/react';
import Permissions from 'src/components/permissions/Permissions';

const PermissionList = () => {
  return (
    <>
      <Badge my={3}>2 Roles</Badge>
      <Box
        borderTop="1px solid"
        borderBottom="1px solid"
        p={{ base: 0, sm: '6' }}
        borderColor="gray.200"
      >
        <Permissions />
      </Box>
    </>
  );
};

export default PermissionList;
