import { ReactElement } from 'react';
import { Navigate } from 'react-router';
import { useAuth } from 'src/hooks/useAuth';

const SuperAdmin = ({ component: Component }: { component: ReactElement }) => {
  const { isSuperAdmin, currentSignedRole } = useAuth();

  if (!isSuperAdmin || currentSignedRole !== 'super_admin') {
    // sending to default route with either take user to auth or guest page
    return <Navigate to="/" replace />;
  }

  return Component;
};

export default SuperAdmin;
