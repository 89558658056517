import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { ENABLE_REDUX_DEV_TOOLS } from './enableRedux';
import { reducer as authReducer } from './slices/auth';
import { api } from './services/baseApi';

const rootReducer = combineReducers({
  auth: authReducer,
  [api.reducerPath]: api.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk, api.middleware),
  devTools: ENABLE_REDUX_DEV_TOOLS(),
});

export type RootState = ReturnType<typeof rootReducer>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export type Dispatch = typeof store.dispatch;
export const useDispatch = () => useReduxDispatch<Dispatch>(); // Export a hook that can be reused to resolve types

export default store;
