import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  Button,
  Text,
  Input,
  Box,
} from '@chakra-ui/react';
import { add, isValid } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { Calendar } from 'react-date-range';
import { FiChevronDown } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';
import { dateFormatter, includeTime } from 'src/utils/time-date-functions';
import EstimateCheckerBadge from '../EstimateCheckerBadge';

import styles from './style.css';

function getFormattedTime(date?: Date) {
  const now = (date ?? new Date()).toISOString();
  const time = now.split('T')[1];
  return time.slice(0, 5); // hh:mm
}

const DateTimeDropdown = ({
  isLoading,
  onClickApply,
  estimatedDate,
  isOverdue,
}: {
  isLoading?: boolean;
  onClickApply?(e: string | null): void;
  estimatedDate?: string | null;
  isOverdue?: boolean;
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [inputDate, setInputDate] = useState(currentDate?.toLocaleDateString()); // formatted date
  const [currentTime, setCurrentTime] = useState(getFormattedTime());
  const [inputTime, setInputTime] = useState(currentTime);

  const results = useMemo(() => {
    const [hours, minutes] = currentTime.split(':');
    return add(currentDate, {
      hours: Number(hours),
      minutes: Number(minutes),
    }).toISOString();
  }, [currentDate, currentTime]);

  useEffect(() => {
    if (estimatedDate) {
      const date = new Date(estimatedDate);
      setCurrentDate(date);
      setInputDate(date.toLocaleDateString());

      const time = getFormattedTime(date);
      setCurrentTime(time);
      setInputTime(time);
    }
  }, []);

  const validateOnBlur = (evt: any) => {
    const est = evt.target.value;
    const parts = est.split('/');
    // Rearrange the parts to create a new date string in 'MM/DD/YYYY' format
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;

    if (!isValid(new Date(formattedDate))) {
      setInputDate(currentDate.toLocaleDateString());
    } else {
      const parsedDate = new Date(formattedDate);
      setCurrentDate(parsedDate);
    }
  };

  const validateTimeOnBlur = (evt: any) => {
    const est = evt.target.value;

    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    if (timeRegex.test(est)) {
      setCurrentTime(est.slice(0, 5));
    } else {
      setInputTime(currentTime);
    }
  };

  return (
    <Popover placement="bottom-end">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              rounded="md"
              p="1"
              px="2"
              border="1px"
              width="fit-content"
              textTransform="capitalize"
              borderRadius="md"
              _hover={{ bg: 'gray.200' }}
            >
              <Text px="2" mr={3}>
                {!estimatedDate ? (
                  'Choose date'
                ) : (
                  <>
                    {includeTime(dateFormatter(estimatedDate), estimatedDate)}{' '}
                    <EstimateCheckerBadge
                      isOverdue={isOverdue}
                      estimatedDate={estimatedDate}
                    />
                  </>
                )}
              </Text>
              <FiChevronDown />
            </Box>
          </PopoverTrigger>
          <PopoverContent w="full" maxW="355px" overflow="auto">
            <PopoverBody>
              <Text textAlign="center" fontWeight="bold">
                Choose Date & Time
              </Text>
              <Calendar
                onChange={(item) => {
                  setCurrentDate(item);
                  setInputDate(item?.toLocaleDateString());
                }}
                date={currentDate}
                className={styles.rdrDateDisplayWrapper}
              />
              <Box my={2} display="flex" gap={3}>
                <Input
                  name="date"
                  placeholder="DD/MM/YYYY"
                  value={inputDate}
                  onChange={(e) => setInputDate(e.target.value)}
                  onBlur={validateOnBlur}
                />
                <Input
                  name="time"
                  placeholder="HH:MM"
                  value={inputTime}
                  onChange={(e) => setInputTime(e.target.value)}
                  onBlur={validateTimeOnBlur}
                />
              </Box>
            </PopoverBody>
            <PopoverFooter>
              <Button
                size="sm"
                bg="blue.900"
                color="white"
                _hover={{ bg: 'blue.900' }}
                onClick={() => {
                  onClickApply?.(results);
                  onClose();
                }}
                isLoading={isLoading}
                spinner={<BeatLoader size={8} color="white" />}
                width="full"
                mb={2}
              >
                Apply
              </Button>
              {estimatedDate && (
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    onClickApply?.('');
                    onClose();
                  }}
                  isLoading={isLoading}
                  spinner={<BeatLoader size={8} color="white" />}
                  width="full"
                >
                  Remove
                </Button>
              )}
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default DateTimeDropdown;
