import { formatDistanceStrict, isAfter } from 'date-fns';
import { Status, Task } from 'src/interfaces/task';

export const lifeSpanCalculator = (task: Task) => {
  const completedDate =
    task.status === Status.COMPLETED
      ? String(task.updatedAt)
      : new Date().toString();

  const createdAtDate = new Date(task.createdAt as string);
  const completedAtDate = new Date(completedDate);

  const lifeSpan = formatDistanceStrict(createdAtDate, completedAtDate);

  const overdue = task.estimate
    ? isAfter(completedAtDate, new Date(task.estimate))
    : false;

  return { lifeSpan, overdue };
};
