import { useEffect, useState } from 'react';
import { Image } from '@chakra-ui/react';

export function PreviewFile({
  file,
  uploadUrl,
}: {
  file: File | undefined;
  uploadUrl?: string;
}) {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (readerEvent) => {
        const result = readerEvent.target?.result as string;
        setPreview(result);
      };
    } else {
      setPreview(undefined);
    }
  }, [file]);

  return (
    <Image src={preview || uploadUrl} fallbackSrc="https://placehold.co/80" />
  );
}
