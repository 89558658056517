import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { useAuth } from 'src/hooks/useAuth';

const GuestGuard = ({ component: Component }: { component: ReactElement }) => {
  const { isAuthenticated, isMultiRoleRedirect } = useAuth();

  if (isMultiRoleRedirect) {
    return <Navigate to="/auth/confirm-role" replace />;
  }

  if (isAuthenticated) {
    return <Navigate to="/app" replace />;
  }

  return Component;
};

export default GuestGuard;
