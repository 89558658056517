/* eslint-disable @typescript-eslint/no-use-before-define */

import truncate from 'src/utils/truncate';

type UserT = {
  id: string;
  name: string;
  link: string;
};

export const useConfig = (users: UserT[]) => ({
  placeholder: '',
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  },
  mention: {
    dropdownLimit: 6,
    feeds: [
      {
        marker: '@',
        feed: (queryText: any) => getFeedItems(queryText, users),
        itemRenderer: customItemRenderer,
      },
    ],
  },
});

function getFeedItems(queryText: any, users: UserT[]) {
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  return new Promise((resolve) => {
    setTimeout(() => {
      const itemsToDisplay = users
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching)
        // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
        .slice(0, 10);

      resolve(itemsToDisplay);
    }, 100);
  });

  // Filtering function - it uses `name` and `username` properties of an item to find a match.
  function isItemMatching(item: UserT) {
    // Make the search case-insensitive.
    const searchString = queryText.toLowerCase();

    // Include an item in the search results if name or username includes the current user input.
    return (
      item.name.toLowerCase()?.includes(searchString) ||
      item.id.toLowerCase()?.includes(searchString)
    );
  }
}

function customItemRenderer(item: any) {
  const itemElement = document.createElement('div');

  const usernameElement = document.createElement('span');
  usernameElement.classList.add('custom-item');
  usernameElement.id = `mention-list-item-id-${item.id}`;

  usernameElement.textContent = truncate(item.name, 45);

  const userEmailElement = document.createElement('span');
  userEmailElement.classList.add('custom-item-username');
  userEmailElement.textContent = truncate(item.id, 45);

  itemElement.appendChild(usernameElement);
  itemElement.appendChild(userEmailElement);

  return itemElement;
}
