import { useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { UserObject } from 'src/interfaces/user';

import { useConfig } from './config';
import './ck-editor.css';

type Props = {
  id?: string;
  placeholder?: string;
  shouldFocus?: boolean;
  contents: string;
  setContents: (content: string) => void;
  onFocus?(event: any, editor: any): void;
  onBlur?(event: any, editor: any): void;
  users?: UserObject[];
};

const Editor = (props: Props) => {
  const {
    placeholder = '',
    shouldFocus,
    contents,
    setContents,
    id,
    onBlur,
    onFocus,
    users = [],
  } = props;

  const items = useMemo(() => {
    return users.map((user) => ({
      id: `@${user.email}`,
      name: `${user.firstName} ${user.lastName}`,
      link: `/app/settings/members/${user.id}`,
    }));
  }, [users]);

  const config = useConfig(items);

  config.placeholder = placeholder;

  const onChangeContents = (_event: any, editor: any) => {
    const data = editor.getData();
    setContents(data);
  };

  return (
    <CKEditor
      id={id}
      editor={ClassicEditor}
      data={contents}
      config={config}
      onReady={(editor: any) => {
        if (shouldFocus) editor.focus();
      }}
      onChange={onChangeContents}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default Editor;
