import { forwardRef } from 'react';
import { BiLockAlt } from 'react-icons/bi';
import { Avatar, Box, Link, Text } from '@chakra-ui/react';
import { FiCalendar as CalendarIcon } from 'react-icons/fi';
import { MdAttachment } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';
import { Task as TaskProps } from 'src/interfaces';
import PriorityBadge from 'src/components/priority/PriorityBadge';
import EstimateCheckerBadge from 'src/components/EstimateCheckerBadge';
import truncate from 'src/utils/truncate';
import { lifeSpanCalculator } from 'src/utils/lifeSpanCalculator';
import { dateToTimeAgo } from 'src/utils/time-date-functions';

type componentProps = {
  task: TaskProps.Task;
  isCompleted: boolean;
};

const TaskCard = forwardRef<HTMLDivElement, componentProps>(
  ({ task, isCompleted, ...rest }, ref) => {
    const { firstName, lastName } = task.assigned;

    return (
      <Box
        ref={ref}
        mt="8px"
        borderRadius="5px"
        borderWidth={1}
        bg="white"
        width="100%"
        minHeight="100px"
        {...rest}
        cursor="pointer"
        borderColor={isCompleted ? 'green.200' : ''}
      >
        <Link
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
          as={RouterLink}
          to={`/app/issues/${task.id}`}
          display="flex"
          flexDirection="column"
          height="full"
        >
          <Box p="10px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                {task.isPrivate && <BiLockAlt />}{' '}
                <Text fontSize="12px">
                  {task.id}{' '}
                  <EstimateCheckerBadge
                    isOverdue={lifeSpanCalculator(task).overdue}
                    estimatedDate={task.estimate}
                  />
                </Text>
              </Box>
              <PriorityBadge priority={task.priority} />
            </Box>
            <Box className="card-text" my="10px" fontSize="14px" flexGrow={1}>
              {truncate(task.title, 70)}
            </Box>
          </Box>
          <Box
            p="10px"
            borderTop="1px solid"
            borderColor="gray.100"
            fontSize="12px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <CalendarIcon />{' '}
              <Text ml={2}>{dateToTimeAgo(String(task.createdAt))}</Text>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              {task.files.length ? (
                <Text display="inline-flex" alignItems="center">
                  <MdAttachment /> {task.files.length}
                </Text>
              ) : undefined}
              <Avatar size="xs" name={`${firstName} ${lastName}`} mr={1} />
            </Box>
          </Box>
        </Link>
      </Box>
    );
  }
);

export default TaskCard;
