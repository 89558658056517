import { RouteObject } from 'react-router-dom';
import ConfirmAccount from './ConfirmAccount';
import ConfirmSignedRole from './ConfirmSignedRole';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Register from './Register';

export const authRoutes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'confirm',
    element: <ConfirmAccount />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'confirm-role',
    element: <ConfirmSignedRole />,
  },
];
