import {
  Button,
  Flex,
  Input,
  Text,
  VStack,
  StackDivider,
  Box,
  InputGroup,
  InputLeftElement,
  Stack,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { BsSearch } from 'react-icons/bs';
import CustomDrawer from 'src/components/CustomDrawer';
import Permissions from 'src/components/permissions/Permissions';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import UserCard from './components/UserCard';
import NewMemberForm from './components/NewMemberForm';
import MemberDetails from './MemberDetails';

const Members = () => {
  const { users, isLoading } = useFetchUsersQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      users: data ?? [],
    }),
  });
  const { isAdmin } = useAuth();
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const filteredUsers = users.filter(
    (user) =>
      user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <>
      <Flex wrap="wrap" gap={2} justifyContent="space-between" py={2} w="full">
        {isAdmin && (
          <Button
            bg="blue.900"
            color="white"
            _hover={{ bg: 'blue.900' }}
            onClick={() => setIsOpen(true)}
          >
            Add member
          </Button>
        )}

        <InputGroup maxWidth="350px">
          <InputLeftElement pointerEvents="none">
            <BsSearch />
          </InputLeftElement>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filter by name"
          />
        </InputGroup>
      </Flex>
      <VStack
        mt="4"
        borderTop="1px"
        borderBottom="1px"
        divider={<StackDivider borderColor="blue.800" />}
        spacing={3}
        py={3}
        align="stretch"
      >
        {isLoading && !users?.length ? (
          <Text>Loading...</Text>
        ) : (
          <Box
            display="grid"
            gap={5}
            gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
          >
            {filteredUsers.map((user) => (
              <UserCard key={user.id} {...user} />
            ))}
          </Box>
        )}
      </VStack>

      {/* Member details */}
      <MemberDetails />
      {/* Add member */}
      <CustomDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement="right"
        size="xl"
        title="New Member"
      >
        <Stack
          divider={<StackDivider borderColor="gray.200" />}
          direction={{ base: 'column', md: 'row' }}
          gap={4}
          mt={-2}
        >
          <Box w="100%" pt={3}>
            <NewMemberForm />
          </Box>
          <Box w="100%" pt={3}>
            <Text fontSize="lg" fontWeight="bold" textAlign="center">
              Roles
            </Text>
            <Divider my={2} borderColor="gray.200" />
            <Permissions />
          </Box>
        </Stack>
      </CustomDrawer>
    </>
  );
};

export default Members;
