import { Menu, MenuButton, MenuItem, MenuList, Box } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import {
  RiSignalWifi1Fill as LowPriority,
  RiSignalWifi2Fill as MediumPriority,
  RiSignalWifiFill as HighPriority,
  RiAlarmWarningFill as UrgentPriority,
} from 'react-icons/ri';

import { Priority, PriorityColor } from 'src/interfaces/task';

interface RenderOptions {
  value: Priority;
  color: PriorityColor;
  icon: FunctionComponent<any>;
}
const options: RenderOptions[] = [
  {
    value: Priority.LOW,
    color: PriorityColor.LOW,
    icon: LowPriority,
  },
  {
    value: Priority.MEDIUM,
    color: PriorityColor.MEDIUM,
    icon: MediumPriority,
  },
  {
    value: Priority.HIGH,
    color: PriorityColor.HIGH,
    icon: HighPriority,
  },
  {
    value: Priority.URGENT,
    color: PriorityColor.URGENT,
    icon: UrgentPriority,
  },
];

interface PriorityDropdownProps {
  priority: string;
  onChange(e: Record<'priority', string>): void;
}

const PriorityDropdown = ({
  priority,
  onChange,
  ...rest
}: PriorityDropdownProps) => {
  const label = options.find((x) => x.value === priority) as RenderOptions;

  return (
    <Menu>
      <MenuButton
        cursor="pointer"
        {...rest}
        px={2}
        pr={4}
        border="1px"
        textTransform="capitalize"
        borderRadius="md"
        type="button"
        position="relative"
        _hover={{ bg: 'gray.200' }}
      >
        <Box display="flex" alignItems="center" mr="4">
          <Box color={label?.color} display="inline-block" mr="2">
            <label.icon />
          </Box>
          {label?.value}
        </Box>
        <Box as="span" position="absolute" right="1" top="1">
          <FiChevronDown />
        </Box>
      </MenuButton>
      <MenuList minW="150px">
        {options
          .filter((opt) => opt.value !== priority)
          .map(({ icon: Icon, value, color }) => (
            <MenuItem
              key={value}
              textTransform="capitalize"
              onClick={() => onChange({ priority: value })}
            >
              <Box color={color} display="inline-block" mr="2">
                <Icon />
              </Box>{' '}
              {value}
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
};

export default PriorityDropdown;
