import {
  MenuList,
  MenuDivider,
  Box,
  Select,
  Input,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { SyntheticEvent, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { Priority, Status } from 'src/interfaces/task';

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

type FilterProps = {
  useFetchTasks: (e?: Record<string, string>) => void;
  isLoading?: boolean;
};

const Filters = ({ useFetchTasks, isLoading }: FilterProps) => {
  const [state, setState] = useState({
    id: '',
    title: '',
    status: '',
    priority: '',
    startDate: '',
    endDate: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e: any) => {
    setState((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    const data = { ...state };

    if (
      data.endDate &&
      data.startDate &&
      new Date(data.endDate) < new Date(data.startDate)
    ) {
      setMessage('Invalid date filter');
      return;
    }

    if (data.startDate && !data.endDate) {
      data.endDate = formatDate(new Date());
      setState(data);
    }

    useFetchTasks({
      ...data,
      ...(data.startDate && {
        startDate: new Date(data.startDate).toISOString(),
      }),
      ...(data.endDate && { endDate: new Date(data.endDate).toISOString() }),
    });
  };

  const resetFilter = () => {
    setMessage('');
    setState({
      id: '',
      title: '',
      status: '',
      priority: '',
      startDate: '',
      endDate: '',
    });
    useFetchTasks();
  };

  const priorityOptions = [
    { value: Priority.LOW },
    { value: Priority.MEDIUM },
    { value: Priority.HIGH },
    { value: Priority.URGENT },
  ];

  const statusOptions = [
    { value: Status.PENDING },
    { value: Status.PROGRESS },
    { value: Status.REVIEW },
    { value: Status.COMPLETED },
  ];

  return (
    <MenuList px={2} width="250px" zIndex="dropdown">
      <form noValidate onSubmit={handleSubmit}>
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">Task Id</Text>
          <Input
            type="text"
            name="id"
            value={state.id}
            onChange={handleChange}
          />
        </Box>
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">Title</Text>
          <Input
            type="text"
            name="title"
            value={state.title}
            onChange={handleChange}
          />
        </Box>
        {/* <Box px={2} mb={4}>
          <Text fontWeight="semibold">Customer Email</Text>
          <Input
            type="text"
            name="customerEmail"
            value={state.customerEmail}
            onChange={handleChange}
          />
        </Box> */}
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">Status</Text>
          <Select name="status" value={state.status} onChange={handleChange}>
            <option value="">All status</option>
            {statusOptions.map((pr) => (
              <option key={pr.value}>{pr.value}</option>
            ))}
          </Select>
        </Box>
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">Priority</Text>
          <Select
            name="priority"
            value={state.priority}
            onChange={handleChange}
          >
            <option value="">All priority</option>
            {priorityOptions.map((pr) => (
              <option key={pr.value}>{pr.value}</option>
            ))}
          </Select>
        </Box>
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">Start date</Text>
          <Input
            type="date"
            max={formatDate(new Date())}
            name="startDate"
            value={state.startDate}
            onChange={(evt) => {
              handleChange(evt);
              setMessage('');
            }}
          />
        </Box>
        <Box px={2} mb={4}>
          <Text fontWeight="semibold">End date</Text>
          <Input
            type="date"
            max={formatDate(new Date())}
            min={state.startDate}
            name="endDate"
            value={state.endDate}
            onChange={(evt) => {
              handleChange(evt);
              setMessage('');
            }}
          />
        </Box>
        <Text color="red" my={2}>
          {message}
        </Text>
        <MenuDivider />
        <Flex
          bg="gray.50"
          p={2}
          alignItems="center"
          justifyContent="space-between"
          gap="2"
        >
          <Button
            variant="outline"
            onClick={resetFilter}
            isLoading={isLoading}
            spinner={<BeatLoader size={8} />}
          >
            Reset
          </Button>
          <Button
            type="submit"
            colorScheme="twitter"
            isLoading={isLoading}
            spinner={<BeatLoader size={8} color="white" />}
          >
            Filter
          </Button>
        </Flex>
      </form>
    </MenuList>
  );
};

export default Filters;
