import { useState } from 'react';
import {
  Badge,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HTMLChakraProps,
  useToast,
  Text,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { Status, StatusColor } from 'src/interfaces/task';
import { capitalize } from 'src/utils/generators';
import { toastParams } from 'src/utils/toastParams';
import { useUpdateTaskMutation } from 'src/hooks/data/task';
import { ConfirmModal } from '../ConfirmModal';

interface RenderOptions {
  value: Status;
  color: StatusColor;
}
const options: RenderOptions[] = [
  {
    value: Status.PENDING,
    color: StatusColor.PENDING,
  },
  {
    value: Status.PROGRESS,
    color: StatusColor.PROGRESS,
  },
  {
    value: Status.REVIEW,
    color: StatusColor.REVIEW,
  },
  {
    value: Status.COMPLETED,
    color: StatusColor.COMPLETED,
  },
];

interface StatusDropdownProps extends HTMLChakraProps<'button'> {
  status: string;
  taskId: string;
}

const StatusDropdown = ({ status, taskId, ...rest }: StatusDropdownProps) => {
  const label = options.find((x) => x.value === status);
  const toast = useToast();
  const [updateTask, { isLoading }] = useUpdateTaskMutation();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const onCloseModal = () => {
    setSelectedStatus(null);
  };

  const updateStatus = (newStatus: string) => {
    const payload = { status: newStatus, taskId };
    updateTask({ taskId, data: payload })
      .unwrap()
      .then(() => {
        toast({
          title: 'Updated Successfully',
          status: 'success',
          ...toastParams,
        });
        onCloseModal();
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  if (status === Status.COMPLETED) {
    return (
      <Badge colorScheme={label?.color}>
        {label?.value}{' '}
        <FiChevronDown
          style={{
            display: 'inline',
            marginLeft: '5px',
            verticalAlign: 'middle',
          }}
        />
      </Badge>
    );
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Badge}
          cursor="pointer"
          colorScheme={label?.color}
          {...rest}
        >
          {label?.value}{' '}
          <FiChevronDown
            style={{
              display: 'inline',
              marginLeft: '5px',
              verticalAlign: 'middle',
            }}
          />
        </MenuButton>

        <MenuList minW="150px">
          {options
            .filter((opt) => opt.value !== status)
            .map((opt) => (
              <MenuItem
                key={opt.value}
                onClick={() =>
                  opt.value === Status.COMPLETED
                    ? setSelectedStatus(opt.value)
                    : updateStatus(opt.value)
                }
              >
                <Badge colorScheme={opt.color}>{capitalize(opt.value)}</Badge>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>

      {/* Modal */}
      <ConfirmModal
        title="ticket status"
        actionText="Yes, continue"
        isOpen={!!selectedStatus}
        onClose={onCloseModal}
        handleContinue={() => updateStatus(selectedStatus!)}
        isSubmitting={isLoading}
      >
        <Text>
          When status is completed, ticket status cannot be changed again.
        </Text>
        <Text>Are you sure you want to continue?</Text>
      </ConfirmModal>
    </>
  );
};

export default StatusDropdown;
