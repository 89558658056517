import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
  description: string;
  faviconUrl?: string;
};

const PageHelmet = ({
  title,
  description,
  faviconUrl,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={faviconUrl} />
        <meta name="name" content={title} />
        <meta name="description" content={description} />
        <link rel="apple-touch-icon" href={faviconUrl} />
      </Helmet>
      {children}
    </div>
  );
};

export default PageHelmet;
