import { useState } from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
// Actions
import { useFetchTasksQuery, useUpdateTaskMutation } from 'src/hooks/data/task';
import { Task } from 'src/interfaces';
// Components
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useAuth } from 'src/hooks/useAuth';
import { toastParams } from 'src/utils/toastParams';
import TaskColumn from './components/TaskColumn';
import { CreateIssue } from './components/CreateIssue';
import { Loading } from './components/Loading';

import styles from './components/board.module.css';

const taskStatus = [
  {
    id: 1,
    name: 'Todo',
    value: Task.Status.PENDING,
  },
  {
    id: 2,
    name: 'In Progress',
    value: Task.Status.PROGRESS,
  },
  {
    id: 3,
    name: 'In Review',
    value: Task.Status.REVIEW,
  },
  {
    id: 4,
    name: 'Completed',
    value: Task.Status.COMPLETED,
  },
];

const Board = () => {
  const { isAdmin } = useAuth();
  const toast = useToast();
  const [updateTask, { isLoading }] = useUpdateTaskMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<Record<
    'status' | 'taskId',
    string
  > | null>(null);
  const { allTasks, isLoading: isLoadingTasks } = useFetchTasksQuery(
    { isAdmin },
    {
      selectFromResult: (results) => ({
        allTasks: results.data?.rows || [],
        ...results,
      }),
    }
  );

  const handleUpdateBoard = async (
    payload: Record<'status' | 'taskId', string>
  ) => {
    updateTask({ taskId: payload.taskId, data: payload })
      .unwrap()
      .then(() => setSelectedStatus(null))
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  const handleDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;

    if (source.droppableId === destination.droppableId) return;

    const payload = {
      status: destination.droppableId,
      taskId: draggableId,
    };
    if (destination.droppableId === Task.Status.COMPLETED) {
      setSelectedStatus(payload);
      return;
    }

    await handleUpdateBoard(payload);
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        mb={2}
        bg="blue.900"
        color="white"
        _hover={{ bg: 'blue.900' }}
      >
        Create Issue
      </Button>
      <Box position="relative" className={styles['column-container']} mb={-6}>
        <DragDropContext onDragEnd={(payload) => handleDragEnd(payload)}>
          {taskStatus.map((col) => (
            <Droppable droppableId={col.value} key={col.value}>
              {(provided, snapshot) => (
                <TaskColumn
                  {...provided.droppableProps}
                  column={col}
                  dragRef={provided.innerRef}
                  allTasks={allTasks}
                  isDraggingOver={snapshot.draggingOverWith}
                  placeholder={provided.placeholder}
                />
              )}
            </Droppable>
          ))}
        </DragDropContext>
        {isLoadingTasks && <Loading />}
      </Box>

      {isOpen && (
        <CreateIssue isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      )}

      {/* Modal */}
      <ConfirmModal
        title="ticket status"
        actionText="Yes, continue"
        isOpen={!!selectedStatus}
        onClose={() => setSelectedStatus(null)}
        handleContinue={() => handleUpdateBoard(selectedStatus!)}
        isSubmitting={isLoading}
      >
        <Text>
          When status is completed, ticket status cannot be changed again.
        </Text>
        <Text>Are you sure you want to continue?</Text>
      </ConfirmModal>
    </>
  );
};

export default Board;
