export const getFileSize = (size: number) => {
  const units = ['B', 'KB', 'MB'];

  const exponent = Math.min(
    Math.floor(Math.log(size) / Math.log(1024)),
    units.length - 1
  );
  const approx = size / 1024 ** exponent;

  const output =
    exponent === 0
      ? `${size} bytes`
      : `${approx.toFixed(1)} ${units[exponent]}`;

  return output;
};
