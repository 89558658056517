/* eslint-disable no-empty */
import { isFuture, isToday } from 'date-fns';
import { Task } from 'src/interfaces';
import { api } from 'src/store/services/baseApi';

type TaskQueryType = {
  size?: number;
  page?: number;
  id?: string;
  title?: string;
  customerEmail?: string;
  status?: string;
  priority?: string;
  userIds?: number[];
  departmentIds?: number[];
  startDate?: Date;
  endDate?: Date;
} & AdminType;

export type AdminType = { isAdmin: boolean };

const queryString = (args: Record<string, any>) => {
  return Object.keys(args)
    .map((key) => {
      if (Array.isArray(args[key])) {
        return args[key].length
          ? `${key}=${encodeURIComponent(JSON.stringify(args[key]))}`
          : '';
      }
      return args[key] ? `${key}=${args[key]}` : '';
    })
    .filter(Boolean)
    .join('&');
};

export const updateExistingProperty = <T extends Record<string, any>>(
  oldData: T,
  payload: Record<string, any>
) => {
  return Object.entries(oldData).reduce(
    (acc, [key, value]) =>
      key in payload
        ? { ...acc, [key]: payload[key] }
        : { ...acc, [key]: value },
    {}
  ) as T;
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchTaskStatsMe: build.query<any, { startDate?: Date; endDate?: Date }>({
      query({ startDate, endDate }) {
        endDate =
          endDate && (isFuture(endDate) || isToday(endDate))
            ? new Date()
            : endDate;
        return {
          url: `tasks/statistics/me?startDate=${startDate}&endDate=${endDate}`,
          method: 'GET',
        };
      },
    }),
    fetchTasks: build.query<Task.PaginatedTasks, TaskQueryType | AdminType>({
      query: ({ isAdmin, ...args }) => {
        const urlPath = isAdmin
          ? `tasks/all?${queryString(args)}`
          : `tasks?${queryString(args)}`;
        return {
          url: urlPath,
          method: 'GET',
        };
      },
      providesTags: (result) =>
        result
          ? result.rows.map(({ id }) => ({ type: 'fetchTasks', id }))
          : ['fetchTasks'],
    }),
    createTask: build.mutation<Task.Task, FormData>({
      query: (data) => ({ url: 'task', method: 'POST', data }),
      invalidatesTags: ['fetchTasks'],
    }),
    fetchTask: build.query<Task.Task, { taskId: string } & AdminType>({
      query: ({ isAdmin, taskId }) => {
        const urlPath = isAdmin ? `task/${taskId}/admin` : `task/${taskId}`;
        return { url: urlPath, method: 'GET' };
      },
      providesTags: (result, error, { taskId }) => [
        { type: 'fetchTasks', id: taskId },
      ],
    }),
    updateTask: build.mutation<
      Task.Task,
      { taskId: string; data: FormData | Task.taskPayloadProps }
    >({
      query: ({ taskId, data }) => ({
        url: `task/${taskId}`,
        method: 'PUT',
        data,
      }),
      onQueryStarted(
        { taskId, data: payload },
        { dispatch, queryFulfilled, getState }
      ) {
        const selectedInvalidated = injectedRtkApi.util.selectInvalidatedBy(
          getState(),
          [{ type: 'fetchTasks', id: taskId }]
        );
        selectedInvalidated.forEach(({ endpointName, originalArgs }) => {
          if ('taskId' in payload) {
            const patchResult = dispatch(
              injectedRtkApi.util.updateQueryData(
                endpointName as 'fetchTasks' | 'fetchTask',
                originalArgs,
                (oldData) => {
                  if (endpointName === 'fetchTasks') {
                    return {
                      ...oldData,
                      rows: (oldData as Task.PaginatedTasks)?.rows?.map(
                        (task) => {
                          const data = updateExistingProperty(task, payload);
                          return task.id === taskId ? data : task;
                        }
                      ),
                    };
                  }

                  return updateExistingProperty(oldData, payload);
                }
              )
            );
            queryFulfilled.catch(patchResult.undo);
          } else {
            queryFulfilled.then(() => {
              dispatch(
                injectedRtkApi.util.invalidateTags([
                  { type: 'fetchTasks', id: taskId },
                ])
              );
            });
          }
        });
      },
    }),
    deleteTask: build.mutation<void, string>({
      query: (slug) => ({ url: `task/${slug}`, method: 'DELETE' }),
      onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
        const selectedInvalidated = injectedRtkApi.util.selectInvalidatedBy(
          getState(),
          [{ type: 'fetchTasks', id: arg }]
        );
        selectedInvalidated.forEach(({ endpointName, originalArgs }) => {
          if (endpointName === 'fetchTasks') {
            const patchResult = dispatch(
              injectedRtkApi.util.updateQueryData(
                endpointName,
                originalArgs,
                (oldData) => ({
                  ...oldData,
                  rows: oldData.rows.filter((old) => old.id !== arg),
                  count: oldData.count - 1,
                })
              )
            );
            queryFulfilled.catch(patchResult.undo);
          }
        });
      },
    }),
    deleteAttachment: build.mutation<void, { taskId: string; fileId: string }>({
      query: ({ taskId, fileId }) => ({
        url: `task/${fileId}/file`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { taskId }) => [
        { type: 'fetchTasks', id: taskId },
      ],
    }),
    fetchTaskLogs: build.query({
      query: (taskId) => ({ url: `task/${taskId}/log`, method: 'GET' }),
    }),
    getComments: build.query<
      { rows: Task.Comment[]; isPermitted: boolean },
      string
    >({
      query: (taskId) => ({ url: `task/${taskId}/comments`, method: 'GET' }),
      providesTags: ['getComments'],
    }),
    postComment: build.mutation<
      Task.Comment,
      { taskId: string; description: string }
    >({
      query: ({ taskId, description }) => ({
        url: `task/${taskId}/comment`,
        method: 'POST',
        data: {
          description,
        },
      }),
      async onQueryStarted({ taskId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            injectedRtkApi.util.updateQueryData(
              'getComments',
              taskId,
              (oldData) => {
                oldData.rows.unshift(data);
              }
            )
          );
        } catch {}
      },
    }),
    deleteComment: build.mutation<
      Task.Comment,
      { taskId: string; commentId: number }
    >({
      query: ({ taskId, commentId }) => ({
        url: `task/${taskId}/comment/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getComments'],
    }),
  }),
});

export { injectedRtkApi as taskApi };

export const {
  useFetchTasksQuery,
  useDeleteTaskMutation,
  useFetchTaskQuery,
  useFetchTaskLogsQuery,
  useFetchTaskStatsMeQuery,
  useGetCommentsQuery,
  usePostCommentMutation,
  useDeleteCommentMutation,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteAttachmentMutation,
} = injectedRtkApi;
