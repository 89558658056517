import { createApi } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig } from 'axios';
import axios from 'src/utils/axios';

export type ErrorQueryResponse = { status: number; data: any };

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    ErrorQueryResponse
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({ url, method, data, params });
      return { data: result.data };
    } catch (err: any) {
      return {
        error: {
          status: err?.status,
          data: err?.data,
        },
      };
    }
  };

export const api = createApi({
  baseQuery: axiosBaseQuery(),
  refetchOnReconnect: true,
  tagTypes: [
    'fetchMe',
    'fetchUsers',
    'notification',
    'fetchTasks',
    'getComments',
    'getDepartments',
    'getPlatformSettings',
  ],
  endpoints: () => ({}),
});
