import { useMemo } from 'react';
import {
  Avatar,
  Box,
  Flex,
  SkeletonCircle,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { StackList } from 'src/components/StackList';
import { useFetchTaskLogsQuery } from 'src/hooks/data/task';
import { dateToTimeAgo } from 'src/utils/time-date-functions';

function History({ slug }: { slug: string }) {
  const { data, isLoading } = useFetchTaskLogsQuery(slug);

  const reverseLogs = useMemo(() => {
    return [...(data ?? [])].reverse();
  }, [data]);

  if (isLoading) {
    return (
      <Box>
        {[1, 2].map((i) => (
          <Flex gap="2" align="start" key={i} mb={5}>
            <SkeletonCircle size="10" mr={2} />
            <Box flexGrow={1}>
              <Flex align="center" gap="3" justifyContent="space-between">
                <Skeleton height="2" width="150px" />
                <Skeleton height="2" width="100px" />
              </Flex>
              <Skeleton mt={2} height="2" />
              <Skeleton mt={2} height="2" />
            </Box>
          </Flex>
        ))}
      </Box>
    );
  }

  return reverseLogs.length ? (
    <StackList
      data={reverseLogs}
      renderItem={(item, index) => (
        <Flex gap="2" align="start" key={index}>
          <Avatar
            size="sm"
            name={`${item.user.firstName} ${item.user.lastName}`}
            mr={2}
          />
          <Box flexGrow={1}>
            <Flex align="center" gap="3" justifyContent="space-between">
              <Text fontSize="sm" fontWeight="semibold">
                {item.user.firstName} {item.user.lastName}
              </Text>
              <Text fontSize="xs">{dateToTimeAgo(item.createdAt)}</Text>
            </Flex>
            <Text
              mt="2"
              fontSize="sm"
              dangerouslySetInnerHTML={{ __html: item.message }}
            />
          </Box>
        </Flex>
      )}
    />
  ) : (
    <Text>No history</Text>
  );
}

export default History;
