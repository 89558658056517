import { CgPentagonUp as OpenTaskIcon } from 'react-icons/cg';
import { MdOutlineTag as ProgressTaskIcon } from 'react-icons/md';
import { IoMdCodeWorking as ReviewTaskIcon } from 'react-icons/io';
import { HiBadgeCheck as DoneTaskIcon } from 'react-icons/hi';
import { Status } from 'src/interfaces/task';
import { FunctionComponent } from 'react';

type RenderOptions = {
  // eslint-disable-next-line no-unused-vars
  [k in Status]: {
    color: string;
    icon: FunctionComponent<any>;
  };
};

const options: RenderOptions = {
  pending: {
    color: 'gray',
    icon: OpenTaskIcon,
  },
  progress: {
    color: 'blue',
    icon: ProgressTaskIcon,
  },
  review: {
    color: 'orange',
    icon: ReviewTaskIcon,
  },
  completed: {
    color: 'green',
    icon: DoneTaskIcon,
  },
};

const TaskIcon = ({ status }: { status: string }) => {
  // TODO: find a better way to get the status type
  const typedStatus = status as Status;
  const option = options[typedStatus];

  return <option.icon color={option.color} fontSize="20px" />;
};

export default TaskIcon;
