import { FormEvent, SyntheticEvent, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Divider,
  Box,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  StackDivider,
  VStack,
  useToast,
  useMediaQuery,
  Switch,
  Tooltip,
  MenuButton,
  AvatarGroup,
  Avatar,
  // Badge,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import UserDropdown from 'src/components/dropdown/UserDropdown';
import PriorityDropdown from 'src/components/dropdown/PriorityDropdown';
import Editor from 'src/components/editor/CKeditor';

// Icons
import {
  MdSubtitles,
  MdLowPriority,
  MdAttachment,
  MdOutlineDateRange,
} from 'react-icons/md';
import { RiMenu2Fill } from 'react-icons/ri';
import {
  AiOutlineInfoCircle,
  AiOutlineTeam,
  AiOutlineUser,
} from 'react-icons/ai';

import { UserStrip, Priority } from 'src/interfaces/task';
import FilePreview from 'src/components/FilePreview';
import { toastParams } from 'src/utils/toastParams';
import UploadInput from 'src/components/UploadInput';
import { MultipleUserDropdown } from 'src/components/dropdown/MultipleUserDropdown';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import { useCreateTaskMutation } from 'src/hooks/data/task';
import { DepartmentDropdown } from 'src/components/dropdown/departmentDropdown';
import { Department } from 'src/interfaces/company';
import DateTimeDropdown from 'src/components/dropdown/DateTimeDropdown';
import {
  defaultError,
  defaultValues,
  titleValidator,
  validation,
} from './formUtils';

type AddModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

type formPros = {
  title: string;
  description: string;
  assignedTo: number | null;
  assigned: UserStrip | null;
  priority: Priority;
  estimate: string;
  department: Department | null;
};

export const CreateIssue = ({ isOpen, handleClose }: AddModalProps) => {
  const [createTask, { isLoading }] = useCreateTaskMutation();
  const { data: users = [] } = useFetchUsersQuery('activated');
  const toast = useToast();
  const [isMediaIpad] = useMediaQuery('(max-width: 720px)');
  const [values, setValues] = useState<formPros>(defaultValues);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState(defaultError);
  const [isPrivate, setIsPrivate] = useState(false);
  const [permittedUsers, setPermittedUsers] = useState<UserStrip[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChangeItem = (value: Record<string, unknown>) => {
    if ('department' in value) {
      // Reset assigned user when department changes
      setValues((prevValues) => ({
        ...prevValues,
        assignedTo: null,
        assigned: null,
      }));
    }
    setValues((prevValues) => ({ ...prevValues, ...value }));
    // handle error onChange action
    const { errors } = validation(value);
    setError((prevValues) => ({ ...prevValues, ...errors }));
  };

  const handleBlur = (e: FormEvent<HTMLInputElement>) => {
    const value = { [e.currentTarget.name]: e.currentTarget.value };
    const { errors } = validation(value);
    setError((prevValues) => ({ ...prevValues, ...errors }));
  };

  const handleDeleteFile = (index: number) => {
    const newFiles = files.filter((_, idx) => idx !== index);
    setFiles(newFiles);
  };

  const resetForm = () => {
    setValues(defaultValues);
    setPermittedUsers([]);
    setIsPrivate(false);
    setFiles([]);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    // VALIDATION
    const { isInValid, errors } = validation(values);
    if (isInValid) {
      setError((prevValues) => ({ ...prevValues, ...errors }));
      return;
    }

    const formData = new FormData();

    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('priority', values.priority);
    formData.append('assignedTo', String(values.assignedTo));
    formData.append('estimate', values.estimate);
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('isPrivate', String(isPrivate));
    formData.append('departmentId', String(values.department?.id ?? ''));
    (isPrivate ? permittedUsers : []).forEach((user) => {
      formData.append('permitted', user.email);
    });

    createTask(formData)
      .unwrap()
      .then(() => {
        toast({
          title: 'Submitted successfully',
          status: 'success',
          ...toastParams,
        });
        resetForm();
      })
      .catch((err) => {
        toast({
          title: err?.data?.message,
          status: 'error',
          ...toastParams,
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size={isMediaIpad ? 'full' : '4xl'}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Issue</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" alignItems="start" gap="2">
              <MdSubtitles size="20px" />
              <FormControl isInvalid={error.title}>
                <FormLabel mt={-0.5} fontWeight="bold">
                  Title
                </FormLabel>
                <Input
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={(e) => handleChangeItem({ title: e.target.value })}
                  name="title"
                  id="title"
                  placeholder="Add a title"
                />
                <FormErrorMessage>
                  {titleValidator(values.title)}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box
              display={isMediaIpad ? 'block' : 'flex'}
              justifyContent="space-between"
              mt="8"
            >
              <Box width={isMediaIpad ? 'full' : '65%'}>
                <Box display="flex" alignItems="start" gap="2">
                  <RiMenu2Fill size="20px" />
                  <FormControl>
                    <FormLabel mt={-0.5} fontWeight="bold">
                      Description
                    </FormLabel>
                    <Editor
                      contents={values.description}
                      users={users}
                      setContents={(value) =>
                        handleChangeItem({ description: value })
                      }
                      placeholder="Add a more detailed description"
                    />
                    <FormErrorMessage>
                      Description is required.
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                {files.length ? (
                  <Box mt="8">
                    <Box display="flex" alignItems="start" gap="2" mb="2">
                      <MdAttachment size="20px" />
                      <Text fontWeight="bold">Attachments</Text>
                    </Box>
                    {files.map((file, index) => (
                      <FilePreview
                        key={index}
                        file={file}
                        onDelete={() => handleDeleteFile(index)}
                      />
                    ))}

                    <Box ml={5} px="2">
                      <Button
                        mt="4"
                        px="4"
                        bg="gray.200"
                        fontSize="sm"
                        rounded="base"
                        onClick={() => inputRef.current?.click()}
                      >
                        Add an attachment
                      </Button>
                    </Box>
                  </Box>
                ) : undefined}
              </Box>
              <Box
                width={isMediaIpad ? 'full' : '30%'}
                mt={isMediaIpad ? 8 : 0}
              >
                <VStack
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={4}
                  align="stretch"
                >
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="1">
                      <AiOutlineTeam size="20px" />
                      <Text fontSize="sm" fontWeight="bold">
                        Department
                      </Text>
                      <Tooltip
                        hasArrow
                        label="Only department member(s) can view task"
                      >
                        <span>
                          {' '}
                          <AiOutlineInfoCircle color="green" />
                        </span>
                      </Tooltip>
                    </Box>
                    <DepartmentDropdown
                      selectedData={
                        values.department ? [values.department] : []
                      }
                      onApply={(val) => {
                        handleChangeItem({ department: val[0] });
                      }}
                      isSingle
                    >
                      <MenuButton
                        type="button"
                        p="1"
                        isTruncated
                        borderRadius="md"
                        _hover={{ bg: 'gray.200' }}
                        border="2px solid transparent"
                        maxW="100%"
                      >
                        <Text isTruncated width="100%">
                          {values.department
                            ? values.department.name
                            : 'Unassigned'}
                        </Text>
                      </MenuButton>
                    </DepartmentDropdown>
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="1">
                      <AiOutlineUser size="20px" />
                      <Text fontSize="sm" fontWeight="bold">
                        Assignee
                      </Text>
                    </Box>
                    <UserDropdown
                      data={values.assigned!}
                      onChangeUser={handleChangeItem}
                      hasError={error.assignedTo}
                      enableClick
                      externalUsers={values.department?.users}
                      useExternalUsers={!!values.department}
                    />
                    {error.assignedTo && (
                      <Text color="red.500" fontSize="sm" mt="2">
                        Assignee is required
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="2">
                      <MdLowPriority size="20px" />
                      <Text fontSize="sm" fontWeight="bold">
                        Priority
                      </Text>
                    </Box>
                    <PriorityDropdown
                      priority={values.priority}
                      onChange={handleChangeItem}
                    />
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="2">
                      <MdAttachment size="20px" />
                      <Text fontSize="sm" fontWeight="bold">
                        Attachment
                      </Text>
                    </Box>
                    {/* Hidden file upload input */}
                    <UploadInput
                      ref={inputRef}
                      files={files}
                      setFiles={setFiles}
                    />
                    <Button
                      px="4"
                      bg="gray.200"
                      rounded="base"
                      onClick={() => inputRef.current?.click()}
                    >
                      choose a file
                    </Button>
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="2">
                      <MdOutlineDateRange size="20px" />
                      <Text fontSize="sm" fontWeight="bold">
                        Due date
                      </Text>
                    </Box>
                    <DateTimeDropdown
                      estimatedDate={values.estimate}
                      onClickApply={(est) =>
                        handleChangeItem({ estimate: est })
                      }
                    />
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="start" gap="2" mb="2">
                      <Switch
                        id="private-task"
                        onChange={(e) => setIsPrivate(e.target.checked)}
                        isChecked={isPrivate}
                      />
                      <Text fontSize="sm" fontWeight="bold">
                        Set to private
                      </Text>
                      <Tooltip
                        hasArrow
                        label="Only permitted user(s) will be able to view this task."
                      >
                        <span>
                          {' '}
                          <AiOutlineInfoCircle color="green" />
                        </span>
                      </Tooltip>
                    </Box>
                    {isPrivate && (
                      <MultipleUserDropdown
                        data={permittedUsers}
                        onApply={(val) => setPermittedUsers(val)}
                      >
                        <MenuButton
                          as={Button}
                          bg="gray.200"
                          color="white"
                          _hover={{ bg: 'blue.900' }}
                        >
                          {permittedUsers.length ? (
                            <AvatarGroup color="blue.900" size="sm" max={4}>
                              {permittedUsers.map((item) => (
                                <Avatar
                                  key={item?.id}
                                  name={`${item?.firstName} ${item?.lastName}`}
                                />
                              ))}
                            </AvatarGroup>
                          ) : (
                            'Click to select member(s)'
                          )}
                        </MenuButton>
                      </MultipleUserDropdown>
                    )}
                  </Box>
                </VStack>
              </Box>
            </Box>
            <Divider orientation="horizontal" my="4" />
            <Button
              type="submit"
              isLoading={isLoading}
              colorScheme="teal"
              spinner={<BeatLoader size={8} color="white" />}
            >
              Submit
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
