import { Formik, Form } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Alert,
  AlertDescription,
  AlertIcon,
  Text,
  Select,
  CloseButton,
  Box,
  MenuButton,
  Flex,
  Tag,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';
import { AiOutlinePlus } from 'react-icons/ai';
import { roles } from 'src/constant';
import { useAddUserMutation } from 'src/hooks/data/auth';
import { DepartmentDropdown } from 'src/components/dropdown/departmentDropdown';
import { Department } from 'src/interfaces/company';

export default function NewMemberForm() {
  const [addUser, { isLoading }] = useAddUserMutation();
  const [selectedDepts, setSelectedDepts] = useState<Department[]>([]);

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        firstName: Yup.string()
          .min(3, 'First name must be at least 3 characters')
          .required('First name is required'),
        lastName: Yup.string()
          .min(3, 'Last name must be at least 3 characters')
          .required('Last name is required'),
        role: Yup.string().required('Role is required'),
      })}
      onSubmit={async (values, { setStatus, resetForm }) => {
        try {
          const { submit, role, ...rest } = values;
          const data = {
            ...rest,
            roles: [role],
            departments: selectedDepts.map((dept) => dept.id),
          };
          await addUser(data).unwrap();
          resetForm();
          setStatus({
            type: 'success',
            title: 'Success',
          });
          setSelectedDepts([]);
        } catch (error: any) {
          setStatus({
            type: 'error',
            title: error?.data?.error,
            message: error?.data?.message,
          });
        } finally {
          setTimeout(() => {
            setStatus();
          }, 7000);
        }
      }}
    >
      {({
        status,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setStatus,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl
            id="firstName"
            mb="1rem"
            isInvalid={Boolean(touched.firstName && errors.firstName)}
          >
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="lastName"
            mb="1rem"
            isInvalid={Boolean(touched.lastName && errors.lastName)}
          >
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="email"
            mb="1rem"
            isInvalid={Boolean(touched.email && errors.email)}
          >
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="role"
            mb="1rem"
            isInvalid={Boolean(touched.role && errors.role)}
          >
            <FormLabel>Role</FormLabel>
            <Select
              name="role"
              placeholder="Select role"
              value={values.role}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {roles.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.role}</FormErrorMessage>
          </FormControl>
          <Box mb="1rem">
            <Box mb={2} display="flex" gap={2}>
              <Text fontWeight="medium">Department</Text>
              <DepartmentDropdown
                selectedData={selectedDepts}
                onApply={(val) => {
                  setSelectedDepts(val);
                }}
              >
                <MenuButton
                  size="xs"
                  as={Button}
                  bg="blue.900"
                  color="white"
                  _hover={{ bg: 'blue.900' }}
                >
                  <AiOutlinePlus />
                </MenuButton>
              </DepartmentDropdown>
            </Box>
            <Flex
              minH="3rem"
              flexWrap="wrap"
              border="1px solid"
              borderColor="gray.200"
              rounded="md"
              p={2}
              gap={2}
            >
              {selectedDepts.map((dept, index) => (
                <Tag key={index} variant="outline" colorScheme="blue">
                  {dept.name}
                </Tag>
              ))}
            </Flex>
          </Box>

          {status && status?.type && (
            <Alert status={status?.type} variant="subtle" mb="1rem">
              <AlertIcon />
              <AlertDescription>
                <Text fontWeight="semibold">{status?.title}</Text>
                <Text>
                  {status?.type !== 'success'
                    ? status?.message
                    : 'New member created successfully. An email has been sent to user inorder to complete the registration.'}
                </Text>
              </AlertDescription>
              <CloseButton
                alignSelf="flex-start"
                position="absolute"
                right={-1}
                top={-1}
                onClick={() => setStatus({})}
              />
            </Alert>
          )}
          <Button
            isLoading={isLoading}
            colorScheme="teal"
            width="full"
            type="submit"
            spinner={<BeatLoader size={8} color="white" />}
          >
            Create Member
          </Button>
        </Form>
      )}
    </Formik>
  );
}
