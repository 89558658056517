import { Heading, Box, Image, Text } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import emptyBox from 'src/assets/images/empty-inbox.svg';

type Props = {
  title: string;
  subtitle?: string;
  imageSrc?: any;
};

const EmptyResult = ({
  title,
  subtitle,
  children,
  imageSrc,
}: PropsWithChildren<Props>) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" my={6}>
      <Image src={imageSrc ?? emptyBox} alt="empty" width={150} />
      <Heading>{title}</Heading>
      <Text>{subtitle}</Text>
      {children}
    </Box>
  );
};

export default EmptyResult;
