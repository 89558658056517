import { ImageProps, forwardRef, Box, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import logo from 'src/assets/images/logo_blue.png';
import { useGetPlatformSettingsQuery } from 'src/hooks/data/admin/settings';

const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  const { data } = useGetPlatformSettingsQuery();
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="50px"
      width="100px"
      as={RouterLink}
      to="/"
      {...props}
    >
      <Image
        src={data?.logoUrl}
        fallbackSrc={logo}
        alt="logo"
        ref={ref}
        position="absolute"
      />
    </Box>
  );
});

export default Logo;
