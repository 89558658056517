import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Badge,
  Input,
  Avatar,
  Button,
  useToast,
  SkeletonCircle,
  Skeleton,
} from '@chakra-ui/react';
import { StackList } from 'src/components/StackList';
import Editor from 'src/components/editor/CKeditor';
import { toastParams } from 'src/utils/toastParams';
import { BeatLoader } from 'react-spinners';
import { useAuth } from 'src/hooks/useAuth';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import {
  useDeleteCommentMutation,
  useGetCommentsQuery,
  usePostCommentMutation,
} from 'src/hooks/data/task';
import { dateToTimeAgo } from 'src/utils/time-date-functions';

const Comments = ({ slug }: { slug: string }) => {
  const toast = useToast();
  const { user } = useAuth();
  const { data: users = [] } = useFetchUsersQuery('activated');
  const { comments, isLoading, isPermitted } = useGetCommentsQuery(slug, {
    selectFromResult: ({ data, ...rest }) => ({
      comments: data?.rows ?? [],
      isPermitted: data?.isPermitted,
      ...rest,
    }),
  });

  const [postComment, { isLoading: isPosting }] = usePostCommentMutation();
  const [deleteComment, { isLoading: isDeleting }] = useDeleteCommentMutation();
  const [contents, setContents] = useState('');
  const [focusComment, setFocusComment] = useState(false);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(
    null
  );

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      if (e.code === 'Escape') {
        setFocusComment(true);
      }
    });
  }, []);

  const handleSelectedForModal = (value: number | null) => {
    setSelectedCommentId(value);
  };

  const handleDeleteComment = () => {
    if (!selectedCommentId) return;

    deleteComment({ taskId: slug, commentId: selectedCommentId })
      .unwrap()
      .then(() => {
        handleSelectedForModal(null);
        toast({
          title: 'Deleted successfully',
          status: 'success',
          ...toastParams,
        });
      });
  };

  const handleSubmitComment = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await postComment({ taskId: slug, description: contents }).unwrap();
      toast({
        title: 'Submitted successfully',
        status: 'success',
        ...toastParams,
      });
      setContents('');
    } catch (error: any) {
      toast({
        title: error?.data?.message || 'Something went wrong!',
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <>
      <Flex align="start" py="3">
        <Avatar
          size="sm"
          name={`${user?.firstName} ${user?.lastName}`}
          mr={2}
        />
        {!focusComment ? (
          <Box width="100%">
            <Input
              fontSize="sm"
              placeholder="Click to add comment..."
              onFocusCapture={() => setFocusComment(true)}
            />
            <Text fontSize="xs" color="gray.500" my="2">
              <b>Pro tip:</b> press <Badge colorScheme="gray">esc</Badge> to
              comment
            </Text>
          </Box>
        ) : (
          <Box flexGrow={1}>
            <form onSubmit={handleSubmitComment}>
              <Editor
                shouldFocus={focusComment}
                placeholder="Add a comment..."
                users={users}
                contents={contents}
                setContents={setContents}
              />
              <Flex gap="2" mt="2">
                <Button
                  type="submit"
                  colorScheme="teal"
                  isLoading={isPosting}
                  isDisabled={!contents || isPosting || !isPermitted}
                  spinner={<BeatLoader size={8} color="white" />}
                >
                  Submit
                </Button>
                <Button
                  type="button"
                  colorScheme="gray"
                  onClick={() => setFocusComment(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        )}
      </Flex>
      {/* Render comments */}
      {isLoading && !comments.length ? (
        <Box>
          {[1, 2].map((i) => (
            <Flex gap="2" align="start" key={i} mb={5}>
              <SkeletonCircle size="10" mr={2} />
              <Box flexGrow={1}>
                <Flex align="center" gap="3" justifyContent="space-between">
                  <Skeleton height="2" width="150px" />
                  <Skeleton height="2" width="100px" />
                </Flex>
                <Skeleton mt={2} height="2" />
                <Skeleton mt={2} height="2" />
              </Box>
            </Flex>
          ))}
        </Box>
      ) : (
        <StackList
          data={comments}
          renderItem={(comment, idx) => (
            <Flex gap="2" align="start" key={idx} id={String(comment.id)}>
              <Avatar
                size="sm"
                name={`${comment.user.firstName} ${comment.user.lastName}`}
                mr={2}
              />
              <Box flexGrow={1}>
                <Flex align="center" gap="3" justifyContent="space-between">
                  <Text fontSize="sm" fontWeight="semibold">
                    {comment.user.firstName} {comment.user.lastName}
                  </Text>
                  <Text fontSize="xs">{dateToTimeAgo(comment.createdAt)}</Text>
                </Flex>
                <Box bg="whiteAlpha.500" borderRadius="md" p="2" mt="2">
                  <Text
                    fontSize="sm"
                    dangerouslySetInnerHTML={{ __html: comment.description }}
                  />
                </Box>
                {/* Actions */}
                {user?.id === comment.user.id && (
                  <Button
                    variant="ghost"
                    fontSize="sm"
                    ml={-5}
                    onClick={() => handleSelectedForModal(comment.id)}
                    colorScheme="red"
                  >
                    <RiDeleteBinLine size={15} style={{ marginRight: '5px' }} />{' '}
                    Delete
                  </Button>
                )}
              </Box>
            </Flex>
          )}
        />
      )}

      {/* Modal */}
      <ConfirmModal
        title="delete"
        actionText="Delete"
        isOpen={!!selectedCommentId}
        onClose={() => handleSelectedForModal(null)}
        handleContinue={handleDeleteComment}
        isSubmitting={isDeleting}
      >
        <Text>Deleting this comment is permanent. There is no undo.</Text>
      </ConfirmModal>
    </>
  );
};

export default Comments;
