import { Box, Button, HTMLChakraProps } from '@chakra-ui/react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { usePagination, DOTS } from 'src/hooks/usePagination';

const defaultProps = {
  siblingCount: 1,
};

interface PaginationProps extends HTMLChakraProps<'div'> {
  currentPage: number;
  totalCount: number;
  siblingCount?: number;
  pageSize: number;
  // eslint-disable-next-line no-unused-vars
  setCurrentPage: (e: number) => void;
}

const Pagination = ({
  currentPage,
  totalCount,
  setCurrentPage,
  siblingCount,
  pageSize,
  ...rest
}: PaginationProps & typeof defaultProps) => {
  const pagination = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  }) as (string | number)[];

  const goToPage = (page: number) => setCurrentPage(page);
  const lastPage = Number(pagination[pagination.length - 1]);
  const hasPrevious = currentPage > 1;
  const hasNext = currentPage < lastPage;

  return (
    <Box display="flex" {...rest}>
      <Button
        isDisabled={!hasPrevious}
        aria-label="Prev"
        border="1px solid"
        borderColor="blue.100"
        onClick={() => goToPage(currentPage - 1)}
      >
        <GrPrevious />
      </Button>
      {pagination.map((item, idx) => {
        if (item === DOTS) {
          return (
            <Button
              border="1px solid"
              borderColor="blue.100"
              isDisabled
              key={String(item + idx)}
            >
              <Box as="span" mt="-1.5">
                {item}
              </Box>
            </Button>
          );
        }

        return (
          <Button
            key={item}
            bg={item === currentPage ? 'blue.50' : ''}
            border="1px solid"
            borderColor="blue.100"
            onClick={() => goToPage(item as number)}
          >
            <Box as="span">{item}</Box>
          </Button>
        );
      })}
      <Button
        isDisabled={!hasNext}
        aria-label="Next"
        border="1px solid"
        borderColor="blue.100"
        onClick={() => goToPage(currentPage + 1)}
      >
        <GrNext />
      </Button>
    </Box>
  );
};

Pagination.defaultProps = defaultProps;

export default Pagination;
