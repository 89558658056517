import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import Permissions from 'src/components/permissions/Permissions';
import { roles } from 'src/constant';
import { useUpdateUserByAdminMutation } from 'src/hooks/data/auth';
import { UserObject } from 'src/interfaces/user';

type Status = { type: 'success' | 'error'; message: string; title: string };

export const ChangeUserRole = ({
  data,
  isLoading,
}: {
  data?: UserObject;
  isLoading: boolean;
}) => {
  const [updateUserRole, { isLoading: isUpdating }] =
    useUpdateUserByAdminMutation();
  const [newRole, setNewRole] = useState('');
  const [status, setStatus] = useState<Status | undefined>();

  useEffect(() => {
    if (data?.id) {
      setNewRole(data.roles[0]);
    }
  }, [data]);

  const handleChange = (event: FormEvent<HTMLSelectElement>) => {
    setNewRole(event.currentTarget.value);
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    updateUserRole({ id: data?.id!, data: { roles: [newRole] } })
      .unwrap()
      .then((_res) => {
        setStatus({
          type: 'success',
          title: 'Success',
          message: 'Updated successfully',
        });
      })
      .catch((error) => {
        setStatus({
          type: 'error',
          title: error?.data?.message || 'Something went wrong!',
          message: error?.data?.message,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setStatus(undefined);
        }, 5000);
      });
  };

  return (
    <Stack
      divider={<StackDivider borderColor="gray.200" />}
      direction={{ base: 'column', md: 'row' }}
      gap={4}
      mt={-2}
    >
      <Box w="100%" pt={3}>
        <Text mb={3} fontWeight="semibold">
          Are you sure you want to change this member role?{' '}
        </Text>
        {!isLoading && (
          <form onSubmit={handleSubmit}>
            <FormControl mb="1rem">
              <FormLabel>Email</FormLabel>
              <Input type="email" name="email" value={data?.email} disabled />
            </FormControl>
            <FormControl id="role" mb="1rem">
              <FormLabel>Role</FormLabel>
              <Select name="role" value={newRole} onChange={handleChange}>
                {roles.map((rol) => (
                  <option key={rol.value} value={rol.value}>
                    {rol.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            {!isUpdating && status?.type && (
              <Alert status={status.type} variant="subtle" mb="1rem">
                <AlertIcon />
                <AlertDescription>
                  <Text fontWeight="semibold">{status.title}</Text>
                  <Text>{status.message}</Text>
                </AlertDescription>
                <CloseButton
                  alignSelf="flex-start"
                  position="absolute"
                  right={-1}
                  top={-1}
                  onClick={() => setStatus(undefined)}
                />
              </Alert>
            )}
            <Button
              isLoading={isUpdating}
              colorScheme="teal"
              width="full"
              type="submit"
              spinner={<BeatLoader size={8} color="white" />}
            >
              Update Role
            </Button>
          </form>
        )}
      </Box>
      <Box w="100%" pt={3}>
        <Text fontSize="lg" fontWeight="bold" textAlign="center">
          Roles
        </Text>
        <Divider my={2} borderColor="gray.200" />
        <Permissions />
      </Box>
    </Stack>
  );
};
