import { Badge } from '@chakra-ui/react';
import { isAfter, differenceInDays } from 'date-fns';
import { useMemo } from 'react';

const EstimateCheckerBadge = ({
  isOverdue,
  estimatedDate,
}: {
  isOverdue?: boolean;
  estimatedDate?: string | null;
}) => {
  const isDueSoon = useMemo(() => {
    if (!estimatedDate) {
      return false;
    }
    if (isOverdue) {
      return true;
    }

    const estimatedDateObject = new Date(estimatedDate);
    const now = new Date();

    if (isAfter(estimatedDateObject, now)) {
      const daysDifference = differenceInDays(estimatedDateObject, now);
      return daysDifference < 1;
    }

    return false;
  }, [estimatedDate, isOverdue]);

  return isDueSoon ? (
    <Badge colorScheme={isOverdue ? 'red' : 'orange'}>
      {isOverdue ? 'Overdue' : 'Due soon'}
    </Badge>
  ) : null;
};

export default EstimateCheckerBadge;
