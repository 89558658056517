import { Box, Container, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';

const Layout = () => {
  const [isLargerScreen] = useMediaQuery('(min-width: 992px)');
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);

  useEffect(() => {
    if (!isLargerScreen && isOpenSidebar) {
      setIsOpenSidebar(false);
    } else {
      setIsOpenSidebar(true);
    }
  }, [isLargerScreen]);

  return (
    <Box position="relative" display="flex">
      <Sidebar isOpen={isOpenSidebar} />
      {isOpenSidebar && !isLargerScreen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex="overlay"
          backgroundColor="rgba(0,0,0,0.1)"
          onClick={() => setIsOpenSidebar(false)}
        />
      )}
      <Box flex={1}>
        <Container maxW="container.wide">
          <Topbar toggleSidebar={() => setIsOpenSidebar((prev) => !prev)} />
          <Box as="main" maxW="container.wide" py={6}>
            <Outlet />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
