import { Box, Heading, Container } from '@chakra-ui/react';
import bgImage from 'src/assets/images/login_background.jpg';
import Logo from 'src/components/Logo';
import { Outlet } from 'react-router-dom';
import SuccessRegisterModal from './components/SuccessRegisterModal';

const Base = () => {
  return (
    <Box as="main">
      <Box
        minHeight="50vh"
        background={`url(${bgImage})`}
        position="relative"
        backgroundPosition="50%"
        backgroundSize="cover"
        margin="1rem"
        borderRadius="0.75rem"
        overflow="hidden"
        transition="all 0.5s ease-in-out"
        paddingBottom="12rem"
      >
        <Box
          as="span"
          position="absolute"
          top="0px"
          left="0px"
          width="100%"
          height="100%"
          background="linear-gradient(310deg, #141727 0%, #3A416F 100%)"
          backgroundPosition="center center"
          backgroundSize="cover"
          opacity="0.5"
        />
        <Container
          maxW="container.lg"
          position="relative"
          textColor="white"
          textAlign="center"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Logo marginTop="50px" marginBottom="50px" />
          <Heading size="2xl">Task Management System</Heading>
        </Container>
      </Box>
      <Box margin="0.5rem">
        <Container display="flex" justifyContent="center" maxW="container.lg">
          <Box
            borderRadius="0.75rem"
            overflow="hidden"
            padding="1.5rem"
            background="white"
            width={{ base: '100%', md: '500px' }}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
            marginTop="-10rem"
            marginBottom="10rem"
            zIndex="1"
          >
            <Outlet />
          </Box>
        </Container>
      </Box>
      {/* Modal shows if successfully registered */}
      <SuccessRegisterModal />
    </Box>
  );
};

export default Base;
