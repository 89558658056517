import { ReactNode } from 'react';
import { Box, HTMLChakraProps } from '@chakra-ui/react';

interface CardProps extends HTMLChakraProps<'div'> {
  children: ReactNode;
}

const Card = ({ children, ...rest }: CardProps) => {
  return (
    <Box p="6" rounded="md" boxShadow="md" bg="white" {...rest}>
      {children}
    </Box>
  );
};

export default Card;
