import {
  Menu,
  Button,
  Avatar,
  MenuList,
  Checkbox,
  Input,
  Box,
  Divider,
  Text,
} from '@chakra-ui/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useFetchUsersQuery } from 'src/hooks/data/auth';
import { UserStrip } from 'src/interfaces/task';

type Props<T> = { data: T[]; onApply: (data: T[]) => void };
// NB: children must be MenuButton component
export const MultipleUserDropdown = ({
  data,
  onApply,
  children,
}: PropsWithChildren<Props<UserStrip>>) => {
  const { data: users = [] } = useFetchUsersQuery('activated');
  const [selected, setSelected] = useState<UserStrip[]>([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (data) {
      setSelected(data);
    }
  }, [data]);

  const handleSelectUser = (user: UserStrip) => {
    if (!selected.find((i) => i.id === user.id)) {
      setSelected((prev) => [...prev, user]);
    } else {
      setSelected((prev) => prev.filter((i) => i.id !== user.id));
    }
  };

  const filteredUsers = users.filter((user) =>
    [user.firstName.toLowerCase(), user.lastName.toLowerCase()].some((i) =>
      i.includes(query.toLowerCase())
    )
  );

  return (
    <Menu>
      {({ onClose }) => (
        <>
          {children}
          <MenuList py={0} width="300px" zIndex="dropdown">
            <Box p={2}>
              <Input
                type="text"
                name="search"
                placeholder="Search user..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </Box>
            <Divider mb={1} />
            <Box maxH="200px" overflow="scroll" mb={4}>
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user) => (
                  <Checkbox
                    key={user.id}
                    width="full"
                    _hover={{
                      bg: 'gray.100',
                      borderRight: '3px solid',
                      borderColor: 'blue.500',
                      outline: 'none',
                    }}
                    p="2"
                    pl="4"
                    isChecked={!!selected.find((i) => i.id === user.id)}
                    onChange={() => handleSelectUser(user)}
                  >
                    <Box display="flex" overflow="hidden">
                      <Avatar
                        size="sm"
                        name={`${user.firstName} ${user.lastName}`}
                        mr={2}
                      />
                      <Box>
                        <Text
                          fontSize="sm"
                          overflow="hidden"
                          width="220px"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {user.firstName} {user.lastName}
                        </Text>
                        <Text
                          fontSize="xs"
                          mb="2.5"
                          textStyle="bold"
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {user.email}
                        </Text>
                      </Box>
                    </Box>
                  </Checkbox>
                ))
              ) : (
                <Text fontSize="sm" p="2" textAlign="center">
                  No users found
                </Text>
              )}
            </Box>
            <Box p={2}>
              {filteredUsers.length ? (
                <Button
                  onClick={() => {
                    onApply(selected);
                    onClose();
                  }}
                  width="full"
                  colorScheme="teal"
                  type="button"
                  disabled={!selected.length}
                >
                  Apply
                </Button>
              ) : null}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
