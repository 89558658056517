/* eslint-disable no-empty */
import { Department } from 'src/interfaces/company';
import { api } from 'src/store/services/baseApi';

type DeptType = {
  name: string;
  description?: string;
};

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepartments: build.query<Department[], void>({
      query: () => ({ url: 'departments', method: 'GET' }),
      providesTags: (result) =>
        result
          ? result.map(({ id }) => ({ type: 'getDepartments', id }))
          : ['getDepartments'],
    }),
    getDepartment: build.query<Department, { id: string }>({
      query: ({ id }) => ({ url: `department/${id}`, method: 'GET' }),
      providesTags: (result, error, { id }) => [{ type: 'getDepartments', id }],
    }),
    addDepartment: build.mutation<any, DeptType>({
      query: (data) => ({ url: 'department', method: 'POST', data }),
      invalidatesTags: ['getDepartments'],
    }),
    updateDepartment: build.mutation<any, DeptType & { id: number }>({
      query: ({ id, ...data }) => ({
        url: `department/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'getDepartments', id },
      ],
    }),
  }),
});

export const {
  useAddDepartmentMutation,
  useGetDepartmentQuery,
  useGetDepartmentsQuery,
  useUpdateDepartmentMutation,
} = injectedRtkApi;
