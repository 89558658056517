// theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        color: '#333984',
        backgroundColor: '#F8F8FF',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '15px',
      },
      h1: {
        fontSize: '3xl',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '2xl',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: 'lg',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 'md',
        fontWeight: 'bold',
      },
      // PS: This global anchor style is due to ck-editor component
      a: {
        textDecoration: 'underline',
        '&:hover': {
          color: '#000',
        },
        '&.mention': {
          backgroundColor: 'rgba(153,0,48,.1)',
          color: '#990030',
        },
      },
      'ul, ol': {
        marginInlineStart: '1em',
      },
      blockquote: {
        borderLeft: '5px solid #ccc',
        fontStyle: 'italic',
        marginLeft: 0,
        marginRight: 0,
        overflow: 'hidden',
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
      },
    },
  },
  sizes: {
    container: {
      ultra: '1920px',
      wide: '1440px',
      extra: '1200px',
      tabletWide: '990px',
      tablet: '768px',
    },
  },
});

export default theme;
