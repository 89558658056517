import {
  Avatar,
  Flex,
  Grid,
  Text,
  Skeleton,
  Box,
  HStack,
  StackDivider,
} from '@chakra-ui/react';
import { MdTaskAlt } from 'react-icons/md';
import { FaTasks } from 'react-icons/fa';
import Card from 'src/components/Card';

interface SortWidgetProps {
  icon: any;
  iconBgColor?: string;
  value: number | Record<'assigned' | 'assignee', number>;
  title: string;
  loading?: boolean;
}

const SortWidget = ({
  icon: Icon,
  iconBgColor = 'yellow.800',
  title,
  value,
  loading,
}: SortWidgetProps) => {
  const isObject = typeof value === 'object';
  return (
    <Card
      minH={150}
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {loading ? (
        <Skeleton height="100%" width="100%" />
      ) : (
        <>
          {isObject ? (
            <HStack spacing={4} mb={1} divider={<StackDivider />}>
              <Box>
                <Text fontSize={40} lineHeight={1}>
                  {value.assignee}
                </Text>
                <Text fontSize="sm">Assignee</Text>
              </Box>
              <Box>
                <Text fontSize={40} lineHeight={1}>
                  {value.assigned}
                </Text>
                <Text fontSize="sm">Assigned</Text>
              </Box>
            </HStack>
          ) : (
            <Text fontSize={40}>{value}</Text>
          )}
          <Flex alignItems="center">
            <Avatar
              bg={iconBgColor}
              color="white"
              icon={<Icon fontSize="1rem" />}
              mr="2"
              size="sm"
            />
            <Text fontWeight="semibold">{title}</Text>
          </Flex>
        </>
      )}
    </Card>
  );
};

const Overrall = ({
  loading,
  dataProps,
}: {
  dataProps: Record<string, number>;
  loading: boolean;
}) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        sm: 'repeat(2, 1fr)',
        xl: 'repeat(4, 1fr)',
      }}
      gap={4}
    >
      <SortWidget
        value={dataProps?.completed || 0}
        title="Completed tasks"
        icon={MdTaskAlt}
        iconBgColor="green.500"
        loading={loading}
      />
      <SortWidget
        value={dataProps?.progress || 0}
        title="InProgress tasks"
        icon={MdTaskAlt}
        iconBgColor="orange.500"
        loading={loading}
      />
      <SortWidget
        value={dataProps?.overDue || 0}
        title="Overdue tasks"
        icon={MdTaskAlt}
        iconBgColor="red.500"
        loading={loading}
      />
      <SortWidget
        value={{
          assignee: dataProps?.total || 0,
          assigned: dataProps?.taskAssigned || 0,
        }}
        title="Total tasks"
        icon={FaTasks}
        loading={loading}
      />
    </Grid>
  );
};

export default Overrall;
