import { Badge } from '@chakra-ui/react';
import { PriorityColor } from 'src/interfaces/task';

const priorityColor: Record<string, string> = {
  low: PriorityColor.LOW,
  medium: PriorityColor.MEDIUM,
  high: PriorityColor.HIGH,
  urgent: PriorityColor.URGENT,
};

const PriorityBadge = ({ priority }: { priority: string }) => {
  return (
    <Badge
      variant="outline"
      colorScheme={priorityColor[priority]}
      textTransform="uppercase"
    >
      {priority}
    </Badge>
  );
};

export default PriorityBadge;
