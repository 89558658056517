import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  useCancelSubscriptionMutation,
  useGetCurrentCompanySubscriptionQuery,
  useManageCardMutation,
} from 'src/hooks/data/subscription';
import { useAuth } from 'src/hooks/useAuth';
import CreditcardIcon from 'src/assets/images/credit-card.png';
import { BeatLoader } from 'react-spinners';
import { toastParams } from 'src/utils/toastParams';
import SubscriptionHistory from './SubscriptionHistory';
import { CompanySubscriptionUsage } from './Usage';
import { Plans } from './Plans';

const Billing = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [setCurrentSub, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const { data: currentSub } = useGetCurrentCompanySubscriptionQuery();
  const [manageCard, { isLoading: isManageCardLoading }] =
    useManageCardMutation();
  const isCurrentSubActive = user?.subscription?.status === 'active';
  // console.log('currentSub', currentSub);
  const handleCancelSub = async () => {
    try {
      await setCurrentSub({
        code: currentSub?.subscriptionCode,
        token: currentSub?.emailToken,
        paymentProvider: currentSub?.paymentProvider,
      }).unwrap();
      toast({
        title: 'Cancelled succesfully',
        status: 'success',
        ...toastParams,
      });
    } catch (error: any) {
      toast({
        title: error?.data?.message,
        status: 'error',
        ...toastParams,
      });
    }
  };

  const handleManageCard = async () => {
    try {
      await manageCard({
        code: currentSub?.subscriptionCode,
      }).unwrap();
      toast({
        title: 'A manage card link has been sent to your email',
        status: 'success',
        ...toastParams,
      });
    } catch (error: any) {
      toast({
        title: error?.data?.message,
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <Box pt={5}>
      <Card
        padding={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="3"
        flexWrap="wrap"
      >
        <Text>
          You&apos;re currently on the{' '}
          <Badge colorScheme="green">{user?.subscription?.plan}</Badge>
        </Text>
        <Button
          bg="blue.900"
          color="white"
          _hover={{ bg: 'blue.800' }}
          onClick={() => setOpenDrawer(true)}
        >
          View history
        </Button>
      </Card>
      {currentSub && currentSub.status !== 'pending' && (
        <Card padding={4} my={2} maxW={350}>
          <Text my={2}>Next billing will be charge on this card</Text>
          <Flex align="center" gap={4}>
            <Box bg="gray.100" padding="5px" rounded="full">
              <Image src={CreditcardIcon} width="25px" />
            </Box>
            <Box>
              <Text fontWeight="semibold" fontSize="sm">
                *******{currentSub?.authorization?.last4}
              </Text>
              <Text color="gray.500" fontSize="sm">
                Expires: {currentSub?.authorization?.exp_month}/
                {currentSub?.authorization?.exp_year}
              </Text>
            </Box>
          </Flex>
          {isCurrentSubActive && (
            <Flex mt={6} justify="space-between" gap={3}>
              <Button
                colorScheme="green"
                onClick={handleManageCard}
                disabled={isManageCardLoading}
                isLoading={isManageCardLoading}
                spinner={<BeatLoader size={8} color="white" />}
              >
                Manage card
              </Button>
              <Button
                colorScheme="red"
                width="fit-content"
                disabled={isCanceling}
                onClick={handleCancelSub}
                isLoading={isCanceling}
                spinner={<BeatLoader size={8} color="white" />}
              >
                Cancel
              </Button>
            </Flex>
          )}
        </Card>
      )}
      <Plans customerCode={currentSub?.customerCode} />
      <CompanySubscriptionUsage />
      {/* Drawer */}
      <SubscriptionHistory
        onClose={() => setOpenDrawer(false)}
        isOpen={openDrawer}
      />
    </Box>
  );
};

export default Billing;
