import { useToast } from '@chakra-ui/react';
import React, { FormEvent, forwardRef } from 'react';
import { FileType } from 'src/interfaces/task';
import { toastParams } from 'src/utils/toastParams';

type Props = {
  files: Array<File | FileType>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

const MAX_FILE_SIZE = 5120; // 5MB

const UploadInput = forwardRef<HTMLInputElement, Props>(
  ({ files, setFiles }, ref) => {
    const toast = useToast();

    const handleAttachmentChange = (event: FormEvent<HTMLInputElement>) => {
      const attachedFiles = event.currentTarget.files!;
      const totalFiles = files.length + attachedFiles.length;

      if (totalFiles > 10) {
        toast({
          title: 'Max upload is 10',
          status: 'error',
          ...toastParams,
        });
        return;
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < attachedFiles.length; i++) {
        const file = attachedFiles[i];

        const fileSize = file.size / 1024;
        if (fileSize > MAX_FILE_SIZE) {
          toast({
            title: 'Each file must not be larger than 5MB',
            status: 'error',
            ...toastParams,
          });
          return;
        }

        setFiles((prev) => [...prev, file]);
      }
    };

    return (
      <input
        type="file"
        ref={ref}
        multiple
        value={[]}
        style={{ display: 'none' }}
        maxLength={10}
        size={5000}
        onChange={handleAttachmentChange}
      />
    );
  }
);

export default UploadInput;
