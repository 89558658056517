import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { SERVER_URL } from 'src/constant';

const axiosInstance = axios.create();

// decode jwt token expiration date
export const authFactory = (token: string | null) => {
  if (!token) return false;
  const payload = jwtDecode(token) as JwtPayload;
  const now = new Date().getTime() / 1000;
  return now <= Number(payload.exp);
};

// set auth session
export const setSession = (
  tokens: Record<'token' | 'refreshToken', string> | null
) => {
  const method = tokens ? 'setItem' : 'removeItem';
  const tskStore = tokens && {
    token: btoa(tokens?.token),
    refreshToken: btoa(tokens?.refreshToken),
  };
  localStorage[method]('tskStore', JSON.stringify(tskStore));
};

export const getSession = () => {
  const tskStore = localStorage.getItem('tskStore');
  if (!tskStore) {
    return null;
  }
  const { token, refreshToken } = JSON.parse(tskStore);
  if (!token || !refreshToken) {
    localStorage.removeItem('tskStore');
    return null;
  }
  return { token: atob(token), refreshToken: atob(refreshToken) };
};

axiosInstance.interceptors.request.use(async (config) => {
  const tskStoreJson = getSession();

  config.baseURL = SERVER_URL;
  config.headers!['Content-Type'] = 'application/json';
  config.headers!['Authorization'] = tskStoreJson
    ? `Bearer ${tskStoreJson.token}`
    : '';

  // check if token exist and valid, else logout user.
  if (tskStoreJson && !authFactory(tskStoreJson.token)) {
    // Refresh token
    if (authFactory(tskStoreJson?.refreshToken)) {
      try {
        const response = await axios.get(`${SERVER_URL}auth/refresh`, {
          headers: {
            Authorization: `Bearer ${tskStoreJson?.refreshToken}`,
            'Content-Type': 'application/json',
          },
        });

        setSession(response.data);
        config.headers!['Authorization'] = `Bearer ${response.data?.token}`;
        return config;
      } catch {
        setSession(null);
        window.location.replace(window.location.origin);
        return false;
      }
    } else {
      setSession(null);
      window.location.replace(window.location.origin);
      return false;
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    let message = 'Something went wrong!';
    if (!error.status && error.message === 'Network Error') {
      message =
        'No internet connection. Please check your network and try again.';
    }

    return Promise.reject(error.response || { data: { message } });
  }
);

export default axiosInstance;
