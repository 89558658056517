import { Text } from '@chakra-ui/react';

const CardTitle = ({ text }: { text: string }) => {
  return (
    <Text mb="4" fontWeight="semibold">
      {text}
    </Text>
  );
};

export default CardTitle;
