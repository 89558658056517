import { Box, Heading, IconButton } from '@chakra-ui/react';
import { useState, useEffect, SyntheticEvent } from 'react';
import ContentEditable from 'react-contenteditable';
import { MdOutlineCheck, MdOutlineClose } from 'react-icons/md';

type Props = {
  title: string;
  handleUpdateTask: (val: Record<string, string>) => void;
};

const Title = ({ title, handleUpdateTask }: Props) => {
  const [editTitle, setEditTitle] = useState(title || '');
  const [focusText, setFocusText] = useState(false);

  useEffect(() => {
    setEditTitle(title);
  }, [title]);

  const handleClose = () => {
    setEditTitle(title);
    setFocusText(false);
  };

  const onSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    handleUpdateTask({ title: editTitle });
    setFocusText(false);
  };

  return (
    <Box my="4" position="relative">
      <form onSubmit={onSubmit}>
        <Box
          p="2"
          mx="-2"
          border="2px solid"
          borderColor={focusText ? 'borderColor' : 'transparent'}
          _hover={{ bg: 'gray.200' }}
          _focusWithin={{ bg: 'white', borderColor: 'blue.700' }}
          rounded="md"
        >
          <Heading as="div" size="lg" fontFamily="inherit">
            <ContentEditable
              html={editTitle}
              onFocus={() => setFocusText(true)}
              onChange={(e) => setEditTitle(e.target.value)}
              tagName="h1"
              style={{ outline: 'none' }}
            />
          </Heading>
        </Box>
        <Box>
          {focusText && (
            <Box position="absolute" right="0" mt="5px">
              <IconButton
                type="submit"
                isDisabled={!editTitle.length}
                aria-label="save title"
                icon={<MdOutlineCheck />}
                mr="2"
              />
              <IconButton
                aria-label="close title"
                icon={<MdOutlineClose />}
                onClick={handleClose}
              />
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default Title;
