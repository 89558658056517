import { Skeleton, Flex, Box, Text, Image, Badge } from '@chakra-ui/react';
import CustomDrawer from 'src/components/CustomDrawer';
import { NAIRA_SYMBOL } from 'src/constant';
import { useGetCompanySubscriptionsQuery } from 'src/hooks/data/subscription';
import { dateFormatter, getTime } from 'src/utils/time-date-functions';
import PaystackIcon from 'src/assets/images/paystack.svg';

const SubscriptionHistory = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose(): void;
}) => {
  const { isLoading: isLoadingSubscriptions, data: subscriptionData } =
    useGetCompanySubscriptionsQuery(undefined, { skip: !isOpen });

  return (
    <CustomDrawer
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="sm"
      title="Billing history"
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoadingSubscriptions ? (
        <Box>
          <Skeleton height="100px" mb={1} />
          <Skeleton height="100px" mb={1} />
          <Skeleton height="100px" />
        </Box>
      ) : subscriptionData?.length ? (
        subscriptionData.map((sub: any, index: number) => (
          <Box p={4} borderBottom="1px gray solid" mb={1} key={index}>
            <Text color="gray.500" mb={1}>
              {dateFormatter(sub?.createdAt)} {getTime(sub?.createdAt)}
            </Text>

            <Flex justify="space-between" align="center">
              {/* This should be replaced with the payment provider icon */}
              <Image src={PaystackIcon} width="16px" height="100%" />

              <Text fontWeight="semibold">
                *******{sub?.authorization?.last4}
              </Text>
            </Flex>
            <Flex justify="space-between">
              <Text color="gray.500">Charged for PRO plan</Text>
              <Text fontWeight="semibold">
                {NAIRA_SYMBOL}
                {(sub?.amount || 0)?.toLocaleString('en-US')}
              </Text>
            </Flex>
            <Badge
              mt={4}
              colorScheme={sub?.status === 'active' ? 'green' : 'gray'}
            >
              {sub?.status}
            </Badge>
          </Box>
        ))
      ) : (
        <Text textAlign="center" p="2">
          No recent billing
        </Text>
      )}
    </CustomDrawer>
  );
};

export default SubscriptionHistory;
