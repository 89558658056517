import { Avatar, Badge, Box, IconButton, Text } from '@chakra-ui/react';
import { RxExternalLink } from 'react-icons/rx';
import { useNavigate } from 'react-router';
import Card from 'src/components/Card';

import truncate from 'src/utils/truncate';
import { dateFormatter } from 'src/utils/time-date-functions';
import { UserObject } from 'src/interfaces/user';

const statusType: Record<string, string> = {
  activated: 'green',
  deactivated: 'red',
  pending: 'yellow',
};

const UserCard = (props: UserObject) => {
  const { firstName, lastName, email, createdAt, status, roles, id } = props;
  const navigate = useNavigate();
  return (
    <Card
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1"
      textAlign="center"
      position="relative"
      borderTop="1px solid"
      borderTopColor="green"
      pt="8"
    >
      {roles.includes('admin') && (
        <Box
          as="span"
          bg="green"
          color="white"
          px="4"
          py="1"
          fontSize="xs"
          borderBottomLeftRadius="full"
          borderBottomRightRadius="full"
          position="absolute"
          top="0"
        >
          Admin
        </Box>
      )}

      <IconButton
        aria-label="Options"
        icon={<RxExternalLink />}
        variant="outline"
        position="absolute"
        top="2"
        right="2"
        onClick={() => navigate(`/app/settings/members/${id}`)}
      />
      <Avatar name={`${firstName} ${lastName}`} size="xl" />
      <Text fontWeight="semibold" wordBreak="break-word">
        {firstName} {lastName}
      </Text>
      <Text fontSize="sm" noOfLines={1}>
        {truncate(email, 30)}
      </Text>
      {status && status !== 'activated' && (
        <Badge colorScheme={statusType[status]}>{status}</Badge>
      )}
      <Text fontSize="xs" textColor="gray.400">
        {dateFormatter(createdAt as string)}
      </Text>
    </Card>
  );
};

export default UserCard;
