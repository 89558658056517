/* eslint-disable no-nested-ternary */
import {
  Avatar,
  Box,
  Flex,
  Grid,
  IconButton,
  Skeleton,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { BiPencil } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { useAuth } from 'src/hooks/useAuth';
import { Department } from 'src/interfaces/company';
import { DeptFormMutations } from 'src/components/dropdown/departmentDropdown';
import { useState } from 'react';

export const DepartmentOverview = ({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: Department | undefined;
}) => {
  const { isAdmin } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  return (
    <Box>
      <Box minH={180}>
        {!isEdit ? (
          <>
            <Skeleton mb={6} minHeight="40px" isLoaded={!isLoading}>
              <Flex justify="space-between" gap={3} width="100%">
                <Text fontSize="2xl">{data?.name}</Text>

                {isAdmin && (
                  <IconButton
                    aria-label="Edit department"
                    icon={<BiPencil />}
                    onClick={() => setIsEdit(!isEdit)}
                  />
                )}
              </Flex>
            </Skeleton>

            <Text fontWeight="semibold" fontSize="lg" mb={2}>
              Description:
            </Text>
            <SkeletonText
              mt="3"
              noOfLines={4}
              spacing="4"
              skeletonHeight="2"
              isLoaded={!isLoading}
            >
              <Text>
                {data?.description || (
                  <i>What&apos;s this department about ?</i>
                )}
              </Text>
            </SkeletonText>
          </>
        ) : (
          <DeptFormMutations
            editDept={data}
            onClose={() => setIsEdit(!isEdit)}
          />
        )}
      </Box>

      <Box mt={10}>
        <Flex gap={2} align="center" fontWeight="semibold" mb={2}>
          <FiUsers style={{ marginRight: '5px' }} size={25} />
          <Text fontSize="lg">Members</Text>
        </Flex>

        <Grid
          my={6}
          gap={2}
          gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        >
          {isLoading ? (
            Array.from({ length: 3 }, (_, index) => (
              <Skeleton height="50px" width="100%" rounded="md" key={index} />
            ))
          ) : data?.users.length ? (
            data?.users.map((user, index) => (
              <Flex
                key={index}
                as={RouterLink}
                to={`/app/settings/members/${user.id}`}
                textDecoration="none"
                align="center"
                p={2}
                rounded="md"
                _hover={{ background: 'gray.200' }}
              >
                <Avatar
                  name={`${user.firstName} ${user.lastName}`}
                  size="sm"
                  mr={2}
                />
                <Box>
                  <Text>
                    {user.firstName} {user.lastName}
                  </Text>
                  <Text fontSize="xs" noOfLines={1}>
                    {user.email}
                  </Text>
                </Box>
              </Flex>
            ))
          ) : (
            <Text fontSize="xs">
              <em>--No member yet--</em>
            </Text>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
