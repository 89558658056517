import { Box, IconButton, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { IoMenu } from 'react-icons/io5';
import { matchPath, useLocation } from 'react-router-dom';
import Profile from './partials/Profile';
import { navItems } from './Sidebar';

export const Topbar = ({ toggleSidebar }: { toggleSidebar(): void }) => {
  const location = useLocation();

  const currentPage = useMemo(() => {
    const navItem = navItems.find((nav) => {
      return !!matchPath({ path: nav.link, end: false }, location.pathname);
    });
    return navItem?.label;
  }, [location.pathname]);

  return (
    <Box as="header" position="sticky" top="0" zIndex="banner">
      <Box
        position="relative"
        bg="#F8F8FF"
        boxShadow="sm"
        height="90px"
        zIndex="banner"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" gap={2}>
          <IconButton
            onClick={toggleSidebar}
            display={{ base: 'flex', lg: 'none' }}
            aria-label="menu"
            icon={<IoMenu size={24} />}
          />
          <Text fontSize={{ base: 20, md: 30 }} fontWeight="semibold">
            {currentPage}
          </Text>
        </Box>
        <Profile />
      </Box>
    </Box>
  );
};
