import { Box } from '@chakra-ui/react';
import { Link, matchPath, Outlet, useLocation } from 'react-router-dom';

const tabs = [
  {
    name: 'General',
    link: '/admin/settings/general',
  },
  {
    name: 'SMTP',
    link: '/admin/settings/smtp',
  },
];

const Settings = () => {
  const location = useLocation();
  const isMatch = (path: string) => matchPath(path, location.pathname);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap={6}
        borderBottom="1.5px solid"
      >
        {tabs.map((tab) => (
          <Box
            as={Link}
            to={tab.link}
            key={tab.name}
            textDecor="none"
            pb="16px"
            borderBottom="2.5px solid"
            borderColor={isMatch(tab.link) ? 'blue.500' : 'transparent'}
            fontSize="18px"
            _hover={{ color: isMatch(tab.link) ? 'blue.500' : 'inherit' }}
            color={isMatch(tab.link) ? 'blue.500' : 'inherit'}
          >
            {tab.name}
          </Box>
        ))}
      </Box>
      <Outlet />
    </Box>
  );
};

export default Settings;
