import { RouteObject, Navigate } from 'react-router-dom';
import NotFound from 'src/views/app/error/NotFound';
import General from './General';
import Smtp from './Smtp';

export const settingsRoutes: RouteObject[] = [
  {
    path: 'general',
    element: <General />,
  },
  {
    path: 'smtp',
    element: <Smtp />,
  },
  { path: '', element: <Navigate to="/admin/settings/general" /> },
  { path: '*', element: <NotFound /> },
];
