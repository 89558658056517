import {
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Box,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import axios from 'src/utils/axios';

const ForgotPassword = () => {
  return (
    <>
      <Box textAlign="center" mb="1rem">
        <Heading size="xl">Forgot Password ?</Heading>
        <Text mt="1rem">
          You&apos;ll need a verification link to change your password. Please
          enter your email address.
        </Text>
      </Box>
      <Box>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
          })}
          onSubmit={async (values, { setStatus, setSubmitting, resetForm }) => {
            try {
              await axios.post('auth/reset-password', {
                email: values.email,
              });
            } finally {
              resetForm();
              setStatus({
                type: 'success',
                message:
                  'Success!, if email exist, a verification token would be sent to you.',
              });
              setSubmitting(false);
              setTimeout(() => {
                setStatus({});
              }, 10000);
            }
          }}
        >
          {({
            errors,
            status,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {status && status?.type && (
                <Alert status={status?.type} mb="1rem">
                  <AlertIcon />
                  <AlertDescription>{status?.message}</AlertDescription>
                </Alert>
              )}
              <FormControl
                id="email"
                mb="1rem"
                isInvalid={Boolean(touched.email && errors.email)}
              >
                <FormLabel>Enter your email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              <Button
                isLoading={isSubmitting}
                colorScheme="teal"
                width="full"
                type="submit"
                spinner={<BeatLoader size={8} color="white" />}
              >
                Confirm email
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ForgotPassword;
