import { useEffect, useState } from 'react';
import { Box, HStack, useMediaQuery } from '@chakra-ui/react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import OnlyAdmin from 'src/components/guards/OnlyAdmin';

import CustomDrawer from 'src/components/CustomDrawer';
import NotFound from '../error/NotFound';
import Members from './members';
import Notifications from './notifications';
import Profile from './profile';
import PermissionList from './permissions/PermissionList';
import Security from './security';
import Organization from './organization';
import Header from './Header';
import RouteList from './RouteList';
import Billing from './billing';
import Departments from './departments';

const Settings = () => {
  const [isMediaLarge] = useMediaQuery('(min-width: 890px)');
  const params = useParams()['*'];
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => setOpenDrawer(false);

  useEffect(() => {
    if (params) handleClose();
  }, [params]);

  return (
    <Box maxW="container.extra" mx="auto">
      <HStack
        spacing={isMediaLarge ? '20px' : 0}
        alignItems="flex-start"
        display={isMediaLarge ? 'flex' : 'block'}
      >
        {isMediaLarge ? (
          <Box w="250px" overflow="hidden" mb="4" rounded="md" boxShadow="sm">
            <Box position="fixed" width="inherit" py="4" bgColor="white">
              <RouteList />
            </Box>
          </Box>
        ) : (
          <CustomDrawer isOpen={openDrawer} onClose={handleClose}>
            <RouteList />
          </CustomDrawer>
        )}

        <Box
          flex={1}
          bgColor="white"
          minHeight="calc(100vh - 100px)"
          overflow="hidden"
          rounded="md"
          p="4"
          boxShadow="sm"
        >
          {params && (
            <Header
              title={params}
              isMediaLarge={isMediaLarge}
              openDrawer={() => setOpenDrawer(true)}
            />
          )}
          <Routes>
            <Route path="security" element={<Security />} />
            <Route path="profile" element={<Profile />} />
            <Route path="members" element={<Members />}>
              <Route path=":id" element={<Members />} />
            </Route>
            <Route
              path="organization"
              element={<OnlyAdmin component={<Organization />} />}
            />
            <Route path="notifications" element={<Notifications />} />
            <Route path="departments" element={<Departments />}>
              <Route path=":id" element={<Departments />} />
            </Route>
            <Route
              path="permissions"
              element={<OnlyAdmin component={<PermissionList />} />}
            />
            <Route
              path="billing"
              element={<OnlyAdmin component={<Billing />} />}
            />
            <Route path="/" element={<Navigate to="/app/settings/profile" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </HStack>
    </Box>
  );
};

export default Settings;
