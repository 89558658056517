import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Link,
  Skeleton,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { RiDeleteBinLine } from 'react-icons/ri';
import ProfileCard from 'src/components/ProfileCard';
import StatusDropdown from 'src/components/dropdown/StatusDropdown';
import TaskIcon from 'src/components/TaskIcon';
import { Task } from 'src/interfaces';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useState } from 'react';
import { toastParams } from 'src/utils/toastParams';
import { useAuth } from 'src/hooks/useAuth';
import { dateFormatter } from 'src/utils/time-date-functions';
import { lifeSpanCalculator } from 'src/utils/lifeSpanCalculator';
import { useDeleteTaskMutation } from 'src/hooks/data/task';
import { BiLockAlt } from 'react-icons/bi';

const SkeletonRow = () => (
  <Tr>
    <Td width="10px" />
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td width="40%">
      {' '}
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
    <Td>
      <Skeleton height="10px" />
    </Td>
  </Tr>
);

const ListData = ({
  tasks,
  loading,
}: {
  tasks: Task.Task[];
  loading?: boolean;
}) => {
  const [deleteTask, { isLoading }] = useDeleteTaskMutation();
  const toast = useToast();
  const { isAdmin } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const toggleModal = () => setOpenModal(!openModal);

  const handleDeleteTask = async () => {
    if (!selectedItem) return;
    try {
      await deleteTask(selectedItem).unwrap();
      toggleModal();
      toast({
        title: 'Deleted successfully',
        status: 'success',
        ...toastParams,
      });
    } catch (error: any) {
      toast({
        title: error?.data?.message || 'Something went wrong!',
        status: 'error',
        ...toastParams,
      });
    }
  };

  return (
    <>
      <Table fontSize="sm" variant="unstyled" position="relative" minW="1200px">
        <Thead position="sticky" top="0" zIndex="docked" bg="white">
          <Tr boxShadow="sm">
            <Th width="30px" />
            <Th>Ticket ID</Th>
            <Th width="40%" minW="250px">
              Ticket title
            </Th>
            <Th>Assignee</Th>
            <Th>Reporter</Th>
            <Th>Status</Th>
            <Th>Created</Th>
            <Th>Lifespan</Th>
            {isAdmin && <Th>Action</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </>
          ) : (
            tasks.map((task) => (
              <Tr _hover={{ bgColor: 'gray.100' }} key={task.id}>
                <Td padding={0} paddingLeft="10px">
                  {task.isPrivate ? (
                    <BiLockAlt fontSize="20px" />
                  ) : (
                    <TaskIcon status={task.status} />
                  )}
                </Td>
                <Td>
                  <Link as={RouterLink} to={`/app/issues/${task.id}`}>
                    <Text
                      whiteSpace="nowrap"
                      decoration={
                        task.status === Task.Status.COMPLETED
                          ? 'line-through'
                          : ''
                      }
                    >
                      {task.id}
                    </Text>
                  </Link>
                </Td>
                <Td>
                  <Link as={RouterLink} to={`/app/issues/${task.id}`}>
                    <Text noOfLines={2}>{task.title}</Text>
                  </Link>
                </Td>
                <Td>
                  <ProfileCard user={task.assigned} />
                </Td>
                <Td>
                  <ProfileCard user={task.user} />
                </Td>
                <Td>
                  <StatusDropdown status={task.status} taskId={task.id} />
                </Td>
                <Td whiteSpace="nowrap">
                  {task.createdAt ? dateFormatter(task.createdAt) : ''}
                </Td>
                <Td whiteSpace="nowrap">{lifeSpanCalculator(task).lifeSpan}</Td>
                {isAdmin && (
                  <Td whiteSpace="nowrap">
                    <IconButton
                      aria-label="Delete ticket"
                      colorScheme="red"
                      onClick={() => {
                        toggleModal();
                        setSelectedItem(task.id);
                      }}
                      icon={<RiDeleteBinLine size={15} />}
                    />
                  </Td>
                )}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>

      {/* Modal */}
      <ConfirmModal
        title="delete"
        actionText="Delete"
        isOpen={openModal}
        onClose={toggleModal}
        handleContinue={handleDeleteTask}
        isSubmitting={isLoading}
      >
        <Text>Deleting this ticket is permanent. There is no undo.</Text>
      </ConfirmModal>
    </>
  );
};

export default ListData;
