export const copyTextToClipboard = (copyText: string) => {
  return navigator.clipboard.writeText(copyText).then(
    () => {
      return 'Copied successfully.';
    },
    function (err) {
      console.error(`Async, could not copy text: ${err}`);
    }
  );
};
