import { RouteObject, Navigate } from 'react-router-dom';
import Overview from './overview';
import TasksBoard from './board';
import Settings from './settings';
import { issueRoutes } from './issue/issue.routes';
import NotFound from './error/NotFound';

export const appRoutes: RouteObject[] = [
  {
    path: 'overview',
    element: <Overview />,
  },
  {
    path: 'board',
    element: <TasksBoard />,
  },
  {
    path: 'issues',
    children: issueRoutes,
  },
  {
    path: 'settings/*',
    element: <Settings />,
  },
  { path: '', element: <Navigate to="/app/overview" /> },
  { path: '*', element: <NotFound /> },
];
