export const roleConstant = [
  {
    title: 'Admin',
    description:
      'This role describes member who oversees operations across the organization e.g. company owner, an executive member who needs full administrator privileges.',
  },
  {
    title: 'User',
    description:
      'This role describes member with less operation across the organisation. It is best for members who need to view task, create task but cannot delete them.',
  },
];
