import { Box, Text } from '@chakra-ui/react';
import { PulseLoader } from 'react-spinners';

export const Loading = () => {
  return (
    <Box
      position="absolute"
      width="full"
      height="full"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box mt={-150}>
        <PulseLoader />
        <Text>Loading...</Text>
      </Box>
    </Box>
  );
};
