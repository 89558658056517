export const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_SERVER_URL_PRODUCTION
    : process.env.REACT_APP_SERVER_URL_DEVELOPMENT;

export const filesAPIUrl = (url: string) =>
  `${process.env.REACT_APP_S3_URL}/${url}`;

export const roles = [
  { label: 'Admin', value: 'admin' },
  { label: 'User', value: 'user' },
];

export const NAIRA_SYMBOL = '₦';
