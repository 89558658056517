import {
  Box,
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { setSignedProfile, SignedRoleT } from 'src/store/slices/auth';

const ConfirmSignedRole = () => {
  // const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('');

  const handleContinue = () => {
    const role = selectedRole as SignedRoleT;
    setSignedProfile(role);

    // const path = role === 'admin' ? '/app' : '/admin/dashboard';
    // return navigate(path);
  };

  return (
    <Box>
      <Box textAlign="center" mb="1rem">
        <Heading size="xl">Choose role to continue</Heading>
        <Text mt="1rem">
          This account seems to have multiple roles. Please choose one to
          continue.
        </Text>
      </Box>
      <Box>
        <RadioGroup
          my={6}
          value={selectedRole}
          onChange={(val) => setSelectedRole(val)}
        >
          <Stack>
            <Radio size="lg" value="admin">
              Admin
            </Radio>
            <Radio size="lg" value="super_admin">
              Super Admin
            </Radio>
          </Stack>
        </RadioGroup>

        <Button
          onClick={handleContinue}
          isDisabled={!selectedRole}
          colorScheme="teal"
          width="full"
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmSignedRole;
