/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { useGetDepartmentsQuery } from 'src/hooks/data/department';
import { useAuth } from 'src/hooks/useAuth';
import { DeptFormMutations } from 'src/components/dropdown/departmentDropdown';
import { HiRectangleGroup } from 'react-icons/hi2';
import EmptyResult from 'src/components/EmptyResult';
import DepartmentDetails from './DepartmentDetails';

const Departments = () => {
  const { isAdmin } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading, data = [] } = useGetDepartmentsQuery(undefined);

  return (
    <Box>
      <Flex wrap="wrap" gap={2} justifyContent="space-between" py={2} w="full">
        {isAdmin && (
          <Button
            bg="blue.900"
            color="white"
            _hover={{ bg: 'blue.900' }}
            onClick={() => setIsOpen(true)}
          >
            Add department
          </Button>
        )}
      </Flex>

      {isLoading ? (
        <div>loading...</div>
      ) : data.length ? (
        <Grid
          py={6}
          gap={2}
          gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
        >
          {data.map((dept, index) => {
            return (
              <Card
                key={index}
                as={RouteLink}
                to={`/app/settings/departments/${dept.id}`}
                height={100}
                p={2}
                textDecoration="none"
                alignItems="center"
                justifyContent="center"
                bgGradient="linear(to-r, teal.500, green.500)"
                _hover={{
                  bgGradient: 'linear(to-r, green.500, teal.500)',
                }}
              >
                <Text fontWeight="bold" color="white">
                  {dept.name}
                </Text>
              </Card>
            );
          })}
        </Grid>
      ) : (
        <EmptyResult
          title="No department yet"
          subtitle="All departments created by your admin will be shown here"
        />
      )}

      {/* Department Details */}
      <DepartmentDetails />

      {/* Add dept */}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HiRectangleGroup style={{ display: 'inline-flex' }} /> New
            department
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DeptFormMutations />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Departments;
