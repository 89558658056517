import { Checkbox, Flex, IconButton, Text } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Department } from 'src/interfaces/company';

type Props<T> = {
  dept: T;
  onPress(e: T): void;
  selected: T[];
  disabled?: boolean;
};

export const DeptItem = ({
  dept,
  onPress,
  selected,
  disabled,
}: Props<Department>) => {
  return (
    <Flex px={2} gap={1}>
      <Checkbox
        p={2}
        _hover={{ bg: 'gray.100', outline: 'none' }}
        isChecked={!!selected.find((i) => i.id === dept.id)}
        onChange={() => onPress(dept)}
        disabled={disabled}
      >
        <Text
          fontSize="sm"
          width="205px"
          overflow="hidden"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {dept.name}
        </Text>
      </Checkbox>
      <IconButton
        aria-label="view department"
        variant="link"
        as={RouterLink}
        to={`/app/settings/departments/${dept.id}`}
        _hover={{ bg: 'gray.100' }}
        icon={<AiOutlineArrowRight size={15} />}
      />
    </Flex>
  );
};
