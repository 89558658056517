import { Box, Skeleton, Text } from '@chakra-ui/react';
import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Card from 'src/components/Card';
import CardTitle from 'src/components/CardTitle';

ChartJS.register(...registerables);

export default function TaskSummaryChart({
  loading,
  dataProps,
}: {
  dataProps: Record<string, number>;
  loading: boolean;
}) {
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    aspectRatio: 2,
    cutout: '85%',
    plugins: {
      legend: { position: 'bottom' },
    },
  };

  const dataRecords = [
    dataProps.pending,
    dataProps.progress,
    dataProps.review,
    dataProps.completed,
  ];

  const hasRecords = dataRecords.some((rec) => rec);

  const data = {
    labels: ['Pending', 'In Progress', 'In Review', 'Completed'],
    datasets: [
      {
        data: dataRecords,
        backgroundColor: [
          'rgba(102, 102, 102, 0.1)',
          '#0000FF',
          '#FFA500',
          '#62B44E',
        ],
        borderColor: [
          'rgba(102, 102, 102, 0.1)',
          '#0000FF',
          '#FFA500',
          '#62B44E',
        ],
        borderWidth: 1,
        hoverBorderWidth: 5,
        borderRadius: 150,
      },
    ],
  };

  return (
    <Card>
      <CardTitle text="Tasks Summary" />
      {loading ? (
        <Skeleton height="200px" width="100%" />
      ) : (
        <Box minH={200} height="80%">
          {hasRecords ? (
            <Doughnut options={options} data={data} />
          ) : (
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="sm">No records during the selected dates</Text>
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
}
