import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  AlertIcon,
  AlertDescription,
  Alert,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { BeatLoader } from 'react-spinners';
import * as Yup from 'yup';
import axios from 'src/utils/axios';

const Security = () => {
  return (
    <Box pt="4" pb="10" width={{ base: '100%', md: '60%' }}>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required('Old password is required'),
          newPassword: Yup.string().required('New password is required'),
          confirmNewPassword: Yup.string()
            .oneOf(
              [Yup.ref('newPassword'), null],
              'Confirm Password must match new password'
            )
            .required('Confirm password is required'),
        })}
        onSubmit={async (value, { setSubmitting, setStatus, resetForm }) => {
          try {
            const { newPassword, oldPassword } = value;
            await axios.post('auth/change-password', {
              newPassword,
              oldPassword,
            });
            resetForm();
            setStatus({
              type: 'success',
              message: 'Password changed successfully',
            });
          } catch (err: any) {
            setStatus({
              type: 'error',
              message: err.data.message,
            });
          } finally {
            setSubmitting(false);
            setTimeout(() => {
              setStatus({});
            }, 5000);
          }
        }}
      >
        {({
          status,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormControl
              id="oldPassword"
              mb="1rem"
              isInvalid={Boolean(touched.oldPassword && errors.oldPassword)}
            >
              <FormLabel>Enter your old password</FormLabel>
              <Input
                type="password"
                name="oldPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.oldPassword}
              />
              <FormErrorMessage>{errors.oldPassword}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="newPassword"
              mb="1rem"
              isInvalid={Boolean(touched.newPassword && errors.newPassword)}
            >
              <FormLabel>Enter your new password</FormLabel>
              <Input
                type="password"
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.newPassword}
              />
              <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="confirmNewPassword"
              mb="1rem"
              isInvalid={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
            >
              <FormLabel>Confirm your new password</FormLabel>
              <Input
                type="password"
                name="confirmNewPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmNewPassword}
              />
              <FormErrorMessage>{errors.confirmNewPassword}</FormErrorMessage>
            </FormControl>
            {status && status?.type && (
              <Alert status={status?.type} mb="1rem">
                <AlertIcon />
                <AlertDescription>{status?.message}</AlertDescription>
              </Alert>
            )}
            <Button
              isLoading={isSubmitting}
              colorScheme="teal"
              type="submit"
              spinner={<BeatLoader size={8} color="white" />}
            >
              Update password
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Security;
