import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { BiLockAlt } from 'react-icons/bi';

function Header({ slug, isPrivate }: { slug: string; isPrivate?: boolean }) {
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink as={RouterLink} to="/app/issues">
          Issues
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <Text mr={2}>{slug}</Text>
        {isPrivate && <BiLockAlt />}
      </BreadcrumbItem>
    </Breadcrumb>
  );
}

export default Header;
